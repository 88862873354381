import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';
import { selectLanguage } from '../../../../util/language/ResolveLanguage';
import { getManifest, Manifest } from '../../../../util/language/util';

type LanguageListProps = {
  onLanguageChange: (lang: string) => void;
};
function LanguageList({ onLanguageChange }: LanguageListProps): React.JSX.Element {
  const manifest = getManifest();

  return (
    <>
      {Object.keys(manifest)
        // Only active languages should be presented
        .filter((lng) => manifest[lng as keyof Manifest].enabled)
        .map((lng) => (
          <li key={lng}>
            <button
              className="dropdown-item"
              onClick={(evt) => {
                evt.preventDefault();
                onLanguageChange(lng);
              }}
            >
              {manifest[lng as keyof Manifest].name}
            </button>
          </li>
        ))}
    </>
  );
}

function ChangeLanguage(): React.JSX.Element {
  const manifest = getManifest();
  const { t, i18n } = useTranslation();

  // Reflect changes in language in Moment-formatted strings
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (newLanguage: string) => {
    // Ensure we select a valid and active language
    const lang = selectLanguage(newLanguage);
    i18n.changeLanguage(lang);
    document.documentElement.setAttribute('lang', lang);
  };

  return (
    <div>
      <div className="dropdown">
        <button
          className="btn dropdown-toggle language-dropdown"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {manifest[i18n.language as keyof Manifest].name}
        </button>
        <ul className="dropdown-menu" role="menu" aria-label={t('navbar.langSelector')}>
          <LanguageList onLanguageChange={changeLanguage} />
        </ul>
      </div>
    </div>
  );
}

export default ChangeLanguage;
