/**
 * Verifies if the error is of type HttpError or not
 * */
export class HttpError extends Error {
  readonly status: number;

  readonly data?: unknown;

  readonly response?: Response;

  constructor(err: string, status = 500, data: Response | undefined = undefined, response: Response | undefined = undefined) {
    super(err);

    // View-name (relevant in Sentry reports)
    this.name = 'HttpError';
    this.status = status;
    this.data = data;
    this.response = response;
  }
}

export const isHttpError = (error: Error): error is HttpError => 'status' in error && 'response' in error;

export class PersistenceError extends Error {
  constructor(message: string) {
    super(message);

    // View-name (relevant in Sentry reports)
    this.name = 'PersistenceError';
  }
}

export class InvalidDataError extends Error {
  constructor(message: string) {
    super(message);

    // View-name (relevant in Sentry reports)
    this.name = 'InvalidDataError';
  }
}
