import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './index.scss';
import useRetrieveAdhocGroupDetails from '../../../hooks/groups/useRetrieveAdhocGroupDetails';
import useAuthInformation from '../../../hooks/auth/useAuthInformation';
import AdhocGroupAdmin from './adhocgroup/AdhocGroupAdmin';
import { Group, GroupMembership } from '../../../types/group';
import { lastItem } from '../../../util/arrayUtil';
import { toHumanFormat } from '../../../util/dateUtil';
import { getMostPreciseName, getQualifiedGroupType, getQualifiedMembership } from '../../../util/feideUtil';
import useWindowWidth from '../../../util/hooks/windowWidth';
import GroupSymbol from './GroupSymbol';
import GenericTable, { CollapsableTableRow } from '../../ui/GenericTable';

const isCollapsed = (currentWidth: number, threshold: number) => currentWidth < threshold;

interface GroupTypeProps {
  group: Group;
  primaryType: string;
}

/**
 * Translated container to present what type the group is
 * */
function GroupType({ group, primaryType }: GroupTypeProps): React.JSX.Element {
  const { t } = useTranslation('groups');

  const types: { [key: string]: string } = {
    'ad-hoc': t('type.ad-hoc'),
    emne: t('type.emne'),
    klasse: t('type.klasse'),
    kull: t('type.kull'),
    org: t('type.org'),
    orgunit: t('type.orgunit'),
    prg: t('type.prg'),
    str: t('type.str'),
    'gogroup.a': t('type.gogroup.a'),
    'gogroup.u': t('type.gogroup.u'),
    'gogroup.b': t('type.gogroup.b'),
    'grep.aarstrinn': t('type.grep.aarstrinn'),
  };

  const qualifiedType = getQualifiedGroupType(group);

  if (!qualifiedType || !(qualifiedType in types)) {
    console.warn(`Unknown group type: ${primaryType}`);
    return <>{types.str}</>;
  }

  return <>{types[qualifiedType]}</>;
}

interface GroupMembershipProps {
  group: Group;
}

/**
 * Translated container to present a user's membership in a group
 * */
function TranslatedGroupMembership({ group }: GroupMembershipProps): React.JSX.Element {
  const { t } = useTranslation('groups');
  const { membership } = group;

  const qualifiedMembership = getQualifiedMembership(group);

  const memberships: { [key: string]: string } = {
    admin: t('membership.admin'),
    member: t('membership.member'),
    owner: t('membership.owner'),
    'affiliation.student': t('membership.affiliation.student'),
    'affiliation.member': t('membership.member'),
  };

  if (qualifiedMembership in memberships) {
    return <>{memberships[qualifiedMembership]}</>;
  }

  if (membership.displayName) {
    return <>{membership.displayName}</>;
  }

  console.warn(`Unknown group membership: ${membership}`);

  return <>{memberships.member}</>;
}

interface GroupPropertyProps {
  label: string;
  property?: JSX.Element;
  icon: string;
}

/**
 * Container for a specific property in a group.
 * Accompanied with an icon for labelling
 * */
function GroupProperty({ label, property, icon }: GroupPropertyProps): React.JSX.Element {
  const fontAwesomeIcon = `fa-${icon}`;

  return (
    <span className="member-type-container groupproperty">
      <i aria-label={label} className={classNames('fas', fontAwesomeIcon, 'groupproperty__icon')} />
      <em className="groupproperty__property">{property}</em>
    </span>
  );
}

interface GroupPropertiesProps {
  group: Group;
  primaryType: string;
  membership: GroupMembership;
}

/**
 * Collection of the primary properties a group has
 *  - Type
 *  - User's membership in the group
 * */
function GroupProperties({ group, primaryType }: GroupPropertiesProps): React.JSX.Element {
  const { t } = useTranslation('groups');

  // List is used because it makes more semantically sense for a screen reader.
  // It is a "list of properties"
  return (
    <ul className="groupproperties">
      <li>
        <GroupProperty label={t('membershipInGroup')} icon="user" property={<TranslatedGroupMembership group={group} />} />
      </li>
      <li>
        <GroupProperty label={t('groupType')} icon="users" property={<GroupType group={group} primaryType={primaryType} />} />
      </li>
    </ul>
  );
}

export interface TranslatableProperty {
  key: string;
  label: string | JSX.Element | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any[];
}

function extractDetailedProperties(group: Group, t: (arg: string) => string): TranslatableProperty[] {
  const baseProperties = [
    'eduOrgHomePageURI',
    'norEduOrgNIN',
    'norEduOrgAcronym',
    'norEduOrgUniqueIdentifier',
    'norEduOrgUnitUniqueIdentifier',
    'id',
  ]
    .map(
      (key): TranslatableProperty => ({
        key,
        label: t(`properties.${key}`),
        value: group[key as keyof Group] ? [group[key as keyof Group]] : undefined,
      }),
    )
    .filter((attribute) => !!attribute.value);

  // notBefore and notAfter need some additional parsing
  // to be human readable
  if (group.notBefore) {
    const membershipNotAfter: TranslatableProperty = {
      key: 'notBefore',
      label: t('properties.notBefore'),
      value: [toHumanFormat(group.notBefore)],
    };
    baseProperties.push(membershipNotAfter);
  }

  if (group.notAfter) {
    const membershipNotAfter: TranslatableProperty = {
      key: 'notAfter',
      label: t('properties.notAfter'),
      value: [toHumanFormat(group.notAfter)],
    };
    baseProperties.push(membershipNotAfter);
  }

  if (group.membership?.notAfter) {
    const membershipNotAfter: TranslatableProperty = {
      key: 'membership.notAfter',
      label: t('properties.membership.notAfter'),
      value: [toHumanFormat(group.membership.notAfter)],
    };
    baseProperties.push(membershipNotAfter);
  }

  if (group.membership?.fsroles) {
    const fsRoles: TranslatableProperty = {
      key: 'membership.fsroles',
      label: t('properties.membership.fsroles'),
      // fsroles is an array
      value: group.membership.fsroles,
    };
    baseProperties.push(fsRoles);
  }

  if (group.grep?.code) {
    const grepCode: TranslatableProperty = {
      key: 'grep.code',
      label: t('properties.grep.code'),
      value: [group.grep.code],
    };
    baseProperties.push(grepCode);
  }

  return baseProperties;
}

interface GroupCardProps {
  group: Group;
}

/**
 * Informative card containing information about a
 * group and a user's relation to the group.
 * */
function GroupCard({ group }: GroupCardProps): React.JSX.Element {
  const { t } = useTranslation('groups');
  const windowWidth = useWindowWidth();
  const types = group.type.split(':');
  const primaryType = lastItem<string>(types) ?? '';

  const { accessToken } = useAuthInformation();
  const groupId = primaryType === 'ad-hoc' ? group.id.replace('fc:adhoc:', '') : undefined;

  const { group: adhocGroup } = useRetrieveAdhocGroupDetails({ accessToken, groupId, currentMembership: group.membership });

  const { eduOrgHomePageURI } = group;

  const detailedAttributes = extractDetailedProperties(group, t);

  const name = getMostPreciseName(group);

  const header = (
    <header className="groupcard__content__header">
      {eduOrgHomePageURI ? (
        <a href={eduOrgHomePageURI} target="_blank" rel="noreferrer noopener" className="groupcard__content__header__link">
          <h2>{name}</h2>
        </a>
      ) : (
        <h2>{name}</h2>
      )}
    </header>
  );

  return (
    <div className="groupcard">
      <GroupSymbol type={primaryType} />
      <section className="groupcard__content">
        {header}
        {group.description && <p className="groupcard__content__description">{group.description}</p>}

        <GroupProperties group={group} primaryType={primaryType} membership={group.membership} />

        <GenericTable>
          {detailedAttributes.map((attribute) => (
            <CollapsableTableRow key={attribute.key} attribute={attribute} collapsed={isCollapsed(windowWidth, 650)} />
          ))}
        </GenericTable>

        {!!adhocGroup && <AdhocGroupAdmin group={adhocGroup} />}
      </section>
    </div>
  );
}
export default GroupCard;
