import React, { ErrorInfo } from 'react';
import { Trans } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import GenericErrorCard from '../ui/GenericErrorCard';

/**
 * Outer application error boundrary to handle any uncatched events.
 *
 * Errors are reported to Sentry, with additional userinfo and tags
 * available.
 * */
export class OuterErrorBoundary extends React.Component {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
    Sentry.withScope((scope) => {
      if (errorInfo) {
        scope.setExtras({ errorInfo });
      }
      Sentry.captureException(error);
    });
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    // Do not use Layout, because it requires React router and Redux,
    // and we want this ErrorBoundary to be minimal
    return (
      <main data-testid="outererrorboundary">
        <GenericErrorCard
          title={<Trans ns={'globalError'} i18nKey={'somethingUnexpected'} />}
          error={
            <div>
              <p>
                <Trans ns={'globalError'} i18nKey={'applicationCrashed'} />

                <a href="https://www.feide.no/kontakt-oss" target="_blank" rel="noopener noreferrer">
                  <Trans ns={'globalError'} i18nKey={'contactUs'} />
                </a>
              </p>
              <p>
                <a href="/">{<Trans ns={'globalError'} i18nKey={'backToStart'} />}</a>
              </p>
            </div>
          }
        />
      </main>
    );
  }
}

export default OuterErrorBoundary;
