import React from 'react';

import NotificationCard from './NotificationCard';

import './index.scss';

function getTitleOrDefault(title?: React.ReactNode): React.ReactNode {
  if (!title) {
    return 'Noe gikk galt';
  }

  return title;
}

type GenericErrorCardProps = {
  title?: React.ReactNode;
  error: React.ReactNode;
};

function GenericErrorCard({ title = null, error }: GenericErrorCardProps): React.JSX.Element {
  return (
    <div className="errorview" aria-live="assertive">
      <NotificationCard title={getTitleOrDefault(title)} type="error">
        {error}
      </NotificationCard>
    </div>
  );
}

export default GenericErrorCard;
