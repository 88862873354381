import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useRetrieveContactInformation, { OrganizationContactInformation } from '../../../hooks/useRetrieveContactInformation';

import { getRealmFromPrincipalName } from '../../../util/feideUtil';
import { isEmptyObject } from '../../../util/objectUtil';

import './index.scss';

type PrioritizedContactLinkResult = {
  url: string;
  label: string;
};
/**
 * Returns the "best" contact link to the user and the language he/she speaks.
 *
 * 1. Will first attempt to find a support url in the same language the user speaks.
 * 2. Will use support email if org has no support page in their language
 * 3. If support email is also missing, but supportUrl in english exists, will it be used
 * 4. Last attempt before giving up is a phone number
 * 5. If all else fails, do we conclude that this org has no appropriate contact information
 * */
function prioritizedContactLink(contactInfo: OrganizationContactInformation, userLang: string): PrioritizedContactLinkResult | null {
  const { supportEmail, supportUrl, supportPhone } = contactInfo;

  // Support webpage in the same language as user is the best
  if (!isEmptyObject(supportUrl) && supportUrl && userLang in supportUrl) {
    const url = supportUrl[userLang as keyof typeof supportUrl]!;
    return { url, label: url };
  }

  // Support email is the second best
  if (supportEmail) {
    return { url: `mailto:${supportEmail}`, label: supportEmail };
  }

  // If possible fallback to english support page, if it exists
  if (supportUrl?.en) {
    return { url: supportUrl.en, label: supportUrl.en };
  }

  // Last attempt before giving up
  if (supportPhone) {
    return { url: `tel:${supportPhone}`, label: supportPhone };
  }
  return null;
}

type Props = {
  principalName: string;
};
function OrgContactInfo({ principalName }: Props): React.JSX.Element {
  const { i18n } = useTranslation();
  const realm = getRealmFromPrincipalName(principalName);
  const { contactInformation, isLoading } = useRetrieveContactInformation({ realm });

  if (isLoading) {
    return (
      <p className="orgcontactinfo orgcontactinfo-loading">
        <Trans ns="userinfo" i18nKey="basicInfo.orgContactLoading" />
      </p>
    );
  }

  const contactLink = contactInformation ? prioritizedContactLink(contactInformation, i18n.language) : null;

  if (!contactInformation || !contactLink) {
    return (
      <p className="orgcontactinfo">
        <Trans ns="userinfo" i18nKey="basicInfo.orgContactNoContact" />
      </p>
    );
  }

  return (
    <p className="orgcontactinfo">
      <Trans ns="userinfo" i18nKey="basicInfo.orgContactInfo" />
      &nbsp;
      <a className="pagelink contact-link" href={contactLink.url} target="_blank" rel="noreferrer noopener">
        {contactLink.label}
      </a>
    </p>
  );
}

export default OrgContactInfo;
