import React from 'react';
import useUserPreference from '../../../../hooks/useUserPreference';
import './index.scss';

interface ToggleSwitchProps {
  label: JSX.Element;
}

function ToggleSwitch({ label }: ToggleSwitchProps): React.JSX.Element {
  const { isDetailedInformationVisible, toggleShowDetailedInformation } = useUserPreference();
  const handleChange = () => {
    toggleShowDetailedInformation(!isDetailedInformationVisible);
  };

  return (
    <label>
      {label}
      <div className="switch">
        <input type="checkbox" onChange={handleChange} checked={isDetailedInformationVisible} />
        <span className="slider" />
      </div>
    </label>
  );
}

export default ToggleSwitch;
