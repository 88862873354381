import React from 'react';
import classNames from 'classnames';

import './index.scss';

/**
 * Same styled hamburger menu used on feide.no.
 * It is a controlled component, meaning that the parent component
 * tells if the component should be open or closed.
 * */
type Props = {
  onClick: (flag: boolean) => void;
  className: string;
  opened: boolean;
  text: string;
};

function FeideMenuButton({
  onClick, className, opened, text, ...other
}: Props): React.JSX.Element {
  const toggleOpen = () => {
    onClick(!opened);
  };

  return (
    <button
      onClick={toggleOpen}
      aria-label="Toggle navigation"
      aria-expanded={opened}
      className={classNames('menuexpandbutton', { 'menuexpandbutton--open': opened }, className)}
      {...other}
    >
      <span className="menuexpandbutton__icon" />
      <span className="menuexpandbutton__text">{text}</span>
    </button>
  );
}

export default FeideMenuButton;
