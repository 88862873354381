import { Moment } from 'moment';
import { useContext } from 'react';
import { AuthenticationContext } from '../../contexts/authenticationContext';

interface UseAuthInformation {
  accessToken?: string;
  expiresOn: Moment;
  error?: Error;
  /**
   * Loads the access token to our context and persists it to localStorage
   * */
  saveAccessToken: (accessToken: string, expiresOn: Moment) => void;
  /**
   * Clears the access token from our context and localStorage
   * */
  clearAccessToken: () => void;
}

function useAuthInformation(): UseAuthInformation {
  const { state, dispatch } = useContext(AuthenticationContext);

  const saveAccessToken = (accessToken: string, expiresOn: Moment) => {
    dispatch({
      type: 'STORE_ACCESS_TOKEN',
      accessToken,
      expiresOn,
    });
  };

  const clearAccessToken = () => {
    dispatch({ type: 'CLEAR_ACCESS_TOKEN' });
  };

  return {
    accessToken: state.accessToken,
    expiresOn: state.expiresOn,
    error: state.error,
    saveAccessToken,
    clearAccessToken,
  };
}

export default useAuthInformation;
