/**
 * Represents a value/property that is translated into
 * none, single or multiple of the expected locales.
 *
 * @example {"nb": "Eksempel Tjeneste", "en": Example Service", ...}
 * */
export interface TranslatedProperty {
  readonly en?: string;
  readonly nb?: string;
  readonly nn?: string;
  readonly se?: string;

  /**
   * Returned as a locale from idp.feide.no, for Authorized SAML services.
   * @deprecated Prefer using nb or nn when they are available, because they are more precise
   * */
  readonly no?: string;
}

/**
 * Type which lists the locales we partially or fully support
 * */
export type SupportedTranslations = keyof TranslatedProperty;

/**
 * Used when a property that normally is translated, may contain non-translated values.
 * I.e. name for SAML services supports nb, nn, en, etc, but name for OAuth only has a single value
 * */
export interface UnspecifiedTranslation {
  unspecified: string;
}

/**
 * Type utility function which verifies that a property is lacking translation support,
 * i.e. it uses the UnspecifiedTranslation.
 * */
export const hasUnspecifiedLanguage = (
  value: TranslatedProperty | UnspecifiedTranslation,
): value is UnspecifiedTranslation => 'unspecified' in value;
