import React from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import ConditionalMoreInfo from '../ConditionalMoreInfo';
import { isAddressAttribute, isSensitiveAttribute } from '../../../util/feideUtil';
import SensitiveDataField from './SensitiveDataField';
import { TranslatableProperty } from '../../group/GroupCard/index';

import './index.scss';

type TableColumnProps = {
  children: React.ReactNode;
  className?: string;
};
export function TableColumn({ children, className }: TableColumnProps): React.JSX.Element {
  return <td className={classNames('generictable__row__column', className)}>{children}</td>;
}

type TableRowProps = {
  children: React.ReactNode;
};

export function TableRow({ children }: TableRowProps): React.JSX.Element {
  return <tr className="generictable__row">{children}</tr>;
}

type AttributeValueProps = {
  name: string;
  value: string[];
};
/**
 * Genereal purpose component to render a Feide attribute
 * in a reader friendly format.
 *
 * Sensitive fields will be hidden behind a mask.
 * */
function AttributeValue({ name, value }: AttributeValueProps): React.JSX.Element {
  const normalized = value.join(', ');

  // Show some helpful text if no data is present
  if (normalized.length < 1) {
    return <Trans ns="userinfo">noData</Trans>;
  }

  if (isSensitiveAttribute(name)) {
    return <SensitiveDataField>{normalized}</SensitiveDataField>;
  }

  if (isAddressAttribute(name)) {
    return <>{normalized.replace('$', '; ')}</>;
  }

  return <>{normalized}</>;
}

type CollapsableTableRowProps = {
  attribute: TranslatableProperty;
  collapsed: boolean;
};
/**
 * Constructs a renderable table-row, based on metadata about
 * a feide-attribute
 * */
export function CollapsableTableRow({ attribute, collapsed }: CollapsableTableRowProps): React.JSX.Element {
  const key = (
    <>
      <strong>{attribute.label}</strong>
      <ConditionalMoreInfo>
        <code className="has-nowrapp">({attribute.key})</code>
      </ConditionalMoreInfo>
    </>
  );
  const value = <AttributeValue name={attribute.key} value={attribute.value!} />;

  // Use only one column in collapsed mode
  if (collapsed) {
    return (
      <TableRow>
        <TableColumn className="is-fullwidth">
          {key}
          <div className="is-vertically-spaced">{value}</div>
        </TableColumn>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableColumn>{key}</TableColumn>
      <TableColumn>{value}</TableColumn>
    </TableRow>
  );
}

type GenericTableProps = {
  children: React.JSX.Element[];
  className?: string;
};
function GenericTable({ children, className, ...other }: GenericTableProps): React.JSX.Element {
  return (
    <table {...other} className={classNames('generictable', className)}>
      <tbody>{children}</tbody>
    </table>
  );
}

export default GenericTable;
