import React, { MouseEvent } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import FeideLogo from '../../FeideLogo';

import './Logo.scss';
import * as Routes from '../../../../routes/constants';

interface Props extends RouteComponentProps {
  onClick: () => void;
}
function Logo({
  onClick,
  history,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  staticContext,
  ...other
}: Props): React.JSX.Element {
  const handleClick = (evt: MouseEvent) => {
    evt.preventDefault();

    // Notify callee
    onClick();

    // Limited support for listen on route click,
    // without overriding the redirect action.
    // So we must redirect manually (https://stackoverflow.com/a/48294835/4338833)
    history.push(Routes.PERSONALINFO);
  };

  return (
    <Link {...other} className="navbar-brand active" to={Routes.PERSONALINFO} onClick={handleClick}>
      <FeideLogo className="logo-link" />
      <strong className="logo-title">Innsyn</strong>
    </Link>
  );
}

export default withRouter(Logo);
