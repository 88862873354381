import { Group } from '../../../types/group';

export class TreeNode {
  group: Group | undefined;

  descendents: TreeNode[];

  constructor(group?: Group) {
    this.group = group;
    this.descendents = [];
  }
}

/**
 * BFS for detecting cycle in a graph
 * Returns false if no cycle
 * Returns a list of nodes that were visited if a cycle is found
 */
function detectCycle(node: TreeNode, visited: TreeNode[]): TreeNode[] | boolean {
  const children = node.descendents;
  for (let i = 0; i < children.length; i += 1) {
    if (visited.includes(children[i])) {
      return visited;
    }
    visited.push(children[i]);
    if (detectCycle(children[i], visited)) {
      return visited;
    }
  }
  return false;
}

/**
 * Break the cycle
 * Calls detectCycle to verify that the cycle is eliminated
 * Returns detached child (will become the root)
 */
function breakCycle(node: TreeNode, loopers: TreeNode[]): boolean | string {
  const nodeDesc = node.descendents;
  for (let i = 0; i < loopers.length; i += 1) {
    for (let j = 0; j < nodeDesc.length; j += 1) {
      if (nodeDesc[j].group === loopers[i].group) {
        const element = nodeDesc[j];
        nodeDesc.splice(j, 1);
        if (!detectCycle(node, []) && element.group) {
          return element.group.id;
        }
        nodeDesc.splice(j, 0, element);
      }
    }
  }
  return false;
}

type Nodes = {
  [key: string]: TreeNode;
};
/**
 * Makes a list of all nodes with their children
 */
function createGraph(data: Group[]): Nodes {
  const nodes: Nodes = {};

  for (let i = 0; i < data.length; i += 1) {
    // Check if node exists in 'nodes'
    if (nodes[data[i].id]) {
      // Add group data to node
      nodes[data[i].id].group = data[i];
    } else {
      // Create new node
      nodes[data[i].id] = new TreeNode(data[i]);
    }

    const { parent } = data[i];
    // If node is a child
    if (parent !== undefined) {
      // If parent is not itself
      if (parent !== data[i].id) {
        // Check if parent does not exist
        if (!nodes[parent]) {
          // Create empty parent
          nodes[parent] = new TreeNode();
        }
        // Connect to parent
        nodes[parent].descendents.push(nodes[data[i].id]);
      } else {
        // Remove parent and create a TreeNode
        nodes[data[i].id] = new TreeNode(data[i]);
        const { group } = nodes[data[i].id];
        if (group) {
          group.parent = undefined;
        }
      }
    }
  }

  return nodes;
}

function getRootNodes(nodes: Nodes): TreeNode[] {
  const rootNodes: TreeNode[] = [];
  Object.keys(nodes).forEach((key) => {
    const nodeData = nodes[key].group;
    const nodeDesc = nodes[key].descendents;
    if (!nodeData) {
      for (let i = 0; i < nodeDesc.length; i += 1) {
        rootNodes.push(nodeDesc[i]);
      }
    } else if (!nodeData.parent) {
      rootNodes.push(nodes[key]);
    } else {
      const visited: TreeNode[] = [];
      const cycle = detectCycle(nodes[key], visited);
      // A cycle is detected
      if (cycle && typeof cycle !== 'boolean') {
        const newRoot: string | boolean = breakCycle(nodes[key], cycle);
        if (typeof newRoot !== 'boolean') {
          const { group } = nodes[newRoot];
          if (group) {
            group.parent = undefined;
          }
        }
      }
    }
  });
  return rootNodes;
}

function createGroupsTree(data: Group[]): TreeNode[] {
  const nodes = createGraph(data);
  return getRootNodes(nodes);
}
export default createGroupsTree;
