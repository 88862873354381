import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRequiredAuthInformation from '../../../hooks/auth/useRequiredAuthInformation';
import useCreateAdhocGroup, { BasicAdhocGroupDetails } from '../../../hooks/groups/useCreateAdhocGroup';
import GeneralModal from '../../ui/GeneralModal';
import { FeideFormError, FeideInput, FeideTextArea } from '../../ui/form';
import { FormValues } from '../GroupCard/adhocgroup/AdhocGroupAdmin';

type CreateAdhocGroupProps = {
  onCreation: () => void;
  onAbort: () => void;
};

function CreateAdhocGroup({ onCreation, onAbort }: CreateAdhocGroupProps): React.JSX.Element {
  const { t } = useTranslation('groups');

  const { accessToken } = useRequiredAuthInformation();
  const { createGroup, isCreating, hasBeenCreated } = useCreateAdhocGroup({ accessToken });

  const onSubmit = (values: BasicAdhocGroupDetails) => {
    createGroup(values);
  };

  useEffect(() => {
    // Notify the callback when the group has been created
    if (hasBeenCreated) {
      onCreation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBeenCreated]);

  const validateForm = (values: FormValues): Record<string, string> => {
    const errors: Record<string, string> = {};

    if (!values.name || values.name.length < 2) {
      errors.name = t('adhocGroup.nameTooShort');
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
        isPublic: false,
      }}
      validate={validateForm}
      onSubmit={onSubmit}
    >
      {(props) => {
        const {
          values, handleChange, handleBlur, handleSubmit, isValid, errors, resetForm,
        } = props;

        const handleAbort = () => {
          resetForm();
          onAbort();
        };

        return (
          <form onSubmit={handleSubmit} className="feide-form">
            <div className="feide-input-box">
              <label htmlFor="input-name" className="feide-label required">
                {t('adhocGroup.name')}
              </label>
              <p className="feide-help">{t('adhocGroup.nameDescription')}</p>
              {!!errors.name && <FeideFormError>{errors.name}</FeideFormError>}
              <FeideInput
                id="input-name"
                name="name"
                type="text"
                required={true}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="feide-input-box">
              <label htmlFor="input-description" className="feide-label">
                {t('adhocGroup.description')}
              </label>
              <FeideTextArea
                id="input-description"
                name="description"
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
              />
            </div>
            <div className="feide-input-box feide-checkbox">
              <div className="feide-checkbox__help">
                <label htmlFor="input-ispublic" className="feide-label">
                  {t('adhocGroup.visiblePublicly')}
                </label>
                <p className="feide-help">{t('adhocGroup.visiblePubliclyDescription')}</p>
              </div>
              <FeideInput
                id="input-ispublic"
                name="isPublic"
                type="checkbox"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.isPublic}
              />
            </div>
            <div className="feide-form__actions">
              <button type="submit" className="btn feide-primary" disabled={isCreating || !isValid}>
                {t('createGroup.save')}
              </button>
              <button type="reset" className="btn feide-secondary" onClick={handleAbort}>
                {t('createGroup.abort')}
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

function CreateAdhocGroupForm(): React.JSX.Element {
  const { t } = useTranslation('groups');

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className="margin-vertical">
      <button className="btn feide-secondary" onClick={() => setModalOpen(true)}>
        {t('createAdhocGroup')}
      </button>

      <GeneralModal isOpen={isModalOpen} header={t('createAdhocGroup')} onDismiss={() => setModalOpen(false)}>
        <CreateAdhocGroup onCreation={() => setModalOpen(false)} onAbort={() => setModalOpen(false)} />
      </GeneralModal>
    </div>
  );
}

export default CreateAdhocGroupForm;
