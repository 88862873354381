import { TranslatedProperty } from '../../types/global';
import {
  buildApiDataportenUri, buildClientadminDataportenUri, buildFeideConsentApiUri, getData,
} from '../../util/api';

export interface OAuthServiceOwner {
  readonly id: string;
  readonly name: string;
}
interface OAuthServiceOrganization {
  readonly id: string;
  readonly name: string;
}

export interface AuthorizedOAuthService {
  readonly userId: string;
  /**
   * The key is the identifying name of the client,
   * and the value is the list of scopes it requests/has been granted
   * */
  readonly apiGatekeeperScopes?: Record<string, string[]>;
  readonly issued: string;
  readonly scopes: string[];
  readonly client: OAuthServiceOwner;
  readonly owner: OAuthServiceOwner;
  readonly description: string;
  readonly name: string;
  readonly id: string;
  readonly redirectUri?: string[];
  readonly organization?: OAuthServiceOrganization;
  readonly authProviders: string[];
  readonly systemDescription?: string;
  readonly privacyPolicyUrl?: string;
  readonly homepageUrl?: string;
  readonly loginUrl?: string;
  readonly supportUrl?: string;
  /**
   * For most services status is set to null. However, for the few
   * it isn't, it contains ["Mandatory"]
   * */
  readonly status?: 'Mandatory'[];
  readonly postLogoutRedirectUri?: string[];
}

type AuthorizedOathServiceApiResponse = {
  userid: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  apigk_scopes?: Record<string, string[]>;
  issued: string;
  scopes: string[];
  client: OAuthServiceOwner;
  owner: OAuthServiceOwner;
  descr: string;
  name: string;
  id: string;
  redirect_uri?: string[];
  organization?: OAuthServiceOrganization;
  authproviders: string[];
  systemdescr?: string;
  privacypolicyurl?: string;
  homepageurl?: string;
  loginurl?: string;
  supporturl?: string;
  status?: 'Mandatory'[];
  post_logout_redirect_uri?: string[];
};

/**
 * Lists OAuth services that the client has explicitly authorized (consented)
 * */
export async function listAuthorizedOAuthServices(accessToken: string): Promise<AuthorizedOAuthService[]> {
  // The authorizations endpoint contain limited details about the client
  const authorizations = (await getData(accessToken, buildApiDataportenUri('authorizations/'))) as AuthorizedOathServiceApiResponse[];
  // Promise.all should return each detail in the same order, so we can target each service by index
  // const serviceDetails = await Promise.all(authorizations.map((service) => fetchOAuthServiceDetails(accessToken, service.client.id)));
  const serviceDetails = await Promise.all(
    authorizations.map(
      (service) => getData(
        accessToken,
        buildClientadminDataportenUri(`clients/${service.client.id}`),
        false,
      ) as Promise<AuthorizedOathServiceApiResponse>,
    ),
  );

  return authorizations.map((serviceRaw, index): AuthorizedOAuthService => {
    const details: AuthorizedOathServiceApiResponse = serviceDetails[index];

    return {
      id: details.id,
      name: details.name,
      userId: serviceRaw.userid,
      apiGatekeeperScopes: serviceRaw.apigk_scopes,
      issued: serviceRaw.issued,
      scopes: serviceRaw.scopes,
      client: serviceRaw.client,
      description: details.descr,
      redirectUri: details.redirect_uri,
      owner: details.owner,
      organization: details.organization,
      authProviders: details.authproviders,
      systemDescription: details.systemdescr,
      privacyPolicyUrl: details.privacypolicyurl,
      homepageUrl: details.homepageurl,
      loginUrl: details.loginurl,
      supportUrl: details.supporturl,
      status: details.status,
      postLogoutRedirectUri: details.post_logout_redirect_uri,
    };
  });
}

export interface MandatoryOAuthService {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly redirectUri: string[];
  readonly owner: OAuthServiceOwner;
  readonly organization?: OAuthServiceOrganization;
  readonly authProviders: string[];
  readonly systemDescription?: string;
  readonly privacyPolicyUrl?: string;
  readonly homepageUrl?: string;
  readonly loginUrl?: string;
  readonly supportUrl?: string;
  readonly scopes: string[];
  /**
   * For most services status is set to null. However, there are a few that shouldn't be null,
   * those contain ["Mandatory"]
   * */
  readonly status?: 'Mandatory'[];
  readonly postLogoutRedirectUri?: string[];
}

/**
 * Lists OAuth services that are mandatory for the user / organization the user is part of.
 * */
export async function listMandatoryOAuthServices(token: string): Promise<MandatoryOAuthService[]> {
  const results = (await getData(token, buildApiDataportenUri('authorizations/mandatory_clients/'))) as AuthorizedOathServiceApiResponse[];

  return results.map((serviceRaw) => ({
    id: serviceRaw.id,
    name: serviceRaw.name,
    description: serviceRaw.descr,
    redirectUri: serviceRaw.redirect_uri ?? [],
    owner: serviceRaw.owner,
    organization: serviceRaw.organization,
    authProviders: serviceRaw.authproviders,
    systemDescription: serviceRaw.systemdescr,
    privacyPolicyUrl: serviceRaw.privacypolicyurl,
    homepageUrl: serviceRaw.homepageurl,
    loginUrl: serviceRaw.loginurl,
    supportUrl: serviceRaw.supporturl,
    scopes: serviceRaw.scopes,
    status: serviceRaw.status,
    postLogoutRedirectUri: serviceRaw.post_logout_redirect_uri,
  }));
}

export interface AuthorizedSamlService {
  readonly url: string;
  readonly serviceId: string;
  readonly consentGiven: string;
  readonly lastAccess?: string;
  readonly name: TranslatedProperty;
  readonly description: TranslatedProperty;
  /**
   * Will typically match the properties in FeideAttributes. However,
   * it uses a slightly denormalized structure, such as eduPersonOrgDN:mail.
   * */
  readonly attributes: string[];
  readonly logo?: string;
  readonly kindId?: string;
}
/**
 * Lists all SAML services that the user has authorized (consented)
 * */
export async function listAuthorizedSamlServices(accessToken: string): Promise<AuthorizedSamlService[]> {
  type ConsentResponse = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    svc_id: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    consent_given: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_access?: string;
    name: TranslatedProperty;
    description: TranslatedProperty;
    attributes: string[];
    logo?: string;
    kindid?: string;
  };

  const dataAsObject = (await getData(accessToken, `${buildFeideConsentApiUri()}?action=list`)) as { [key: string]: ConsentResponse };
  // We receive the data as objects, but the results are much
  // easier to work with when we use arrays instead.
  return Object.keys(dataAsObject).map((serviceUrl) => {
    const service = dataAsObject[serviceUrl];

    return {
      url: serviceUrl,
      serviceId: service.svc_id,
      consentGiven: service.consent_given,
      lastAccess: service.last_access,
      name: service.name,
      description: service.description,
      attributes: service.attributes,
      logo: service.logo,
      kindId: service.kindid,
    };
  });
}
