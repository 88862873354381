import React, { useEffect } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import FeideButton from '../FeideButton';

import './index.scss';

Modal.setAppElement(document.getElementById('root')!);
/**
 * Internal button used to dismiss a modal
 */

type ButtonDismissProps = {
  children: string | undefined;
  onClick: () => void;
};

function ButtonDismiss({ children, onClick }: ButtonDismissProps): React.JSX.Element {
  return (
    <FeideButton className="buttondismiss" onClick={onClick} aria-label={children} isFlat>
      <span className="buttondismiss__hiddenlabel">{children}</span>
    </FeideButton>
  );
}

/**
 * General purpose react modal, designed to be accessible
 * and to give extensibility.
 *
 * ```
 * <GeneralModal
 *   isOpen={true}
 *   onDismiss={hideModal}
 *   header={<h2>Modal title</h2>}
 * >
 *   <p>Hello from modal</p>
 * </GeneralModal>
 * ```
 */

type GeneralModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  className?: string;
  header: React.ReactNode;
  children: React.ReactNode;
};
function GeneralModal({
  isOpen, onDismiss, className, header, children, ...other
}: GeneralModalProps): React.JSX.Element {
  useEffect(() => {
    const handleDismiss = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape') {
        onDismiss();
      }
    };

    // Only listen for user dismissal when modal is open
    if (isOpen) {
      document.addEventListener('keydown', handleDismiss);
    }

    return () => document.removeEventListener('keydown', handleDismiss);
  }, [isOpen, onDismiss]);

  if (!isOpen) {
    return <>{false}</>; // don't render anything if modal is not open
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onDismiss}
      className={classNames('generalmodal', 'generalmodal--open', className)}
      overlayClassName={classNames('backdrop', 'backdrop--open')}
      ariaHideApp={true}
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
      shouldCloseOnOverlayClick={true}
      {...other}
    >
      <header className="generalmodal__header">
        {header}
        <ButtonDismiss onClick={onDismiss}>Lukk</ButtonDismiss>
      </header>
      <div className="generalmodal__body">{children}</div>
      {/* eslint-disable-next-line */}
      <div className="backdrop__background" onClick={onDismiss} />
    </Modal>
  );
}
export default GeneralModal;
