import classNames from 'classnames';
import React from 'react';

import './form.scss';

export function FeideFormError({ children }: { children: React.ReactNode }) {
  return <p className="feide-input-error">{children}</p>;
}

type FeideInputProps = {
  id: string | undefined;
  value?: string;
  name: string;
  type: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  checked?: boolean;
  required?: boolean;
};

export function FeideInput({
  id = undefined,
  value,
  name,
  type = 'text',
  onChange,
  onBlur,
  className = undefined,
  checked = undefined,
  required = undefined,
}: FeideInputProps): React.JSX.Element {
  return (
    <input
      type={type}
      id={id}
      name={name}
      className={classNames('feide-input', className)}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      checked={checked}
      required={required}
      aria-required={required}
    />
  );
}

type FeideTextAreaProps = {
  id: string | undefined;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  className?: string;
  required?: boolean;
};
export function FeideTextArea({
  id = undefined,
  name,
  value,
  onChange,
  onBlur,
  className = undefined,
}: FeideTextAreaProps): React.JSX.Element {
  return (
    <textarea id={id} name={name} className={classNames('feide-input', className)} onChange={onChange} onBlur={onBlur}>
      {value}
    </textarea>
  );
}
