import React from 'react';
import './GroupList.scss';
import GroupCard from '../../features/group/GroupCard';
import { Group } from '../../types/group';

function GroupList({ groups }: { groups: Group[] }): React.JSX.Element {
  const cards = groups.map((element: Group) => {
    const { type } = element;

    return (
      <GroupCard
        key={element.id}
        group={{
          ...element,
          type,
          name: element.displayName ?? '',
        }}
      />
    );
  });

  return (
    <ul className="list-group">
      {cards.map((group, idx) => (
        <li key={`group-${idx}`}>{group}</li>
      ))}
    </ul>
  );
}

export default GroupList;
