/**
 * Retrieves the last item from an array
 * @param {array} array
 * @return {null|any}
 * */
// eslint-disable-next-line import/prefer-default-export
export function lastItem<T>(array: Array<T>): T | null {
  if (array.length < 1) {
    return null;
  }

  return array[array.length - 1];
}
