import { Moment } from 'moment';
import moment from 'moment/moment';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import useAuthInformation from './useAuthInformation';

interface UseRequiredAuthInformation {
  accessToken: string;
  expiresOn: Moment;
}

export function hasTokenExpired(expiresOn: Moment): boolean {
  const timenow = moment();
  return timenow.isSameOrAfter(expiresOn);
}

/**
 * Similar to useAuthInformation but makes sure that accessToken is not a falsy value.
 * Otherwise, the user is redirected to /login.
 * */
function useRequiredAuthInformation(): UseRequiredAuthInformation {
  const { accessToken, expiresOn, clearAccessToken } = useAuthInformation();
  const location = useLocation();

  if (!accessToken || hasTokenExpired(expiresOn)) {
    console.warn('Access token is not available for application, or token has expired. Forcing user to sign in...');
    // Make sure previous access token is cleared first
    clearAccessToken();
    const loginParams = {
      returnTo: location.pathname + location.search,
    };
    window.location.replace(`/login?${qs.stringify(loginParams)}`);
  }

  // TypeScript's type check does not recognize that window.location.replace
  // will ensure that the accessToken never will be "falsy" at this stage
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { expiresOn, accessToken: accessToken! };
}

export default useRequiredAuthInformation;
