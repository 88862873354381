import React from 'react';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import FeideLogo from '../FeideLogo';
import ToggleSwitch from './ToggleSwitch';

import './Footer.scss';

interface LanguageProps {
  t: TFunction;
}

function Footer({ t }: LanguageProps): React.JSX.Element {
  return (
    <footer className="footer content-footer">
      <FeideLogo className="branding ml-auto mr-auto" />
      <p className="mb-4">{t('footer.description')}</p>
      <ToggleSwitch label={t('footer.moreInfo')} />

      <p>
        <a href="https://feide.no/kontakt-oss" className="pagelink" target="_blank" rel="noreferrer noopener">
          {t('footer.contact')}
        </a>
        {/* eslint-disable-next-line no-irregular-whitespace */}
        &nbsp;•&nbsp;
        <a href="https://feide.no/personvern-og-samtykke" className="pagelink" target="_blank" rel="noreferrer noopener">
          {t('footer.privacy')}
        </a>
        {/* eslint-disable-next-line no-irregular-whitespace */}
        &nbsp;•&nbsp;
        <a href="https://feide.no/" className="pagelink" target="_blank" rel="noreferrer noopener">
          Feide
        </a>
        {/* eslint-disable-next-line no-irregular-whitespace */}
        &nbsp;​•&nbsp;
        <a href="https://sikt.no/" className="pagelink" target="_blank" rel="noreferrer noopener">
          Sikt
        </a>
        {/* eslint-disable-next-line no-irregular-whitespace */}
        &nbsp;​•&nbsp;
        <a
          href="https://uustatus.no/nb/erklaringer/publisert/0f182b3b-58f4-4e1f-9233-b9ea7a381f82"
          className="pagelink"
          target="_blank"
          rel="noreferrer noopener"
        >
          Tilgjengelighetserklæring
        </a>
      </p>
    </footer>
  );
}

export default withTranslation()(Footer);
