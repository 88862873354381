/**
 * Checks if an object is empty.
 *
 * @param {object} obj The object to check
 * @return {boolean} True if {} or any 'falsy' value
 * */
export function isEmptyObject(obj?: object | null): boolean {
  if (!obj) {
    return true;
  }

  return Object.keys(obj).length < 1;
}

/**
 * Convert a "list of objects" over to an "object
 * of objects" from a key in each object.
 *
 * @throws {ReferenceError} If a key returned from keyExtract is undefined
 * @param array [{ id: 'tests', name: 'Test' }, { id: ... }]
 * @param keyExtract Called for each item, expects a key-value to be returned
 *                              If an item has no key, should it return 'null', not undefined
 * @return { 'tests': { id: 'tests', name: 'Test' }, ... }
 * */
export function arrayToObject<T>(array: T[], keyExtract: (item: T) => string | number): Record<string, T> {
  return array.reduce((obj: Record<string, T>, item: T) => {
    const key = keyExtract(item);

    // undefined keys point to unhandled missing keys
    if (key === undefined) {
      throw ReferenceError(`No key returned for object ${JSON.stringify(item)}`);
      // If key is null, should we ignore it
    } else if (key === null) {
      return obj;
    }

    // eslint-disable-next-line no-param-reassign
    obj[key] = item;
    return obj;
  }, {});
}

/**
 * Merges two objects together shallowly.
 * */
export function merge(obj1: object, obj2: object) {
  return Object.assign(obj1 || {}, obj2 || {});
}

export function groupBy<T>(arr: T[], criteria: (item: T) => string | number) {
  return arr.reduce((obj: Record<string, T[]>, item: T) => {
    // Check if the criteria is a function to run on the item or a property of it
    const key = criteria(item);

    // If the key doesn't exist yet, create it
    if (!(key in obj)) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = [];
    }

    // Push the value to the object
    obj[key].push(item);

    // Return the object to the next item in the loop
    return obj;
  }, {});
}

export function deDuplicateByKey<T>(items: T[], keyFunction: (item: T) => string): T[] {
  const seen = new Set();
  return items.filter((item) => {
    const key = keyFunction(item);
    const isDuplicate = seen.has(key);
    seen.add(key);
    return !isDuplicate;
  });
}
