import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRequiredAuthInformation from '../../../../hooks/auth/useRequiredAuthInformation';
import useMutateAdhocGroup from '../../../../hooks/groups/useMutateAdhocGroup';
import { useDeleteAdhocGroup, useLeaveAdhocGroup } from '../../../../hooks/groups/useMutateAdhocGroupMember';
import { AdhocGroup, extractCurrentMember } from '../../../../hooks/groups/useRetrieveAdhocGroupDetails';
import GeneralModal from '../../../ui/GeneralModal';
import InlineConfirmableButton from '../../../ui/InlineConfirmableButton';
import InlineLoadingAnimation from '../../../ui/InlineLoadingAnimation';
import { FeideFormError, FeideInput, FeideTextArea } from '../../../ui/form';
import AdhocGroupMembers from './AdhocGroupMembers';
import DeleteGroupSection from './DeleteGroupSection';

import './AdhocGroupAdmin.scss';
import AdhocGroupInvitations from './AdhocGroupInvitations';

export interface FormValues {
  name: string;
  description: string;
  isPublic: boolean;
}

function GroupEditForm({ group, onAbort }: { group: AdhocGroup; onAbort: () => void }): React.JSX.Element {
  const { t } = useTranslation('groups');

  const { accessToken } = useRequiredAuthInformation();
  const { updateGroup, isUpdating } = useMutateAdhocGroup({ accessToken, groupId: group.id });

  const onSubmit = (values: FormValues) => {
    updateGroup(values);
  };

  const validateForm = (values: FormValues): Record<string, string> => {
    const errors: Record<string, string> = {};

    if (!values.name || values.name.length < 2) {
      errors.name = t('adhocGroup.nameTooShort');
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        name: group.name,
        description: group.description,
        isPublic: group.isPublic,
      }}
      validate={validateForm}
      onSubmit={onSubmit}
    >
      {(props) => {
        const {
          errors, values, handleChange, handleBlur, handleSubmit, isValid, resetForm,
        } = props;

        const handleAbort = () => {
          resetForm();
          onAbort();
        };

        return (
          <form onSubmit={handleSubmit} className="feide-form">
            <div className="feide-input-box">
              <label htmlFor="input-name" className="feide-label required">
                {t('adhocGroup.name')}
              </label>
              {!!errors.name && <FeideFormError>{errors.name}</FeideFormError>}
              <FeideInput
                id="input-name"
                name="name"
                type="text"
                required={true}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="feide-input-box">
              <label htmlFor="input-description" className="feide-label">
                {t('adhocGroup.description')}
              </label>
              <FeideTextArea
                id="input-description"
                name="description"
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
              />
            </div>
            <div className="feide-input-box feide-checkbox">
              <div className="feide-checkbox__help">
                <label htmlFor="input-ispublic" className="feide-label">
                  {t('adhocGroup.visiblePublicly')}
                </label>
                <p className="feide-help">{t('adhocGroup.visiblePubliclyDescription')}</p>
              </div>
              <FeideInput
                id="input-ispublic"
                name="isPublic"
                type="checkbox"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.isPublic}
              />
            </div>

            <div className="feide-form__actions">
              <button type="submit" className="btn feide-primary" disabled={isUpdating || !isValid}>
                {t('groupEdit.save')}
              </button>
              <button type="reset" className="btn feide-secondary" onClick={handleAbort}>
                {t('groupEdit.abort')}
              </button>
              {isUpdating && <InlineLoadingAnimation />}
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

function LeaveGroupSection({ onLeave, isLeaving }: { onLeave: () => void; isLeaving: boolean }): React.JSX.Element {
  const { t } = useTranslation('groups');

  const [isPendingLeavingGroup, setPendingLeavingGroup] = useState(false);

  const onLeaveGroup = () => {
    setPendingLeavingGroup(false);
    onLeave();
  };
  return (
    <section className="leaveadhocgroupsection margin-top">
      <h4>{t('leaveGroup.leaveGroup')}</h4>
      <button className="btn feide-dangerous" onClick={() => setPendingLeavingGroup(true)} disabled={isPendingLeavingGroup || isLeaving}>
        {t('leaveGroup.leaveGroup')}
      </button>

      {isPendingLeavingGroup && (
        <InlineConfirmableButton
          confirmationTitle={t('leaveGroup.leaveConfirmation')}
          confirmationMessage={t('leaveGroup.leaveConfirmationDescription')}
          onConfirm={onLeaveGroup}
          onAbort={() => setPendingLeavingGroup(false)}
        />
      )}
    </section>
  );
}

function AdhocGroupAdmin({ group }: { group: AdhocGroup }): React.JSX.Element {
  const { t } = useTranslation('groups');

  const [isModalOpen, setModalOpen] = useState(false);

  const { accessToken } = useRequiredAuthInformation();
  const { leaveGroup, isLeaving, hasLeft } = useLeaveAdhocGroup({ accessToken, groupId: group.id });
  const { deleteGroup, isDeleting, hasDeleted } = useDeleteAdhocGroup({ accessToken, groupId: group.id });

  const currentMember = extractCurrentMember(group.members);
  /**
   * Admin and owners of a group are the only one with editing permissions
   * */
  const hasCurrentUserEditPermissions = currentMember?.type === 'admin' || !!currentMember?.isOwner;

  useEffect(() => {
    // Close the modal after the user has or the group has been deleted
    if (hasLeft || hasDeleted) {
      setModalOpen(false);
    }
  }, [hasLeft, hasDeleted]);

  const onLeaveGroup = () => {
    leaveGroup();
  };

  const onDeleteGroup = () => {
    deleteGroup();
  };

  return (
    <div className="adhocgroupadmin">
      <button className="btn feide-secondary" onClick={() => setModalOpen(true)}>
        {t('adminAdhocGroup')}
      </button>

      <GeneralModal isOpen={isModalOpen} header={t('adminAdhocGroup')} onDismiss={() => setModalOpen(false)}>
        {hasCurrentUserEditPermissions && <GroupEditForm group={group} onAbort={() => setModalOpen(false)} />}
        <AdhocGroupMembers members={group.members} groupId={group.id} hasCurrentUserEditPermissions={hasCurrentUserEditPermissions} />
        {hasCurrentUserEditPermissions && <AdhocGroupInvitations groupId={group.id} accessToken={accessToken} />}
        {/* Owner is not allowed to leave the group he/she has created */}
        {!currentMember?.isOwner && <LeaveGroupSection onLeave={onLeaveGroup} isLeaving={isLeaving} />}
        {hasCurrentUserEditPermissions && <DeleteGroupSection onDelete={onDeleteGroup} isDeleting={isDeleting} />}
      </GeneralModal>
    </div>
  );
}
export default AdhocGroupAdmin;
