import React, { useState } from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import './index.scss';

interface TitleHeaderProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  onClick: () => void;
  isExpanded: boolean;
}
function TitleHeader({
  title, onClick, isExpanded, ...other
}: TitleHeaderProps): React.JSX.Element {
  const arrowClassName = classNames('fas expandablepanel__header__arrow', {
    'fa-chevron-down': !isExpanded,
    'fa-chevron-up': isExpanded,
  });

  return (
    <header>
      <button {...other} onClick={onClick} className="expandablepanel__header">
        <em className="expandablepanel__header__label">{title}</em>
        <i className={arrowClassName} />
      </button>
    </header>
  );
}

type ExpandablePanelProps = {
  title: string;
  children: React.ReactNode;
  isConnected: boolean;
};

function ExpandablePanel({ title, children, isConnected = false }: ExpandablePanelProps): React.JSX.Element {
  // aria-labelledby and controls uses id-attribute
  // to correlate control elements with some content
  const identifier = uuid();
  const controlIdentifier = `expandablepanelHeader-${identifier}`;
  const contentIdentifier = `expandablepanelContent-${identifier}`;

  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => setExpanded(!expanded);

  return (
    <section className={classNames('card dropdown-card expandablepanel', { 'expandablepanel--connected': isConnected })}>
      <TitleHeader
        id={controlIdentifier}
        title={title}
        onClick={toggleExpansion}
        isExpanded={expanded}
        aria-expanded={expanded}
        aria-controls={contentIdentifier}
      />
      <div
        id={contentIdentifier}
        className={classNames('expandablepanel__body', { 'expandablepanel__body--expanded': expanded })}
        aria-labelledby={controlIdentifier}
      >
        {children}
      </div>
    </section>
  );
}

export default ExpandablePanel;
