import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { TranslatedProperty, UnspecifiedTranslation } from '../../types/global';
import {
  AuthorizedOAuthService, listAuthorizedOAuthServices, listAuthorizedSamlServices, listMandatoryOAuthServices,
} from './api';

export type ServiceType = 'oauth' | 'saml';

export interface AuthorizedService {
  readonly id: string;
  readonly type: ServiceType;
  readonly name: TranslatedProperty | UnspecifiedTranslation;
  readonly description: TranslatedProperty | UnspecifiedTranslation;
  readonly owner?: string;
  readonly hostOrganization?: string;
  readonly logoUrl?: string;
  readonly canRevokeConsent: boolean;
  readonly scopes: string[];
}

/**
 * Checks if the service is not part of the mandatory services
 * @param serviceId The service we want to check
 * @param mandatoryServiceIds List of services where consent cannot be revoked
 * @return True when the service's authorization (consent) can be revoked by the user
 * */
function canRevokeOAuthConsent(serviceId: string, mandatoryServiceIds: string[]): boolean {
  return !mandatoryServiceIds.some((mandatoryId) => mandatoryId === serviceId);
}

async function listAllAuthorizedServices(accessToken: string): Promise<AuthorizedService[]> {
  const [authorizedOAuthServices, mandatoryOAuthServices, authorizedSamlServices] = await Promise.all([
    listAuthorizedOAuthServices(accessToken),
    listMandatoryOAuthServices(accessToken),
    listAuthorizedSamlServices(accessToken),
  ]);

  const saml = authorizedSamlServices.map(
    (service): AuthorizedService => ({
      id: service.serviceId,
      type: 'saml',
      scopes: service.attributes,
      name: service.name,
      description: service.name,
      owner: undefined,
      hostOrganization: undefined,
      logoUrl: service.logo,
      // This is determined by the organization's policy globally, which is fetched at a later stage
      // (see useOrgConsentPolicy)
      canRevokeConsent: false,
    }),
  );

  const mandatoryOauth = mandatoryOAuthServices.map((service) => service.id);

  const authorizedOAuth = authorizedOAuthServices.map(
    (service: AuthorizedOAuthService): AuthorizedService => ({
      id: service.client.id,
      type: 'oauth',
      scopes: service.scopes,
      name: { unspecified: service.client.name },
      description: { unspecified: service.description },
      owner: service.owner.name,
      hostOrganization: service.organization?.name,
      // Mandatory services can by definition not be revoked
      canRevokeConsent: canRevokeOAuthConsent(service.id, mandatoryOauth),
    }),
  );

  // Merge both SAML and OAuth services into the same list
  return saml.concat(authorizedOAuth);
}

interface UseListMyAuthorizedServicesResults {
  services: AuthorizedService[];
  isLoading: boolean;
  error?: Error;
}

/**
 * Lists all services the user has authorized (consented) directly,
 * or indirectly by their host organization.
 *
 * Some services are mandatory meaning that authorization cannot be revoked
 * */
function useListMyAuthorizedServices(params: { accessToken?: string }): UseListMyAuthorizedServicesResults {
  const { accessToken } = params;
  const { i18n } = useTranslation();

  const { data, error, isLoading } = useQuery<AuthorizedService[], Error>(
    // The values returned from Dataporten depends on what language the user has selected
    ['services', 'authorized', i18n.language],
    // This function is not called unless accessToken is present
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => listAllAuthorizedServices(accessToken!),
    {
      enabled: !!accessToken,
      onError(err) {
        Sentry.captureException(err);
      },
    },
  );

  return {
    isLoading,
    services: data ?? [],
    error: error ?? undefined,
  };
}

export default useListMyAuthorizedServices;
