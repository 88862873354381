import React, { useCallback, useEffect, useState } from 'react';
import './AdhocGroupMembers.scss';
import { useMutation } from 'react-query';
import {
  buildApiDataportenUri, deleteData, getData, postRequest,
} from '../../../../util/api';

import { FeideFormError, FeideInput } from '../../../ui/form';

interface AdhocGroupInvitationProps {
  readonly accessToken: string;
  readonly groupId: string;
}

interface AdhocGroupInvitation {
  invitationId: string;
  groupid: string;
  invitedBy: string;
  email: string;
  memberType: string;
  added: string;
  expired: boolean;
}

function AdhocGroupInvitations({ accessToken, groupId }: AdhocGroupInvitationProps): React.JSX.Element {
  const [email, setEmail] = useState('');
  const [invitations, setInvitations] = useState<AdhocGroupInvitation[]>([]);
  const [isCheckedAdmin, setIsCheckedAdmin] = useState<boolean>(false);
  const [inviteError, setInviteError] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);

  const getInvitations = useCallback(async () => {
    const details = (await getData(accessToken, buildApiDataportenUri(`adhocgroups/${groupId}/invitations/`))) as AdhocGroupInvitation[];
    setInvitations(details);
  }, [accessToken, groupId]);

  useEffect(() => {
    getInvitations();
  }, [getInvitations]);

  useEffect(() => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,6})*$/;
    setValidEmail(re.test(email));
  }, [email]);

  const memberType = isCheckedAdmin ? 'admin' : 'member';
  const mutation = useMutation<object, Error>(
    () => postRequest(accessToken, buildApiDataportenUri(`adhocgroups/${groupId}/invitations/`), { email, memberType }),
    {
      onSuccess() {
        setInviteError(false);
        setEmail('');
      },
      onError(error) {
        console.error(`Failed to invite ${email} to group (GroupId: ${groupId})`, error);
        setInviteError(true);
      },
      onSettled() {
        if (isCheckedAdmin) {
          setIsCheckedAdmin(false);
        }
        getInvitations();
      },
    },
  );

  const withdrawInvitation = async (groupid: string, invitationId: string) => {
    await deleteData(accessToken, buildApiDataportenUri(`adhocgroups/${groupid}/invitations/${invitationId}`));
    getInvitations();
  };

  return (
    <div>
      <div className="feide-form">
        <div className="feide-input-box">
          <label htmlFor="input-name" className="feide-label">
            Invite other members (email)
          </label>
          {!validEmail && <FeideFormError>* Must contain a valid email address</FeideFormError>}
          <FeideInput
            id="input-email"
            name="email"
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
        </div>
        <div className="feide-input-box feide-checkbox">
          <div className="feide-checkbox__help">
            <label htmlFor="input-isadmin" className="feide-label">
              Make Admin
            </label>
            <p className="feide-help">Invited person will be added as group administrator</p>
          </div>

          <FeideInput
            id="input-isadmin"
            name="isAdmin"
            type="checkbox"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setIsCheckedAdmin(e.target.checked);
            }}
            checked={isCheckedAdmin}
          />
        </div>
        {inviteError && <p className="feide-input-error">Error creating invite</p>}
        <button
          className="btn feide-primary"
          id="invite-btn"
          onClick={() => {
            mutation.mutate();
          }}
          disabled={!validEmail}
        >
          Send invite
        </button>
      </div>
      <section className="adhocgroupmembers">
        <h3>Pending group invitations</h3>
        <ul>
          {invitations?.map((invitation) => (
            <li key={invitation.invitationId}>
              <div className="adhocgroupmembersummary">
                <div className="adhocgroupmembersummary__metadata">
                  <div className="adhocgroupmembersummary__metadata__name">
                    {invitation.email}
                    <div className="feide-help">Membertype: {invitation.memberType}</div>
                    <div className="feide-help">Status: {invitation.expired ? '(Expired)' : 'Active'}</div>
                  </div>
                </div>
                <div className="adhocgroupmembersummary__actions">
                  <button className="btn feide-dangerous" onClick={() => withdrawInvitation(groupId, invitation.invitationId)}>
                    Withdraw
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}
export default AdhocGroupInvitations;
