import React from 'react';

import './index.scss';

function selectIconType(type: string): string {
  const typeToIcon: { [key: string]: string } = {
    error: 'fa-exclamation-circle',
    warning: 'fa-exclamation-triangle',
    info: 'fa-info-circle',
  };

  return typeToIcon[type];
}

type Props = {
  title: React.ReactNode;
  type: 'info' | 'warning' | 'error';
  children: React.ReactNode;
};
/**
 * Inspired by https://design.nav.no/components/alertstripe/
 * */
function NotificationCard({ title, type, children }: Props): React.JSX.Element {
  return (
    <div className={`notificationcard is-${type}`}>
      <i className={`fas ${selectIconType(type)} notificationcard-icon`} />

      <section className="notificationcard-body">
        <h3 className="notificationcard-header">{title}</h3>

        <div className="notificationcard-content">{children}</div>
      </section>
    </div>
  );
}

export default NotificationCard;
