const HASH_ALGORITHM = 'SHA-256';

interface ExtendedWindow extends Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  msCrypto: any;
}
declare const window: ExtendedWindow;
/**
 * Helper method to find the best supported
 * version of Crypto in the browser.
 *
 * IE11 does not support window.crypto,
 * but has window.msCrypto which has subtle differences.
 * msCrypto.subtle.digest returns CryptoOperation, while
 * crypto.subtle.digest returns a promise
 *
 * @return {object} { crypto: <Selected crypto instance>, version: <Label on what was selected> }
 * */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getWindowCrypto(): { crypto: any; version: string } {
  if (window.crypto) {
    return {
      crypto: window.crypto,
      version: 'default',
    };
  }

  if (window.msCrypto) {
    return {
      crypto: window.msCrypto,
      version: 'msIe',
    };
  }

  throw new ReferenceError('No cryptography utilities is supported');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCryptoSubtle(crypto: Crypto | any): SubtleCrypto | any {
  if ('subtle' in crypto) {
    return crypto.subtle;
  }

  if ('webkitSubtle' in crypto) {
    return crypto.webkitSubtle;
  }

  throw new ReferenceError('This browser does not have window.crypto.subtle support native or through browser prefix');
}

/**
 * Uses window.msCrypto to build a SHA-256 hash in IE11,
 * wrapped in a Promise object.
 * */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sha256MS(buffer: ArrayBuffer, crypto: any): Promise<ArrayBuffer> {
  return new Promise((rsv, rr) => {
    /**
     * @var {CryptoOperation}
     * */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const operation: any = crypto.subtle.digest(HASH_ALGORITHM, buffer);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operation.oncomplete = (evt: any) => rsv(evt.target.result);
    operation.onerror = () => rr(new Error('msCrypto.subtle.digest failed to encrypt data'));
    operation.onabort = () => rr(new Error('msCrypto.subtle.digest was aborted'));
  });
}

async function sha256Compatibility(buffer: ArrayBuffer): Promise<ArrayBuffer> {
  const crypto = getWindowCrypto();

  if (crypto.version === 'msIe') {
    console.warn(
      'Identified IE11 or Legacy environment. This does not support window.crypto and TextEncoder, and has to use fallback functions',
    );
    return sha256MS(buffer, crypto.crypto);
  }

  const subtle = getCryptoSubtle(crypto.crypto);

  return subtle.digest(HASH_ALGORITHM, buffer);
}

/**
 * Uses: https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
 *       Only with Uint8Array
 * */
function encodeCompat(str: string): Uint8Array {
  const buf = new ArrayBuffer(str.length); // 2 bytes for each char
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return bufView;
}

function encode(payload: string): ArrayBuffer {
  // eslint-disable-next-line compat/compat -- This line specifically checks for browser support for `window.TextEncoder`,
  // so the warning about browser compatibility here is bogus.
  if (!('TextEncoder' in window)) {
    return encodeCompat(payload);
  }

  // Compatibliity is solved dynamically,
  // because no stable polyfill exists
  // eslint-disable-next-line compat/compat
  const encoder = new TextEncoder();
  return encoder.encode(payload);
}

/**
 * Generates SHA-256 hash. Should support IE11 and Safari 9
 * @param {string} payload
 * @return {Promise} Hash as ByteArray
 * */
// eslint-disable-next-line import/prefer-default-export
export async function sha256(payload: string): Promise<ArrayBuffer> {
  const message = encode(payload);

  return sha256Compatibility(message);
}
