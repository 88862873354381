import { Moment } from 'moment';
import moment from 'moment/moment';

/**
 * Calculates the Access Token's expiration time,
 * based on how long the token is valid (expiresIn)
 *
 * Taken from: feide/oauth-play
 *
 * @return {Moment}
 * */
// eslint-disable-next-line import/prefer-default-export
export function getExpirationTime(expiresIn: number): Moment {
  // A difference (in seconds), to account for delay
  // between the token issuance and when this page is visited.
  const expirationThreshold = 10;

  if (!expiresIn || expiresIn < expirationThreshold) {
    return moment();
  }

  return moment().subtract(expirationThreshold, 'seconds').add(expiresIn, 'seconds');
}
