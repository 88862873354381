import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import MyAuthorizedServices from './MyAuthorizedServices';
import AllAvailableServices from './AllAvailableServices';
import Tabs from '../../features/ui/Tabs';
import serviceImg from '../../icons/service.svg';
import { getQueryParams, setQueryParams } from '../../util/urlUtil';
import './index.scss';

function ServicesOverviewPage(): React.JSX.Element {
  const { t } = useTranslation('services');
  const location = useLocation();
  const history = useHistory();

  const queryParams = getQueryParams(location);
  const shouldShowAllServices = queryParams.show === 'all';

  const handleTabUpdate = () => {
    const nextView = shouldShowAllServices ? undefined : 'all';

    setQueryParams(location, history, { show: nextView });
  };

  const tabs = [
    { key: 'my', label: t('applications.my-applications') },
    { key: 'all', label: t('applications.available-applications') },
  ];

  const currentTab = tabs.find((tab) => tab.key === queryParams.show) || tabs[0];

  // Update title page as we navigate the sidebar
  document.title = `Innsyn - Feide - ${t('applications.applications')}`;

  return (
    <div className="collapse-wrapper content-box">
      <header className="titlewrapper">
        <img src={serviceImg} className="logo" alt="" />
        <h1 id="main" className="pagetitle">
          {t('applications.applications')}
        </h1>
      </header>
      <p>{t('applications.description')}</p>
      <Tabs onChange={handleTabUpdate} currentTab={currentTab} tabs={tabs} />
      <div className="card apps-new-border">
        <ul className="list-group list-group-flush">{!shouldShowAllServices ? <MyAuthorizedServices /> : <AllAvailableServices />}</ul>
      </div>
    </div>
  );
}

export default ServicesOverviewPage;
