import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import CreateAdhocGroupForm from '../../features/group/CreateAdhocGroupForm';

import Tabs from '../../features/ui/Tabs';
import ListView from './ListView';
import TreeView from './TreeView';
import { getQueryParams, setQueryParams } from '../../util/urlUtil';

/**
 * Page to present the groups a user belongs to.
 *
 * A user has the option of presenting this as a list or a tree-structure
 * */
function GroupPage({ location, history }: RouteComponentProps): React.JSX.Element {
  const { t } = useTranslation('groups');

  const queryParams = getQueryParams(location);
  const showAsTree = queryParams.show === 'tree';

  const handleTabUpdate = () => {
    // Note that this will be the _next_ mode, not the current
    const nextMode = showAsTree ? undefined : 'tree';

    setQueryParams(location, history, { show: nextMode });
  };

  const tabs = [
    {
      key: 'list',
      label: t('listView'),
    },
    {
      key: 'tree',
      label: t('treeView'),
    },
  ];

  const currentTab = tabs.find((tab) => tab.key === queryParams.show) || tabs[0];

  // Update title page as we navigate the sidebar
  document.title = `Innsyn - Feide - ${t('groups')}`;

  return (
    <div className="collapse-wrapper content-box">
      <h1 id="main" className="pagetitle">
        {t('groups')}
      </h1>
      <p>{t('groupText')}</p>

      <Tabs onChange={handleTabUpdate} currentTab={currentTab} tabs={tabs} />

      {!showAsTree ? <ListView /> : <TreeView />}
      {/*
       * Positioned at the bottom of this page deliberately. We want it available,
       * but do not wish to encourage people to use this mechanism unless strictly necessary,
       * because management through dashboard.dataporten.no and ad hoc groups are considered deprecated.
       * The long term encouragement is to use kunde.feide.no for such management
       */}
      <CreateAdhocGroupForm />
    </div>
  );
}

export default GroupPage;
