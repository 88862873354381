import React from 'react';
import LoadingView from '../../../features/ui/LoadingView';
import useAuthInformation from '../../../hooks/auth/useAuthInformation';
import useBasicPersonalInformation from '../../../hooks/useBasicPersonalInformation';

import { getRealmFromPrincipalName } from '../../../util/feideUtil';

import ServiceList from './ServiceList';
import useListSubscribedServices from '../../../hooks/service/useListSubscribedServices';

function AllAvailableServices(): React.JSX.Element {
  const { accessToken } = useAuthInformation();
  const { eduPersonPrincipalName } = useBasicPersonalInformation({ accessToken });

  const realm = getRealmFromPrincipalName(eduPersonPrincipalName);
  const { services, isLoading } = useListSubscribedServices({ realm });

  if (!realm || isLoading) {
    return (
      <div className="spinner--centered">
        <LoadingView />
      </div>
    );
  }

  return <ServiceList services={services} />;
}

export default AllAvailableServices;
