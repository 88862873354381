import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';

function LandingPage(): React.JSX.Element {
  const { t } = useTranslation('landing');

  document.title = 'Innsyn - Feide';

  return (
    <div className="collapse-wrapper content-box">
      <h1 id="main" className="pagetitle">
        {t('title')}
      </h1>
      <span className="block mt-4">{t('description')}</span>
      <ul className="list-disc m-4">
        <li>{t('personalinfo')}</li>
        <li>{t('groupsinfo')}</li>
        <li>{t('applicationinfo')}</li>
      </ul>
      <p className="mb-4">
        <a className="pagelink" href="/login" target="_blank" rel="noopener noreferrer">
          {t('linkName')}
          <i className="fa fa-external-link" />
        </a>
      </p>
      <p className="mb-4">{t('newfeideinfo')}</p>
      <p>
        <a className="pagelink" href="https://idp.feide.no/simplesaml/module.php/feide/" target="_blank" rel="noopener noreferrer">
          {t('oldinnsyn')}
        </a>
        <i className="fa fa-external-link" />
      </p>
    </div>
  );
}

export default LandingPage;
