import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';
import ReadMorePanel from '../../../features/user/ReadMorePanel';

function Description(): React.JSX.Element {
  const { t } = useTranslation('userinfo');

  return <ReadMorePanel intro={t('basicInfo.descriptionLess')}>{t('basicInfo.descriptionMore')}</ReadMorePanel>;
}

export default Description;
