import React from 'react';
import { useTranslation } from 'react-i18next';
import { deDuplicateByKey } from '../../util/objectUtil';
import FilterDropdown, { Selected } from '../../features/group/FilterDropdown';
import { getQualifiedGroupType, getQualifiedMembership } from '../../util/feideUtil';
import { Group } from '../../types/group';

type Props = {
  data: Group[];
  selected: Selected | null;
  onChange: (option: Selected | null) => void;
};

function MembershipFilter({ data, selected, onChange }: Props): React.JSX.Element {
  const { t } = useTranslation('groups');

  const mapGroupMembership = (group: Group): Selected => {
    const qualifiedMembership = getQualifiedMembership(group);
    // Hardcoding the i18next key allows extractors
    // to extract the keys for us.
    const memberships: { [key: string]: string } = {
      admin: t('membership.admin'),
      member: t('membership.member'),
      owner: t('membership.owner'),
      affiliate: t('membership.affiliation.affiliate'),
      alum: t('membership.affiliation.alum'),
      employee: t('membership.affiliation.employee'),
      faculty: t('membership.affiliation.faculty'),
      student: t('membership.affiliation.student'),
      staff: t('membership.affiliation.staff'),
      'affiliation.affiliate': t('membership.affiliation.affiliate'),
      'affiliation.alum': t('membership.affiliation.alum'),
      'affiliation.employee': t('membership.affiliation.employee'),
      'affiliation.faculty': t('membership.affiliation.faculty'),
      'affiliation.student': t('membership.affiliation.student'),
      'affiliation.staff': t('membership.affiliation.staff'),
      'affiliation.member': t('membership.member'),
    };

    return {
      key: qualifiedMembership,
      label: memberships[qualifiedMembership],
      isSelected: false,
      isDefault: false,
    };
  };

  // Each membership type should be unique
  const options: Selected[] = deDuplicateByKey(data.map(mapGroupMembership), (item) => item.key).map((membership) => ({
    ...membership,
    isSelected: selected && selected.key === membership.key,
  }));

  // Default item
  options.unshift({
    key: 'default',
    label: t('filter.all'),
    isDefault: true,
    isSelected: false,
  });

  return <FilterDropdown type="filter.membership" onChange={onChange} options={options} />;
}

function GroupTypeFilter({ data, selected, onChange }: Props): React.JSX.Element {
  const { t } = useTranslation('groups');

  const mapGroupTypes = (group: Group): Selected => {
    const type = getQualifiedGroupType(group);

    // Hardcoding the i18next key allows extractors
    // to extract the keys for us.
    const types: { [key: string]: string } = {
      'ad-hoc': t('type.ad-hoc'),
      emne: t('type.emne'),
      klasse: t('type.klasse'),
      kull: t('type.kull'),
      org: t('type.org'),
      orgunit: t('type.orgunit'),
      prg: t('type.prg'),
      str: t('type.str'),
      'gogroup.a': t('type.gogroup.a'),
      'gogroup.u': t('type.gogroup.u'),
      'gogroup.b': t('type.gogroup.b'),
      'grep.aarstrinn': t('type.grep.aarstrinn'),
    };

    return {
      key: type!,
      label: types[type!],
      isSelected: true,
      isDefault: false,
    };
  };

  const options = deDuplicateByKey(data.map(mapGroupTypes), (item: Selected) => item.key).map((group) => ({
    ...group,
    isSelected: !!selected && selected.key === group.key,
  }));

  options.unshift({
    key: 'default',
    label: t('filter.all'),
    isDefault: true,
    isSelected: false,
  });

  return <FilterDropdown type="filter.type" onChange={onChange} options={options} />;
}

/**
 * Gives the user the option of filter the results,
 * by its membership and by the type of group.
 * */
type GroupListControlProps = {
  onTypeChange: (option: Selected | null) => void;
  selectedType: Selected | null;
  onMembershipChange: (option: Selected | null) => void;
  selectedMembership: Selected | null;
  groups: Group[];
};
function GroupListControl({
  onTypeChange,
  selectedType,
  onMembershipChange,
  selectedMembership,
  groups,
}: GroupListControlProps): React.JSX.Element {
  const handleTypeUpdate = (option: Selected | null) => {
    if (option) {
      onTypeChange(option.isDefault ? null : option);
    } else {
      onTypeChange(null);
    }
  };

  const handleMembershipUpdate = (option: Selected | null) => {
    if (option) {
      onMembershipChange(option.isDefault ? null : option);
    } else {
      onMembershipChange(null);
    }
  };

  return (
    <>
      <div className="filter-container">
        <div className="filter filter-left">
          <GroupTypeFilter onChange={handleTypeUpdate} data={groups} selected={selectedType} />
        </div>
        <div className="filter-space" />
        <div className="filter">
          <MembershipFilter onChange={handleMembershipUpdate} data={groups} selected={selectedMembership} />
        </div>
      </div>
    </>
  );
}

export default GroupListControl;
