import * as Sentry from '@sentry/browser';

const sentryDsn = 'https://2b541eec8f5a40079a50f894158661be@sentry.feide.no/3';

type SentryEnvironments = undefined | 'production' | 'staging' | 'review' | 'development';

const validSentryEnvironments: string[] = ['production', 'staging', 'review', 'development'];

function identifySentryEnvironment(): SentryEnvironments {
  if (!window || !window.location) {
    return undefined;
  }

  const { hostname } = window.location;
  if (hostname === 'innsyn.feide.no') {
    return 'production';
  }

  if (hostname.startsWith('feide-innsyn-staging')) {
    return 'staging';
  }

  if (hostname.startsWith('feide-innsyn-review')) {
    return 'review';
  }

  return 'development';
}

function checkEnvironment(environment: SentryEnvironments): boolean {
  return typeof environment === 'string' && validSentryEnvironments.includes(environment);
}

// eslint-disable-next-line import/prefer-default-export
export function initSentry(): void {
  const isEnvironmentValid = checkEnvironment(identifySentryEnvironment());
  Sentry.init({
    environment: identifySentryEnvironment(),
    // Unknown environments are probably tests, and not interesting
    enabled: isEnvironmentValid,
    dsn: sentryDsn,
  });
}
