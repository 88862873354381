import React from 'react';

import './index.scss';
import { useTranslation } from 'react-i18next';

interface InlineConfirmableButtonProps {
  confirmationTitle: string;
  confirmationMessage: string;
  onConfirm: () => void;
  onAbort: () => void;
  confirmButtonText?: string;
  abortButtonText?: string;
}

function InlineConfirmableButton({
  confirmationTitle,
  confirmationMessage,
  onConfirm,
  onAbort,
  confirmButtonText = undefined,
  abortButtonText = undefined,
}: InlineConfirmableButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  const onSubmit = (event: React.FormEvent) => {
    // Prevent the page from suddenly redirecting/reloading
    event.preventDefault();
    onConfirm();
  };

  return (
    <form className="inlineconfirmablebutton" onSubmit={onSubmit}>
      <em className="inlineconfirmablebutton__title">{confirmationTitle}</em>
      <p className="inlineconfirmablebutton__message">{confirmationMessage}</p>

      <div className="inlineconfirmablebutton__actions">
        <button type="submit" className="btn feide-dangerous">
          {confirmButtonText ?? t('executeAction')}
        </button>
        <button type="reset" className="btn feide-secondary" onClick={onAbort}>
          {abortButtonText ?? t('abortAction')}
        </button>
      </div>
    </form>
  );
}

export default InlineConfirmableButton;
