import React from 'react';

import './index.scss';
/**
 * Spinner that can be used inline other content, to indicate that an action is loading.
 * Animation stolen from kunde.feide.no.
 * */
function InlineLoadingAnimation(): React.JSX.Element {
  return (
    <div className="loading-animation">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default InlineLoadingAnimation;
