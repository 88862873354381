import React, { ImgHTMLAttributes } from 'react';

import defaultLogo from '../../../icons/service.svg';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  url: string | undefined;
  alt: string;
}

function ApplicationLogo({ url, alt, ...rest }: Props): React.JSX.Element {
  if (!url) {
    return <img {...rest} src={defaultLogo} alt={alt} />;
  }

  return <img {...rest} src={url} alt={alt} />;
}
export default ApplicationLogo;
