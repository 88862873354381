import { ReactNode } from 'react';
import MainMenu from './header/MainMenu';
import SideMenu from './header/MainMenu/SideMenu';
import Footer from './footer/Footer';

type Props = {
  children: ReactNode;
};

function Layout({ children }: Props) {
  return (
    <div className="page-container">
      <a href="#main" className="nav-skip font-style-bold" role="button" aria-label="Skip to main content">
        Skip to main content
      </a>
      <MainMenu />
      <main className="content-container">
        <SideMenu />
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
