import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';

function PrivacyInfo(): React.JSX.Element {
  const { t } = useTranslation('privacy');

  // Update page title as we navigate through sidebar
  document.title = `Innsyn - Feide - ${t('translation:navbar.privacy')}`;

  return (
    <div className="collapse-wrapper content-box">
      <h1 id="main" className="pagetitle">
        {t('title')}
      </h1>
      <p>
        <span>{t('description')}</span>
        <a className="pagelink" href="https://www.feide.no/personvern-og-samtykke" target="_blank" rel="noopener noreferrer">
          {t('linkName')}
          <i className="fa fa-external-link" />
        </a>
      </p>
    </div>
  );
}

export default PrivacyInfo;
