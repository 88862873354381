import React, { createContext, useReducer } from 'react';

const localStorageKey = 'userPreference';

/**
 * User preferences are local settings a user may set,
 * such as to show or hide the Feide attribute identifier of each property.
 *
 * These preferences are persisted in localStorage
 * */
interface UserPreferenceContextState {
  /**
   * Determines whether detailed information is shown to the user,
   * such as Feide attributes and userIds
   * */
  isDetailedInformationVisible: boolean;
}

const initialState: UserPreferenceContextState = {
  isDetailedInformationVisible: false,
};

type UserPreferenceActions = { type: 'SAVE_SHOW_DETAILED_INFO'; isDetailedInformationVisible: boolean };

interface UserPreferenceContextProps {
  state: UserPreferenceContextState;
  dispatch: (action: UserPreferenceActions) => void;
}

const persistState = (state: UserPreferenceContextState) => {
  try {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  } catch (error) {
    console.error('Failed to persist user preference state in localStorage', error);
  }
};

// eslint-disable-next-line import/prefer-default-export,@typescript-eslint/naming-convention
export const UserPreferenceContext = createContext({
  state: initialState,
} as UserPreferenceContextProps);
// This fails on loadInitialState if the parameters are swapped.
const reducer = (state: UserPreferenceContextState, action: UserPreferenceActions) => {
  let updatedState = state;

  switch (action.type) {
    case 'SAVE_SHOW_DETAILED_INFO':
      updatedState = {
        ...state,
        isDetailedInformationVisible: action.isDetailedInformationVisible,
      };
      break;
    default:
      // If nothing in this context has changed
      // then we return immediately. No point in persisting to localStorage unnecessary
      return state;
  }

  persistState(updatedState);
  return updatedState;
};

/**
 * Loads the initial state from localStorage or the default state (which will force re-authentication).
 * */
const loadInitialState = (): UserPreferenceContextState => {
  try {
    const rawState = localStorage.getItem(localStorageKey);
    if (!rawState) {
      return initialState;
    }
    return JSON.parse(rawState);
  } catch (error) {
    console.error('Failed to load authentication state from localStorage', error);
    // Use the default initial state
    return initialState;
  }
};

export const UserPreferenceProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, loadInitialState());
  return <UserPreferenceContext.Provider value={{ state, dispatch }}>{children}</UserPreferenceContext.Provider>;
};
