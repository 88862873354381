export interface AuthState {
  nonce: string;
  returnTo: string;
}

type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

function hasStringProperty<K extends string>(o: State, prop: K): o is { [prop in K]: string } {
  const v = o[prop as string];
  if (typeof v !== 'string') {
    return false;
  }
  return true;
}

export function parseAuthState(state: string | undefined): AuthState {
  if (state === undefined) {
    throw new Error('Missing state query parameter');
  }
  if (state.substring(0, 1) !== '{') {
    // A legacy state, from before the conversion.
    return {
      nonce: state,
      returnTo: '/aboutme',
    };
  }
  const decoded: unknown = JSON.parse(state);
  if (decoded === null) {
    throw new Error('state cannot be null');
  }
  if (typeof decoded !== 'object') {
    throw new Error('state must be a JSON object');
  }
  if (!hasStringProperty(decoded, 'nonce')) {
    throw new Error('state.nonce must be a string');
  }
  if (!hasStringProperty(decoded, 'returnTo')) {
    throw new Error('state.returnTo must be a string');
  }
  return decoded;
}
