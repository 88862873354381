import React from 'react';
import { FeideService } from '../../../hooks/service/useListSubscribedServices';
import Service from './Service';

interface ServiceListProps {
  services: FeideService[];
}

function ServiceList({ services }: ServiceListProps): React.JSX.Element {
  return (
    <>
      {services.map((service, idx) => (
        <Service key={`service-${idx}`} service={service} />
      ))}
    </>
  );
}

export default ServiceList;
