import React from 'react';
import classNames from 'classnames';

import './index.scss';

type TabButtonProps = {
  onClick: () => void;
  children: React.ReactNode;
  isActive: boolean;
};

function TabButton({ children, onClick, isActive }: TabButtonProps): React.JSX.Element {
  return (
    <button
      onClick={onClick}
      role="tab"
      aria-selected={isActive}
      className={classNames('navtab-button', { active: isActive })}
      disabled={isActive}
    >
      {children}
    </button>
  );
}

type Tab = {
  key: string;
  label: string;
};

type TabsProps = {
  tabs: Tab[];
  onChange: (tab: Tab, idx: number) => void;
  currentTab: Tab;
};

function Tabs({ tabs, onChange, currentTab }: TabsProps): React.JSX.Element {
  const changeTab = (tab: Tab, idx: number) => () => {
    if (currentTab.key === tab.key) {
      return;
    }

    onChange(tab, idx);
  };

  return (
    <ul className="navtab" role="tablist">
      {tabs.map((tab, idx) => (
        <li key={`tab-${idx}`} className="navtab-item" role="presentation">
          <TabButton onClick={changeTab(tab, idx)} isActive={currentTab.key === tab.key}>
            {tab.label}
          </TabButton>
        </li>
      ))}
    </ul>
  );
}

export default Tabs;
