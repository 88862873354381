import React from 'react';
import { getMostPreciseName } from '../../../util/feideUtil';
import { TreeNode } from './createGroupsTree';

import './index.scss';

function buildTree(data: TreeNode[], elements: React.JSX.Element[]): React.JSX.Element[] {
  for (let i = 0; i < data.length; i += 1) {
    const node = data[i];

    if (!node.group) {
      throw new Error('Node with no group');
    }

    let treeLineClass = 'tree-el-cont';

    if (data.length > 1 && i < data.length - 1) {
      treeLineClass += ' first-tree-line';
    }

    elements.push(
      <div className={treeLineClass} key={`treenode-${node.group.id}`}>
        <div className="tree-line" />
        <div className="tree-element">{getMostPreciseName(node.group)}</div>
      </div>,
    );

    if (node.descendents.length > 0) {
      elements.push(
        <div className="tree-view" key={`treeview-${node.group.id}`}>
          {buildTree(node.descendents, [])}
        </div>,
      );
    }
  }
  return elements;
}
type Props = {
  data: TreeNode[];
};
function TreeList({ data }: Props): React.JSX.Element {
  return (
    <>
      <i className="fas fa-circle root-lines" />
      <div className="tree-container">{buildTree(data, [])}</div>
    </>
  );
}
export default TreeList;
