import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Group } from '../types/group';
import { buildGroupsApiDataportenUri, getData } from '../util/api';

async function getGroupInformation(token: string): Promise<Group[]> {
  return getData(token, buildGroupsApiDataportenUri('groups/me/groups?translate=true')) as Promise<Group[]>;
}

export interface UseGroupInformationResults {
  isLoading: boolean;
  groups: Group[];
  error?: Error;
}

function useGroupInformation({ accessToken }: { accessToken?: string }): UseGroupInformationResults {
  const { i18n } = useTranslation();

  const {
    data: groups,
    isLoading,
    error,
  } = useQuery<Group[], Error>(
    // The groups may contain translated names
    ['groups', i18n.language],
    () => getGroupInformation(accessToken!),
    {
      enabled: !!accessToken,
      onError(err) {
        Sentry.captureException(err);
      },
    },
  );

  return {
    isLoading,
    groups: groups ?? [],
    error: error ?? undefined,
  };
}

export default useGroupInformation;
