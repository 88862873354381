import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './SideMenu.scss';
import * as Routes from '../../../../routes/constants';

function SideMenu({ location }: RouteComponentProps): React.JSX.Element {
  const { t } = useTranslation();

  const path = location.pathname;
  const borderClass = 'menu-border';
  const activeLinkClass = ' active-menu-link';
  let home = borderClass;
  let about = borderClass;
  let applications = borderClass;
  let groups = borderClass;
  let privacy = 'menu-no-border';

  switch (path) {
    case Routes.HOME:
      home += activeLinkClass;
      break;
    case Routes.PERSONALINFO:
      about += activeLinkClass;
      break;
    case Routes.APPINFO:
      applications += activeLinkClass;
      break;
    case Routes.GROUPINFO:
      groups += activeLinkClass;
      break;
    case Routes.PRIVACYINFO:
      privacy += activeLinkClass;
      break;
    default:
      break;
  }

  return (
    <div className="side-menu-container shadowed-normal">
      <nav className="list-group" id="menu">
        <Link to={Routes.HOME} className={`menu-element ${home}`}>
          {t('navbar.home')}
        </Link>
        <Link to={Routes.PERSONALINFO} className={`menu-element ${about}`}>
          {t('navbar.aboutMe')}
        </Link>
        <Link to={Routes.GROUPINFO} className={`menu-element ${groups}`}>
          {t('navbar.groups')}
        </Link>
        <Link to={Routes.APPINFO} className={`menu-element ${applications}`}>
          {t('navbar.applications')}
        </Link>
        <Link to={Routes.PRIVACYINFO} className={`menu-element ${privacy}`}>
          {t('navbar.privacy')}
        </Link>
      </nav>
    </div>
  );
}

export default withRouter(SideMenu);
