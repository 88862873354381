import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import JoinAdhocGroupPage from './JoinAdhocGroupPage';
import Login from './Login';
// eslint-disable-next-line import/no-named-as-default
import Callback from './Callback';
import PrivateRoute from './PrivateRoute';
// eslint-disable-next-line import/no-named-as-default
import PersonalInfo from './PersonalInfo';
import Logout from './Logout';
import LoadingView from '../features/ui/LoadingView';
// eslint-disable-next-line import/no-named-as-default
import GroupPage from './GroupPage';
// eslint-disable-next-line import/no-named-as-default
import LandingPage from './LandingPage';
// eslint-disable-next-line import/no-named-as-default
import PrivacyInfo from './PrivacyInfo';
import * as Routes from './constants';
import ServicesOverviewPage from './ServicesOverviewPage';

function MainRoutes(): React.JSX.Element {
  return (
    <Switch>
      <Route exact path={Routes.CALLBACK} component={Callback} />
      <Route exact path={Routes.LOGIN} component={Login} />
      <Route exact path={Routes.LOGOUT} component={Logout} />
      <Route exact path={Routes.LOADING} component={LoadingView} />
      <Route exact path={Routes.PRIVACYINFO} component={PrivacyInfo} />
      <PrivateRoute exact path={Routes.APPINFO} component={ServicesOverviewPage} />
      <PrivateRoute exact path={Routes.GROUPINFO} component={GroupPage} />
      <PrivateRoute exact path="/adhocgroup/:groupId/invitation/:invitationToken" component={JoinAdhocGroupPage} />
      <PrivateRoute exact path={Routes.PERSONALINFO} component={PersonalInfo} />
      <Route exact path={Routes.HOME} component={LandingPage} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}

export default MainRoutes;
