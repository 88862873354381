import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useRequiredAuthInformation from '../../../../hooks/auth/useRequiredAuthInformation';
import { useMutateAdhocMember, useRemoveAdhocGroupMember } from '../../../../hooks/groups/useMutateAdhocGroupMember';
import { AdhocGroupMember } from '../../../../hooks/groups/useRetrieveAdhocGroupDetails';

import './AdhocGroupMembers.scss';
import InlineConfirmableButton from '../../../ui/InlineConfirmableButton';
import InlineLoadingAnimation from '../../../ui/InlineLoadingAnimation';

interface AdhocGroupMemberSummaryProps {
  readonly member: AdhocGroupMember;
  readonly groupId: string;
  readonly hasCurrentUserEditPermissions: boolean;
}

/**
 * Show a summary of the group member.
 * It also include capabilities to manage group members, such as removing them
 * or upgrading some to admin
 * */
function AdhocGroupMemberSummary({ member, groupId, hasCurrentUserEditPermissions }: AdhocGroupMemberSummaryProps): React.JSX.Element {
  const { t } = useTranslation('groups');

  const [isPendingRemoval, setPendingRemoval] = useState(false);
  const [isPendingAdminUpgrade, setPendingAdminUpgrade] = useState(false);

  const { accessToken } = useRequiredAuthInformation();
  const { removeUserFromGroup, isRemoving } = useRemoveAdhocGroupMember({ accessToken, groupId });
  const { updateMember, isUpdating } = useMutateAdhocMember({ accessToken, groupId });
  const { isCurrentUser } = member;
  /**
   * - You cannot edit your own user in this view
   * - You need to be admin or owner to edit other members
   * - You cannot edit the owner of this group
   * */
  const isEditable = !isCurrentUser && hasCurrentUserEditPermissions && !member.isOwner;

  const memberships = {
    admin: t('membership.admin'),
    member: t('membership.member'),
    owner: t('membership.owner'),
  };

  let membership;
  if (member.isOwner) {
    membership = memberships.owner;
  } else if (member.type === 'admin') {
    membership = memberships.admin;
  } else if (member.type === 'member') {
    membership = memberships.member;
  }

  const onRemoveUser = () => {
    setPendingRemoval(false);
    removeUserFromGroup(member.id);
  };

  // Toggles the member role between 'admin' and 'member' (only two options currently available)
  const changeMemberRole = () => {
    setPendingAdminUpgrade(false);
    const changeToRole = member.type === 'admin' ? 'member' : 'admin';
    updateMember({
      id: member.id,
      type: changeToRole,
    });
  };

  return (
    <div className={classNames('adhocgroupmembersummary', { 'adhocgroupmembersummary--currentuser': isCurrentUser })}>
      <div className="adhocgroupmembersummary__metadata">
        <p className="adhocgroupmembersummary__metadata__name">
          <strong>{member.name}</strong> {isCurrentUser && `(${t('adhocGroupMembers.yourself')})`}
        </p>
        <p className="adhocgroupmembersummary__metadata__role">{membership}</p>
      </div>

      <div className="adhocgroupmembersummary__actions">
        {(isRemoving || isUpdating) && <InlineLoadingAnimation />}
        {isEditable && (
          <button
            className="btn feide-secondary"
            disabled={isPendingAdminUpgrade || isUpdating}
            onClick={() => setPendingAdminUpgrade(true)}
          >
            {t('adhocGroupMembers.convertTo', { type: member.type === 'admin' ? memberships.member : memberships.admin })}
          </button>
        )}
        {isEditable && (
          <button className="btn feide-dangerous" onClick={() => setPendingRemoval(true)} disabled={isPendingRemoval || isRemoving}>
            {t('adhocGroupMembers.removeFromGroup')}
          </button>
        )}
      </div>

      {isPendingRemoval && (
        <InlineConfirmableButton
          confirmationTitle={t('adhocGroupMembers.removeMemberConfirmation', { member: member.name })}
          confirmationMessage={''}
          onConfirm={onRemoveUser}
          onAbort={() => setPendingRemoval(false)}
        />
      )}
      {isPendingAdminUpgrade && (
        <InlineConfirmableButton
          confirmationTitle={
            member.type === 'member'
              ? t('adhocGroupMembers.upgradeToAdmin', { member: member.name })
              : t('adhocGroupMembers.downgradeToMember', { member: member.name })
          }
          confirmationMessage={''}
          onConfirm={changeMemberRole}
          onAbort={() => setPendingAdminUpgrade(false)}
        />
      )}
    </div>
  );
}

interface AdhocGroupMembersProps {
  readonly members: AdhocGroupMember[];
  readonly groupId: string;
  /**
   * Does the user have permission to edit members.
   * This does not include its own user, which has to be edited elsewhere
   * */
  readonly hasCurrentUserEditPermissions: boolean;
}

function AdhocGroupMembers({ members, groupId, hasCurrentUserEditPermissions }: AdhocGroupMembersProps): React.JSX.Element {
  const { t } = useTranslation('groups');
  return (
    <section className="adhocgroupmembers">
      <h3>{t('adhocGroupMembers.title')}</h3>
      <ul>
        {members.map((member) => (
          <li key={`member-${member.id}`}>
            <AdhocGroupMemberSummary member={member} groupId={groupId} hasCurrentUserEditPermissions={hasCurrentUserEditPermissions} />
          </li>
        ))}
      </ul>
    </section>
  );
}
export default AdhocGroupMembers;
