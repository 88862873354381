import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from './routes/MainRoutes';
import Layout from './features/ui/Layout';

// Suspense is used by react-i18next when translations are not ready
function App(): React.JSX.Element {
  return (
    <BrowserRouter>
      <Layout>
        <MainRoutes />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
