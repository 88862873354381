import i18next from 'i18next';
import qs from 'qs';
import { HttpError } from '../customErrors';

export const buildAuthDataportenUri = (entity: string): string => `https://auth.dataporten.no/${entity}`;
export const buildApiDataportenUri = (entity: string): string => `https://api.dataporten.no/${entity}`;
export const buildClientadminDataportenUri = (entity: string): string => `https://clientadmin.dataporten-api.no/${entity}`;
export const buildFeideConsentApiUri = (): string => 'https://feideconsent.dataporten-api.no/api.php';
export const buildGroupsApiDataportenUri = (entity: string): string => `https://groups-api.dataporten.no/${entity}`;
export const buildOrgApiUri = (): string => 'https://api.feide.no/2/org/all?fields=realm,attribute_release_policy';

export function authenticatedFetch(accessToken: string, uri: string, acceptLanguage?: string | undefined): Promise<Response> {
  const headers: HeadersInit = {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  if (acceptLanguage) {
    headers['Accept-Language'] = acceptLanguage;
  }
  return fetch(uri, {
    headers,
  });
}

export function authenticatedDelete(accessToken: string, uri: string, data?: string[] | undefined): Promise<Response> {
  const headers: HeadersInit = {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  if (data) {
    headers['Content-Type'] = 'application/json';
  }
  return fetch(uri, {
    method: 'DELETE',
    headers,
    body: JSON.stringify(data) ?? null,
  });
}

function extractResponseBody(res: Response): Promise<object | string> {
  try {
    return res.json();
  } catch (e) {
    return res.text();
  }
}

function isFeideServiceConsentUrl(url: string): boolean {
  if (!url) {
    return false;
  }

  return url.includes(buildFeideConsentApiUri());
}

/**
 * @param {Response} res
 * */
async function responseHandler(res: Response): Promise<object> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const body: any = await extractResponseBody(res);

  if (res.status < 400) {
    return body;
  }

  const { url } = res;

  // Errors typically related to authentication and authorization.
  // 401s from feide consent api implies some other issue, and should not go here
  if (res.status >= 401 && res.status <= 403 && !isFeideServiceConsentUrl(url)) {
    throw new HttpError(`Could not authenticate user (Error Code ${res.status})`, res.status, body, res);
  }

  // Resource not found
  if (res.status === 404) {
    throw new HttpError(`Could not find resource (Error code ${res.status})`, res.status, body, res);
  }

  // Server errors
  if (res.status >= 500) {
    throw new HttpError(`Something went wrong on the server (Error code ${res.status})`, res.status, body, res);
  }

  // Catch all other unmapped failures and give a generic error
  throw new HttpError(`Unknown error (Error code ${res.status})`, res.status, body, res);
}

/**
 * Dispatches a one-time POST request, using the one-time code.
 * Typically used when Authorization code is exchanged for Access Token
 * */
export async function oneTimePost(code: string, codeVerifier: string, options: object): Promise<object> {
  const body = qs.stringify(
    Object.assign(options, {
      code,
      code_verifier: codeVerifier,
    }),
  );

  const uri = buildAuthDataportenUri('oauth/token');
  const res = await fetch(uri, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return responseHandler(res);
}

// eslint-disable-next-line prefer-promise-reject-errors
const missingTokenRejection = () => Promise.reject({
  error: 'Authorization token is missing',
  code: 401,
});

export async function getData(token: string, uri: string, authenticated: boolean = true): Promise<unknown> {
  const preferredLanguage = i18next.language;

  if (!token) {
    return missingTokenRejection();
  }

  if (!authenticated) {
    const res = await fetch(uri, { headers: { 'Accept-Language': preferredLanguage } });
    return responseHandler(res);
  }
  const res = await authenticatedFetch(token, uri, preferredLanguage);
  return responseHandler(res);
}

export async function deleteData(token: string, uri: string, data?: string[] | undefined): Promise<Response> {
  if (!token) {
    return missingTokenRejection();
  }
  return authenticatedDelete(token, uri, data).then();
}

export async function postRequest(token: string, uri: string, data: object): Promise<object> {
  if (!token) {
    return missingTokenRejection();
  }

  const res = await fetch(uri, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return responseHandler(res);
}

export async function patchRequest(token: string, uri: string, data: object): Promise<object> {
  if (!token) {
    return missingTokenRejection();
  }

  const res = await fetch(uri, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return responseHandler(res);
}

/**
 * Secret issued without any access
 * but provided, so we aren't rate-limited
 * */
const apiFeidePublicSecret = 'EWST0uKZmjXLvSn4';

/**
 * Does a get request to api.feide.no,
 * were the public secret is included, to prevent
 * heavy throttling.
 *
 * @param {string} url
 * */
export async function feideApiGet(url: string): Promise<object> {
  const res = await authenticatedFetch(apiFeidePublicSecret, url);
  return responseHandler(res);
}
