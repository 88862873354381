import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import useRequiredAuthInformation from '../hooks/auth/useRequiredAuthInformation';
import GroupPage from './GroupPage';
import JoinAdhocGroupPage from './JoinAdhocGroupPage';
import PersonalInfo from './PersonalInfo';
import ServicesOverviewPage from './ServicesOverviewPage';

/**
 * Components rendered by this Route guarantees that the user is signed in,
 * and has an up-to-date access token.
 * */
interface PrivateRouteProps extends RouteProps {
  component: typeof ServicesOverviewPage | typeof GroupPage | typeof JoinAdhocGroupPage | typeof PersonalInfo;
}
function PrivateRoute(props: PrivateRouteProps): React.JSX.Element {
  const { component: Component, ...rest } = props;
  useRequiredAuthInformation();

  return <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />;
}

export default PrivateRoute;
