import { generateNonce } from './nonceUtil';
import { oneTimePost } from '../api';
import { getTokenConfig } from './config';
import { sha256 } from '../cryptoUtil';
import { persist, retrieve } from '../persistenceUtil';
import { base64UrlEncode } from '../base64Util';

const CODE_CHALLENGE_SECRET = 'code_verifier';

export const persistCodeChallenge = (challenge: string) => {
  persist(CODE_CHALLENGE_SECRET, challenge);
};

/**
 * Generates a PKCE [0] S256 code challenge
 *
 *   code_challenge = BASE64URL-ENCODE(SHA256(ASCII(code_verifier)))
 *
 * [0] https://tools.ietf.org/html/rfc7636#section-1.1
 *
 * @return {object} { nonce: <plaintext nonce, stored in localstorage>, challenge: <hashed nonce> }
 * */
export async function generateCodeChallenge(): Promise<{ nonce: string; challenge: string }> {
  const nonce = generateNonce();
  const hash = await sha256(nonce);

  const challenge = base64UrlEncode(Buffer.from(hash));

  persistCodeChallenge(nonce);

  return { nonce, challenge };
}

const retrieveCodeChallenge = () => retrieve(CODE_CHALLENGE_SECRET);
const purgeCodeChallenge = () => localStorage.removeItem(CODE_CHALLENGE_SECRET);

type TokenData = {
  access_token: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  expires_in: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  id_token: string;
  scope: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  token_type: string;
};
export async function retrieveAccessToken(code: string): Promise<TokenData> {
  if (!code) {
    throw new Error('Missing one time authorization code');
  }

  const challenge = retrieveCodeChallenge();

  if (!challenge) {
    console.warn('Stored code challenge is missing');
    throw new Error('Missing local code challenge');
  }

  const config = getTokenConfig();

  // // Our one-time only challenge has been used, and should be cleared
  purgeCodeChallenge();

  return oneTimePost(code, challenge, config) as Promise<TokenData>;
}
