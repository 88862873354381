import {
  hasUnspecifiedLanguage, SupportedTranslations, TranslatedProperty, UnspecifiedTranslation,
} from '../types/global';

export function getTranslatedValue(property: TranslatedProperty, locale: SupportedTranslations): string | undefined {
  if (locale in property) {
    return property[locale];
  }

  // Fallback. When the requested locale is Norsk bokmål or nynorsk,
  // but the property only has the deprecated locale "no".
  if (['nb', 'nn'].includes(locale) && 'no' in property) {
    return property.no;
  }

  if ('en' in property) {
    return property.en;
  }

  // If all else fails. Try to use one of the keys contained inside the property
  const availableKeys = Object.keys(property);

  if (availableKeys.length < 1) {
    return undefined;
  }

  return property[availableKeys[0] as keyof TranslatedProperty];
}

/**
 * Compares translated properties for the language the user prefers.
 *
 * Note! if value1 or value2 uses UnspecifiedTranslation, then the other value must use the same
 *
 * @return {number} 1 if str1 is larger than str2, -1 if reversed and 0 otherwise
 * */
export function sortedByTranslatedProperty(
  value1: TranslatedProperty | UnspecifiedTranslation,
  value2: TranslatedProperty | UnspecifiedTranslation,
  preferredLocale: SupportedTranslations,
): number {
  let str1: string | undefined;
  let str2: string | undefined;

  if (hasUnspecifiedLanguage(value1)) {
    str1 = value1.unspecified;
  } else {
    str1 = getTranslatedValue(value1, preferredLocale);
  }

  if (hasUnspecifiedLanguage(value2)) {
    str2 = value2.unspecified;
  } else {
    str2 = getTranslatedValue(value2, preferredLocale);
  }

  if (!str1) {
    return 1;
  }

  if (!str2) {
    return -1;
  }

  const lower1 = str1.toLowerCase();
  const lower2 = str2.toLowerCase();

  if (lower1 > lower2) {
    return 1;
  }

  if (lower1 < lower2) {
    return -1;
  }

  return 0;
}
