import React from 'react';
import useUserPreference from '../../../hooks/useUserPreference';

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode | null;
};

function ConditionalMoreInfo({ children, fallback = null }: Props): React.JSX.Element {
  const { isDetailedInformationVisible } = useUserPreference();
  if (!isDetailedInformationVisible) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
}
export default ConditionalMoreInfo;
