import * as Sentry from '@sentry/browser';
import { useQuery } from 'react-query';
import { buildOrgApiUri, feideApiGet } from '../util/api';

export type OrganizationConsentPolicy = 'consent' | 'info' | undefined;

interface FeideApiOrganization {
  realm: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  attribute_release_policy: OrganizationConsentPolicy;
}

/**
 * Retrieves all organization consents
 * */
export async function listOrgConsentPolicies(): Promise<FeideApiOrganization[]> {
  const uri = buildOrgApiUri();
  const results = (await feideApiGet(uri)) as FeideApiOrganization[];

  return results.map((organization) => ({
    realm: organization.realm,
    attribute_release_policy: organization.attribute_release_policy,
  }));
}

interface UseOrgConsentPolicyResults {
  policy: OrganizationConsentPolicy;
  isLoading: boolean;
  error?: Error;
}

/**
 * Retrieves the Organization consent/authorization
 * policy, whether a user can revoke consents for SAML
 * applications themselves.
 * */
function useOrgConsentPolicy({ realm }: { realm?: string | null }): UseOrgConsentPolicyResults {
  const { data, error, isLoading } = useQuery<FeideApiOrganization[], Error>(
    ['organizations', 'all'],
    // This function is not called unless accessToken is present
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => listOrgConsentPolicies(),
    {
      onError(err) {
        Sentry.captureException(err);
      },
    },
  );

  if (!realm) {
    // Is loading is set to true until realm is set
    return {
      isLoading: true,
      error: error ?? undefined,
      policy: undefined,
    };
  }

  const policy = data ? data.find((organization) => organization.realm === realm) : undefined;

  return {
    isLoading,
    policy: policy?.attribute_release_policy,
    error: error ?? undefined,
  };
}

export default useOrgConsentPolicy;
