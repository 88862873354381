import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18n } from 'i18next';
import GenericApplication from '../../../../features/service/GenericApplication';
import './index.scss';
import ExpandableTextBox from '../../../../features/service/ExpandableTextBox';
import { FeideService } from '../../../../hooks/service/useListSubscribedServices';
import { TranslatedProperty } from '../../../../types/global';

// eslint-disable-next-line @typescript-eslint/no-shadow
function getLang(obj: TranslatedProperty, i18n: i18n): string {
  let lang = i18n.language;
  if (!obj[lang as keyof TranslatedProperty]) {
    // Takes the first element in the Object
    // (there should always be a translation, but not necessarily in the correct language)
    [lang] = Object.keys(obj);
  }
  return lang;
}

// eslint-disable-next-line @typescript-eslint/no-shadow
function selectBestTranslation(translations: TranslatedProperty, i18n: i18n): string | undefined {
  const lang = getLang(translations, i18n);
  return translations[lang as keyof TranslatedProperty];
}

interface ServiceProps {
  service: FeideService;
}

function Service({ service }: ServiceProps): React.JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { t, i18n } = useTranslation('services');

  const {
    name, provider, description, aboutUrl, loginUrl, logoRectangular,
  } = service;

  const logo = logoRectangular && `https://idp.feide.no/simplesaml/module.php/feide/splogos/${logoRectangular}.png`;

  return (
    <GenericApplication
      header={
        <p>
          <strong className="owner">{selectBestTranslation(provider.name, i18n)}</strong>
        </p>
      }
      name={selectBestTranslation(name, i18n)}
      landingPageUrl={aboutUrl}
      logo={logo}
    >
      <ExpandableTextBox label={t('services.description')}>
        <p>{selectBestTranslation(description, i18n)}</p>
      </ExpandableTextBox>

      <ul className="margin-vertical">
        {loginUrl && (
          <li>
            <a href={loginUrl} className="pagelink" rel="noreferrer noopener" target="_blank">
              {t('services.loginUrl')}
            </a>
          </li>
        )}
      </ul>
    </GenericApplication>
  );
}

export default Service;
