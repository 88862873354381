import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';

export function useSentryUser(userId?: string): void {
  useEffect(() => {
    if (!userId) {
      return;
    }
    Sentry.setUser({
      id: userId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
}

export function useSentryTags(tagName: string, value?: string | null): void {
  useEffect(() => {
    if (!value) {
      return;
    }
    Sentry.setTag(tagName, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
}
