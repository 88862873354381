import i18next from 'i18next';
import manifest from '../../languageManifest.json';
import { isEmptyObject } from '../objectUtil';

/**
 * Fallback language is the language to be
 * used in all cases, where the preferred
 * language cannot be used.
 *
 * This can be updated in languageManifest.json
 *
 * */
export const getFallbackLanguage = (): string => manifest.defaultLanguage;

type Lang = {
  enabled: boolean;
  name: string;
};
export type Manifest = {
  en: Lang;
  nb: Lang;
  nn: Lang;
  se: Lang;
};
/**
 * Simple hook into the manifest,
 * which contains definitions on which languages
 * are valid and activated.
 *
 * */
export const getManifest = (): Manifest => manifest;

/**
 * Returns a list of languages which has been activated.
 *
 * Which languages has been activated is determined by metadata.json
 *
 * @return {array} ['nn', 'en', 'nb', '...']
 * */
export function getActiveLanguages(): string[] {
  return Object.keys(manifest).filter((lng) => manifest[lng as keyof Manifest].enabled);
}

export function getCurrentLanguage(): string | null {
  try {
    return i18next.language;
  } catch (e) {
    return null;
  }
}

/**
 * From the datastructure:
 * {
 *  nb: String,
 *  en: String,
 *  ...
 * }
 *
 * determines which translation is best for a user.
 *
 * */
export function selectBestTranslation(options: { [key: string]: string }, preferredLang: string): string {
  if (isEmptyObject(options)) {
    return '';
  }

  if (preferredLang in options) {
    return options[preferredLang];
  }

  if ('en' in options) {
    return options.en;
  }

  return '';
}
