import React from 'react';

import useWindowWidth from '../../../util/hooks/windowWidth';
import GenericTable, { CollapsableTableRow } from '../../ui/GenericTable';
import { TranslatedFeideAttributeLabel } from '../../../util/feideUtil';
import { TranslatableProperty } from '../../group/GroupCard';

function isCollapsed(currentWidth: number, threshold: number): boolean {
  return currentWidth < threshold;
}

function injectTranslatedLabel(attribute: TranslatableProperty): TranslatableProperty {
  Object.assign(attribute, {
    label: <TranslatedFeideAttributeLabel attribute={attribute.key} />,
  });
  return attribute;
}

type FeideAttributeTableProps = {
  attributes: TranslatableProperty[];
};

function FeideAttributeTable({ attributes }: FeideAttributeTableProps): React.JSX.Element {
  const width = useWindowWidth();
  const rows = attributes
    // Feide-attribute names are not readable for most users.
    // Inject a readable label.
    .map(injectTranslatedLabel)
    .map((attribute) => <CollapsableTableRow key={attribute.key} collapsed={isCollapsed(width, 650)} attribute={attribute} />);

  return <GenericTable>{rows}</GenericTable>;
}

export default FeideAttributeTable;
