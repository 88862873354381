import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthorizedService, ServiceType } from '../../../hooks/service/useListMyAuthorizedServices';
import { hasUnspecifiedLanguage, SupportedTranslations } from '../../../types/global';
import { getTranslatedValue } from '../../../util/stringUtil';
import Scopes from './Scopes';
import GenericApplication from '../../../features/service/GenericApplication';
import ConditionalMoreInfo from '../../../features/ui/ConditionalMoreInfo';
import ExpandableTextBox from '../../../features/service/ExpandableTextBox';

function readableServiceType(type: ServiceType): string {
  const map: Record<ServiceType, string> = {
    oauth: 'OAuth',
    saml: 'SAML',
  };

  return map[type];
}

interface ServiceSummaryProps {
  service: AuthorizedService;
  deleteConsent: (service: AuthorizedService | string | undefined) => void;
}

function ServiceSummary({ service, deleteConsent }: ServiceSummaryProps): React.JSX.Element {
  // Use services namespace, so we do not confuse translations
  // with global application translations
  const { t, i18n } = useTranslation('services');

  const language = i18n.language as SupportedTranslations;

  let logo = '';
  if (service.type === 'oauth') {
    logo = `https://clientadmin.dataporten-api.no/clients/${service.id}/logo`;
    // Logo path can be null
  } else if (service.type === 'saml' && !!service.logoUrl) {
    logo = `https://idp.feide.no/simplesaml/module.php/${service.logoUrl}`;
  }

  const name = hasUnspecifiedLanguage(service.name) ? service.name.unspecified : getTranslatedValue(service.name, language);
  const description = hasUnspecifiedLanguage(service.description)
    ? service.description.unspecified
    : getTranslatedValue(service.description, language);

  return (
    <GenericApplication
      name={name}
      logo={logo}
      onConsentDelete={deleteConsent}
      isConsent={service.canRevokeConsent}
      appID={service.id}
      header={
        <>
          <ConditionalMoreInfo>
            <span className="badge type-badge">{readableServiceType(service.type)}</span>
          </ConditionalMoreInfo>
          <i className="owner">{service.hostOrganization ?? service.owner}</i>
          <p>{description}</p>
        </>
      }
    >
      <ExpandableTextBox label={t('applications.sentInfo')}>
        <Scopes scopes={service.scopes} type={service.type} />
      </ExpandableTextBox>
    </GenericApplication>
  );
}

export default ServiceSummary;
