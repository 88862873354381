import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingView from '../../../features/ui/LoadingView';
import useListMyAuthorizedServices, { AuthorizedService, ServiceType } from '../../../hooks/service/useListMyAuthorizedServices';
import useRevokeServiceAuthorization from '../../../hooks/service/useRevokeServiceAuthorization';
import useAuthInformation from '../../../hooks/auth/useAuthInformation';
import useBasicPersonalInformation from '../../../hooks/useBasicPersonalInformation';
import useOrgConsentPolicy, { OrganizationConsentPolicy } from '../../../hooks/useOrgConsentPolicy';
import { SupportedTranslations } from '../../../types/global';
import ServiceSummary from './ServiceSummary';
import { getRealmFromPrincipalName } from '../../../util/feideUtil';
import { sortedByTranslatedProperty } from '../../../util/stringUtil';

interface ServiceListProps {
  services: AuthorizedService[];
  organizationGlobalAuthorizationPolicy: OrganizationConsentPolicy;
  revokeAuthorization: (serviceId: string, type: ServiceType) => void;
}

function ServiceList(props: ServiceListProps): React.JSX.Element {
  const { organizationGlobalAuthorizationPolicy, services, revokeAuthorization } = props;

  const { i18n } = useTranslation();
  const language = i18n.language as SupportedTranslations;

  const onRevokeAuthorization = (service: AuthorizedService) => {
    revokeAuthorization(service.id, service.type);
  };

  const servicesInOrder = services
    .map((service) => {
      if (service.type === 'oauth') {
        // We already know which OAuth services are mandatory or not
        return service;
      }

      return {
        ...service,
        // Check what global organization policy is for SAML services
        // (depends on what organization the user is part of)
        canRevokeConsent: organizationGlobalAuthorizationPolicy === 'consent',
      };
    })
    // Sort the services in an order that is sensible for users (alphabetically)
    .sort((service1, service2) => sortedByTranslatedProperty(service1.name, service2.name, language));

  return (
    <>
      {servicesInOrder.map((service) => (
        <ServiceSummary key={service.id} service={service} deleteConsent={() => onRevokeAuthorization(service)} />
      ))}
    </>
  );
}

/**
 * Retrieves all services (OAuth and SAML) that has been authorized (consented) by the user or automatically by the organization.
 * Some of these services can revoke their authorization
 * */
function MyAuthorizedServices(): React.JSX.Element {
  const { accessToken } = useAuthInformation();
  const { eduPersonPrincipalName, isLoading: isLoadingPersonalInformation } = useBasicPersonalInformation({ accessToken });
  const realm = getRealmFromPrincipalName(eduPersonPrincipalName);
  const policy = useOrgConsentPolicy({ realm });
  const { services, isLoading: isLoadingServices } = useListMyAuthorizedServices({ accessToken });
  const { revokeAuthorization } = useRevokeServiceAuthorization({ accessToken });

  const isLoadingRequiredResources = isLoadingPersonalInformation || isLoadingServices;

  if (isLoadingRequiredResources) {
    // Only required resources should trigger the Loading view.
    // All other can instead trigger a rerender
    return (
      <div className="spinner--centered">
        <LoadingView />
      </div>
    );
  }

  return (
    <ServiceList organizationGlobalAuthorizationPolicy={policy.policy} services={services} revokeAuthorization={revokeAuthorization} />
  );
}

export default MyAuthorizedServices;
