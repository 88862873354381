import classNames from 'classnames';

export const TYPE_TO_ICON: { [key: string]: string } = {
  'ad-hoc': 'fa-users',
  emne: 'fa-chalkboard-teacher',
  klasse: 'fa-chalkboard-teacher',
  kull: 'fa-graduation-cap',
  org: 'fa-building',
  orgunit: 'fa-id-badge',
  prg: 'fa-graduation-cap',
  gogroup: 'fa-chalkboard-teacher',
  str: 'fa-graduation-cap',
};

type GroupSymbolProps = {
  type: string;
};
function GroupSymbol({ type }: GroupSymbolProps) {
  return (
    <span className="groupsymbol" aria-hidden={true}>
      <i className={classNames('fas', type in TYPE_TO_ICON ? TYPE_TO_ICON[type] : TYPE_TO_ICON['ad-hoc'], 'fa-fw')} />
    </span>
  );
}
export default GroupSymbol;
