import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import useAuthInformation from '../hooks/auth/useAuthInformation';
import { isValidNonce } from '../util/auth/nonceUtil';
import { retrieveAccessToken } from '../util/auth/pkceUtil';
import { parseAuthState } from '../util/auth/state';
import { getExpirationTime } from '../util/auth/util';
import { getQueryParams } from '../util/urlUtil';

/**
 * Completes the Authorization process,
 * by validating state and exchanging Authorization Code
 * with Access token.
 * */
function Callback(): React.JSX.Element {
  const location = useLocation();
  const [isAccessTokenRetrieved, setIsAccessTokenRetrieved] = useState(false);
  const [error, setError] = useState<{ error: string } | undefined>(undefined);
  const { saveAccessToken } = useAuthInformation();

  const params = getQueryParams(location);
  const { code, state } = params;
  const { nonce, returnTo } = parseAuthState(state);

  // Trade the PKCE Authorization Code with a Access Token
  useEffect(() => {
    if (!isValidNonce(nonce)) {
      setError({ error: 'Invalid nonce' });
      return;
    }
    if (!code) {
      return;
    }

    retrieveAccessToken(code)
      .then((data) => {
        saveAccessToken(data.access_token, getExpirationTime(data.expires_in));
        setIsAccessTokenRetrieved(true);
      })
      .catch((err) => {
        console.error('Failed to retrieve access token', err);
        setError({ error: err.message });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, nonce]);

  if (isAccessTokenRetrieved) {
    const redirectTarget = {
      pathname: returnTo,
    };
    return <Redirect to={redirectTarget} />;
  }

  if (error) {
    return (
      <section>
        <h2>Could not complete autentication</h2>
        <p>Something went wrong when attempting to authenticate your user. Please try to sign in again</p>
      </section>
    );
  }

  return (
    <section>
      <h2>Completing authentication...</h2>
    </section>
  );
}

export default Callback;
