import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './index.scss';

/**
 * Defines the legal category types.
 * A similar collection in ./index.scss also
 * har to be updated
 * */
export const VALID_CATEGORIES = {
  normal: 'matisse',
  info: 'cornflower',
  success: 'turquoise',
  notice: 'peach',
  danger: 'coral',
};

function mapCategoryToClassName(category: string | undefined): string {
  const prefix = 'feidebutton--type-';

  if (!category || !(category in VALID_CATEGORIES)) {
    return `${prefix}${Object.keys(VALID_CATEGORIES)[0]}`;
  }

  return `${prefix}${category}`;
}

function mapIsFlatToClassName(isFlat: boolean = false): string {
  const prefix = 'feidebutton--';

  if (isFlat) {
    return `${prefix}flat`;
  }

  return `${prefix}filled`;
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  onClick: () => void;
  is?: string;
  className?: string;
  isFlat?: boolean;
}
function FeideButton({
  children, onClick, is, className, isFlat, ...other
}: Props): React.JSX.Element {
  const buttonClassName = classNames(
    'feidebutton',
    mapCategoryToClassName(is),
    // Type must override category
    mapIsFlatToClassName(isFlat),
    className,
  );

  return (
    <button {...other} className={buttonClassName} onClick={onClick}>
      {children}
    </button>
  );
}

export default FeideButton;
