import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import * as Sentry from '@sentry/browser';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isDevelopment } from '../envUtil';
import { getActiveLanguages, getFallbackLanguage } from './util';

// eslint-disable-next-line import/prefer-default-export
export function registerLocaleEngine(): void {
  i18n
    .use(LanguageDetector)
    .use(HttpBackend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      backend: {
        // Should be same as outputPath in .babelrc
        loadPath: '/locales/{{ns}}/{{lng}}.json',
      },
      detection: {
        order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
        caches: ['localStorage'],

        lookupQuerystring: 'lng',
        lookupLocalStorage: 'i18nextLng',
      },
      debug: isDevelopment(),

      /**
       * Fallback language is defined in languageManifest.json
       * */
      fallbackLng: getFallbackLanguage(),
      interpolation: {
        escapeValue: false, // Escaping is already handled by react
      },

      saveMissing: false,
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        Sentry.captureMessage(`Missing translation '${ns}:${key}' (fallback: ${fallbackValue}) for language: ${lng}`, 'warning');
      },
      // react-i18next options
      react: {
        wait: true,
      },

      /**
       * List of valid languages.
       * It should match the list `locales` in `.babelrc`
       *
       * If you want to disable a language, can you comment it out
       * in languageManifest.json.
       * */
      whitelist: getActiveLanguages(),
    });
}
