import * as Sentry from '@sentry/browser';
import { useMutation, useQueryClient } from 'react-query';
import { buildApiDataportenUri, postRequest } from '../../util/api';

export interface BasicAdhocGroupDetails {
  name: string;
  description?: string;
  isPublic: boolean;
}

async function createAdhocGroup(accessToken: string, group: BasicAdhocGroupDetails): Promise<object> {
  return postRequest(accessToken, buildApiDataportenUri('adhocgroups/'), {
    name: group.name,
    descr: group.description,
    public: group.isPublic,
  });
}

interface UseCreateAdhocGroupResults {
  createGroup: (group: BasicAdhocGroupDetails) => void;
  isCreating: boolean;
  hasBeenCreated: boolean;
  error?: Error;
}

function useCreateAdhocGroup({ accessToken }: { accessToken: string }): UseCreateAdhocGroupResults {
  const queryClient = useQueryClient();

  const mutation = useMutation<object, Error, { group: BasicAdhocGroupDetails }>(({ group }) => createAdhocGroup(accessToken, group), {
    onSuccess() {
      queryClient.refetchQueries(['groups'], { exact: false });
    },
    onError(error, group) {
      console.error('Failed to create ad hoc group', error);
      Sentry.withScope((scope) => {
        scope.setExtras({ group });
        Sentry.captureException(error);
      });
    },
  });

  const createGroup = (group: BasicAdhocGroupDetails) => {
    mutation.mutate({ group });
  };

  return {
    createGroup,
    isCreating: mutation.isLoading,
    hasBeenCreated: mutation.isSuccess,
    error: mutation.error ?? undefined,
  };
}

export default useCreateAdhocGroup;
