import React, { useEffect, useState } from 'react';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { getHybridAuthConfig, buildAuthUri } from '../util/auth/config';
import { generateNonce, persistNonce } from '../util/auth/nonceUtil';
import { generateCodeChallenge } from '../util/auth/pkceUtil';
import LoadingView from '../features/ui/LoadingView';
import GenericErrorCard from '../features/ui/GenericErrorCard';
import { PersistenceError } from '../util/customErrors';
import { isDevelopment } from '../util/envUtil';
import { getQueryParams } from '../util/urlUtil';

type ErrorHintsParams = {
  error: Error;
};

/**
 * Attempts to give a human readable error,
 * based on the error we received
 * */
function ErrorHints({ error }: ErrorHintsParams): React.JSX.Element {
  const { t } = useTranslation('login');

  if (error instanceof PersistenceError) {
    return <>{t('error.cannotStoreDataInBrowser')}</>;
  }

  return <>{t('error.unknownIssue')}</>;
}

function Login(): React.JSX.Element {
  const [codeChallenge, setCodeChallenge] = useState<string>();
  const [error, setError] = useState<Error>();
  const location = useLocation();
  const params = getQueryParams(location);
  const returnTo = params.returnTo || '/aboutme';

  useEffect(() => {
    generateCodeChallenge()
      .then((data) => setCodeChallenge(data.challenge))
      .catch((err) => {
        Sentry.captureException(err);
        setError(err);
      });
  }, []);

  if (error) {
    return (
      <GenericErrorCard
        title={<Trans ns="login" i18nKey="error.couldNotStartAuthentication" />}
        error={
          <div>
            <ErrorHints error={error} />

            {isDevelopment() && <pre>{error.stack}</pre>}
          </div>
        }
      />
    );
  }

  if (codeChallenge) {
    const nonce = generateNonce();
    persistNonce(nonce);
    const state = {
      nonce,
      returnTo,
    };

    const options = getHybridAuthConfig(state, codeChallenge);
    const uri = buildAuthUri(options);

    window.location.replace(uri);
  }

  return (
    <div className="loading-wrapper">
      <LoadingView />
    </div>
  );
}

// Use with translation to ensure 'login' namespace is loaded.
// <Trans ns="login" ...> fails to load namespace first
export default withTranslation('login')(Login);
