import { Location, History } from 'history';
import qs from 'qs';

/**
 * QueryParameters is a JavaScript object with string keys and string values.
 * Accessing missing query parameters returns undefined.
 */
type QueryParameters = { [name: string]: string | undefined };

export function getQueryParams(location: Location): QueryParameters {
  const { search = '' } = location;

  return (qs.parse(search.replace('?', '')) as QueryParameters) || {};
}

export function setQueryParams(location: Location, history: History, params: QueryParameters): void {
  const { pathname } = location;

  history.push({
    pathname,
    search: `?${qs.stringify(params)}`,
  });
}
