import React from 'react';

function LoadingView(): React.JSX.Element {
  return (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default LoadingView;
