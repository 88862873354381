import React, { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './index.scss';

type ExpandButtonProps = {
  isExpanded: boolean;
  onClick: () => void;
};

function ExpandButton({ isExpanded, onClick }: ExpandButtonProps): React.JSX.Element {
  const { t } = useTranslation();

  let label = t('readMorePanel.readMore');

  if (isExpanded) {
    label = t('readMorePanel.readLess');
  }

  return (
    <button className="readmorepanel-button" onClick={onClick}>
      {label}
    </button>
  );
}

type ReadMorePanelProps = {
  intro: string;
  children: React.ReactNode;
};
/**
 * Inspired by: https://design.nav.no/components/lesmerpanel/
 *
 * General purpose expandable (i.e. 'Read more') panel,
 * which can be used to invite users to read more about a particular
 * subject.
 * */
function ReadMorePanel({ intro, children }: ReadMorePanelProps): React.JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const className = classNames('readmorepanel', {
    'is-expanded': isExpanded,
    'is-minified': !isExpanded,
  });

  return (
    <div className={className}>
      <div className="readmorepanel-intro">{intro}</div>
      {isExpanded && <div className="readmorepanel-content">{children}</div>}
      <ExpandButton isExpanded={isExpanded} onClick={toggleExpansion} />
    </div>
  );
}

export default ReadMorePanel;
