import manifest from '../../languageManifest.json';

import { getFallbackLanguage, Manifest } from './util';

function handleUserSelectedLanguage(lang: string): string | null {
  // The selected language has a localization entry
  if (lang in manifest) {
    // The localization entry is enabled
    if (manifest[lang as keyof Manifest].enabled === true) {
      return lang;
    }
    return null;
  }
  return null;
}

function handleBrowserLanguage(): string | null {
  const browserLanguage = navigator.language.toLowerCase().substring(0, 2);

  if (browserLanguage in manifest) {
    if (manifest[browserLanguage as keyof Manifest].enabled === true) {
      return browserLanguage;
    }
  }
  return null;
}

/**
 * Determines the selected language,
 * based on user preference and heuristics in browser
 * */
// eslint-disable-next-line import/prefer-default-export
export function selectLanguage(preferredLang: string): string {
  // Explicitly selected language has precedence
  const userLang = handleUserSelectedLanguage(preferredLang);
  if (userLang !== null) {
    return userLang;
  }
  // If nothing has been selected. Attempt to get browserLanguage
  const browserLang = handleBrowserLanguage();
  if (browserLang !== null) {
    return browserLang;
  }
  // If browserLanguage is not in metadata then select defaultLanguage
  return getFallbackLanguage();
}
