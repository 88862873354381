import { useTranslation } from 'react-i18next';
import useAuthInformation from '../../hooks/auth/useAuthInformation';
import useBasicPersonalInformation from '../../hooks/useBasicPersonalInformation';
import useDetailedPersonalInformation from '../../hooks/useDetailedPersonalInformation';
import useGroupInformation from '../../hooks/useGroupInformation';
import useGetIsFeideUser from '../../hooks/useGetIsFeideUser';

import LoadingView from '../../features/ui/LoadingView';
import GenericErrorCard from '../../features/ui/GenericErrorCard';
import useUserPreference from '../../hooks/useUserPreference';

import BasicInfo from '../../features/user/BasicInfo';
import Description from './Description';
import DropdownList from '../../features/user/DropdownList';

// eslint-disable-next-line import/no-named-as-default
import OrgContactInfo from './OrgContactInfo';

function PersonalInfo(): React.JSX.Element {
  const { t } = useTranslation('userinfo');
  const { accessToken } = useAuthInformation();
  const basicInformation = useBasicPersonalInformation({ accessToken });
  const groupInformation = useGroupInformation({ accessToken });
  const { isDetailedInformationVisible } = useUserPreference();
  const isFeideUser = useGetIsFeideUser({ basicInformation, accessToken });
  const detailedInformation = useDetailedPersonalInformation({ accessToken, isFeideUser, basicInformation });

  if (basicInformation.error) {
    return <GenericErrorCard title={t('basicInfo.somethingWentWrong')} error={<code>{basicInformation.error.message}</code>} />;
  }

  if (groupInformation.error) {
    return <GenericErrorCard error={groupInformation.error.message} />;
  }

  // Social logins dont have eduPersonPrincipalName.
  const { eduPersonPrincipalName } = basicInformation;

  // Should show loading also if the data is missing
  // and no errors has been triggered yet
  if (detailedInformation.isLoading || basicInformation.isLoading || groupInformation.isLoading || isFeideUser === undefined) {
    return <LoadingView />;
  }

  // Update page title as we navigate through sidebar
  document.title = `Innsyn - Feide - ${t('translation:navbar.aboutMe')}`;

  return (
    <div className="collapse-wrapper content-box">
      <BasicInfo
        eduPersonPrincipalName={eduPersonPrincipalName}
        basicInfo={basicInformation.personalInformation}
        detailedInfo={detailedInformation.personalInformation}
        groupInfo={groupInformation}
      />
      <Description />
      <DropdownList
        detailedInfo={detailedInformation}
        userId={basicInformation.personalInformation?.user?.userId}
        groupInfo={groupInformation}
        isDetailedInformationVisible={isDetailedInformationVisible}
        isFeideUser={isFeideUser}
      />
      {isFeideUser && eduPersonPrincipalName ? <OrgContactInfo principalName={eduPersonPrincipalName} /> : <></>}
    </div>
  );
}

export default PersonalInfo;
