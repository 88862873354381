import React from 'react';
import { useTranslation } from 'react-i18next';
import { TranslatableProperty } from '../features/group/GroupCard';
import { FeideAttributes } from '../hooks/useDetailedPersonalInformation';
import { Group } from '../types/group';
import { lastItem } from './arrayUtil';

// Guest share these attributes
export const FEIDE_ATTRIBUTES = {
  aboutMe: [
    'cn',
    'displayName',
    'norEduPersonLegalName',
    'givenName',
    'sn',
    'eduPersonPrincipalName',
    'uid',
    'norEduPersonNIN',
    'mail',
    'eduPersonAffiliation',
    'eduPersonPrimaryAffiliation',
    'eduPersonScopedAffiliation',
    'eduPersonEntitlement',
    'eduPersonOrcid',
    'mobile',
    'preferredLanguage',
    'norEduPersonBirthDate',
    'norEduPersonLIN',
    'eduPersonNickname',
    'eduPersonPrincipalNamePrior',
    'facsimileTelephoneNumber',
    'homePhone',
    'homePostalAddress',
    'manager',
    'title',
    'userid',
  ],
  aboutOrg: [
    'eduOrgLegalName',
    'schacHomeOrganization',
    'ou',
    'eduPersonOrgDN',
    'eduPersonOrgUnitDN',
    'eduPersonPrimaryOrgUnitDN',
    'eduPersonAssurance',
    'labeledURI',
    'postOfficeBox',
    'postalAddress',
    'street',
    'postalCode',
    'l',
    'telephoneNumber',
  ],
};

export const GUEST_ATTRIBUTES = ['mail', 'displayName', 'userid', 'norEduPersonNIN'];

export const FEIDE_SENSITIVE_ATTRIBUTES = ['norEduPersonNIN'];

const FEIDE_ADDRESS_ATTRIBUTE = ['homePostalAddress', 'postalAddress'];

// eslint-disable-next-line import/prefer-default-export
export function getRealmFromPrincipalName(principalName?: string): string | null {
  if (!principalName) {
    return null;
  }

  const segments = principalName.split('@');

  // Should be ['prefix', 'domain']
  if (segments.length < 2) {
    return null;
  }

  return segments[1].toLowerCase();
}

export function getUsernameFromPrincipalName(principalName: string): string | null {
  if (!principalName) {
    return null;
  }

  return principalName.split('@')[0];
}

/**
 * Retrieves the part of a group type
 * which is the most precise
 *
 * Example
 *  - fc:fs:emne => emne
 *  - voot:ad-hoc => ad-hoc
 *
 * */
export function getQualifiedGroupType(group: Group): string | null {
  const { type } = group;

  const segments = type.split(':');
  const primaryType = lastItem(segments);

  // Some groups have nested properties which semantically distinguish them,
  // which is why the sub-type has to be included.
  if (primaryType === 'gogroup') {
    return `gogroup.${group.go_type}`;
  }

  if (primaryType === 'grep') {
    return `grep.${group.grep_type}`;
  }

  return primaryType;
}

/**
 * Extracts the most precise group affiliation.
 *
 * Gogroups can have have membership.basic set to "member", but also
 * have membership.affiliation set to "Student" (elev). In that case should Student
 * have the highest precedence.
 * */
export function getQualifiedMembership(group: Group): string {
  const { membership } = group;

  if (membership.affiliation) {
    const key = membership.primaryAffiliation ?? membership.affiliation;
    return `affiliation.${key}`;
  }

  if (membership.fsroles && membership.fsroles.length > 0) {
    const key = membership.fsroles[0];
    // A typical fsrole is STUDENT, but our convention is to use all-lowercase
    // We also treat affiliation and fsroles as the same. Because higher education
    // might use affiliation on the organization's group, but use fsroles on courses
    return `affiliation.${key.toLowerCase()}`;
  }

  return membership.basic;
}

export function getMostPreciseName(group: Group): string {
  if (group.grep?.displayName) {
    return group.grep?.displayName;
  }
  if (group.displayName) {
    return group.displayName;
  }
  return group.name;
}

export const isSensitiveAttribute = (attribute: string) => FEIDE_SENSITIVE_ATTRIBUTES.includes(attribute);
export const isAddressAttribute = (attribute: string) => FEIDE_ADDRESS_ATTRIBUTE.includes(attribute);

/**
 * Provides a translated feide-attribute label
 * */
export function TranslatedFeideAttributeLabel({ attribute }: { attribute: string }): React.JSX.Element {
  const { t } = useTranslation('attributes');

  /**
   * Map of an attribute, and it's translated value.
   * We accept duplications between this and FEIDE_ATTRIBUTES,
   * because translation extractor i18next-extract can on compilation identify
   * and extract translation keys, instead of us manually adding each entry.
   * For this to work best, should the attribute-map exist
   * inside a Component which has access to t().
   * */
  const translatedAttributes: { [key: string]: string } = {
    cn: t('attribute.cn'),
    displayName: t('attribute.displayName'),
    norEduPersonLegalName: t('attribute.norEduPersonLegalName'),
    givenName: t('attribute.givenName'),
    sn: t('attribute.sn'),
    eduPersonPrincipalName: t('attribute.eduPersonPrincipalName'),
    uid: t('attribute.uid'),
    norEduPersonNIN: t('attribute.norEduPersonNIN'),
    mail: t('attribute.mail'),
    eduPersonAffiliation: t('attribute.eduPersonAffiliation'),
    eduPersonPrimaryAffiliation: t('attribute.eduPersonPrimaryAffiliation'),
    eduPersonScopedAffiliation: t('attribute.eduPersonScopedAffiliation'),
    eduPersonEntitlement: t('attribute.eduPersonEntitlement'),
    eduPersonOrcid: t('attribute.eduPersonOrcid'),
    mobile: t('attribute.mobile'),
    preferredLanguage: t('attribute.preferredLanguage'),
    norEduPersonBirthDate: t('attribute.norEduPersonBirthDate'),
    norEduPersonLIN: t('attribute.norEduPersonLIN'),
    eduPersonNickname: t('attribute.eduPersonNickname'),
    eduPersonPrincipalNamePrior: t('attribute.eduPersonPrincipalNamePrior'),
    facsimileTelephoneNumber: t('attribute.facsimileTelephoneNumber'),
    homePhone: t('attribute.homePhone'),
    homePostalAddress: t('attribute.homePostalAddress'),
    manager: t('attribute.manager'),
    userid: t('attribute.userid'),
    eduOrgLegalName: t('attribute.eduOrgLegalName'),
    schacHomeOrganization: t('attribute.schacHomeOrganization'),
    ou: t('attribute.ou'),
    eduPersonOrgDN: t('attribute.eduPersonOrgDN'),
    eduPersonOrgUnitDN: t('attribute.eduPersonOrgUnitDN'),
    eduPersonPrimaryOrgUnitDN: t('attribute.eduPersonPrimaryOrgUnitDN'),
    eduPersonAssurance: t('attribute.eduPersonAssurance'),
    labeledURI: t('attribute.labeledURI'),
    postOfficeBox: t('attribute.postOfficeBox'),
    postalAddress: t('attribute.postalAddress'),
    street: t('attribute.street'),
    postalCode: t('attribute.postalCode'),
    l: t('attribute.l'),
    telephoneNumber: t('attribute.telephoneNumber'),
  };

  if (!(attribute in translatedAttributes)) {
    return <>{t('unknown', { attribute })}</>;
  }

  return <>{translatedAttributes[attribute]}</>;
}

/**
 * Builds a list of attributes including relevant metadata. Gives a standard format,
 * which can be easily consumed by other components.
 *
 * */
export function buildAttributeList(values: FeideAttributes, attributeList: string[], showMoreInfo: boolean): TranslatableProperty[] {
  return (
    attributeList
      .map((attributeName) => {
        const attributeValue = values[attributeName];

        // Non critically add all values,
        // Filtering can be done later
        return {
          key: attributeName,
          value: attributeValue || [],
          label: null,
        };
      })
      // If more info is selected, then we want to show everything
      .filter((attribute) => attribute.value.length > 0 || showMoreInfo)
  );
}
