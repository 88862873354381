import React from 'react';
import useAuthInformation from '../../hooks/auth/useAuthInformation';
import useGroupInformation from '../../hooks/useGroupInformation';
import LoadingView from '../../features/ui/LoadingView';
import GenericErrorCard from '../../features/ui/GenericErrorCard';
import createGroupsTree from '../../features/group/TreeList/createGroupsTree';
import TreeList from '../../features/group/TreeList';

/**
 * Presents the group as in a tree structure
 * */
function TreeView(): React.JSX.Element {
  const { accessToken } = useAuthInformation();
  const { groups, error, isLoading } = useGroupInformation({ accessToken });

  if (isLoading) {
    return (
      <div className="spinner--centered">
        <LoadingView />
      </div>
    );
  }

  if (error) {
    return <GenericErrorCard error={error.message} />;
  }

  const tree = createGroupsTree(groups);

  return <TreeList data={tree} />;
}

export default TreeView;
