import React, { useState } from 'react';
import useAuthInformation from '../../hooks/auth/useAuthInformation';
import useGroupInformation from '../../hooks/useGroupInformation';

import { lastItem } from '../../util/arrayUtil';
import LoadingView from '../../features/ui/LoadingView';
import GenericErrorCard from '../../features/ui/GenericErrorCard';
import { getQualifiedGroupType, getQualifiedMembership } from '../../util/feideUtil';
import GroupListControl from './GroupListControl';
import GroupList from './GroupList';
import { Group } from '../../types/group';
import { Selected } from '../../features/group/FilterDropdown';

/**
 * Ordered list of how each group
 * should be sorted relative to each other.
 *
 * When sorting use getGroupTypeOrdering
 * to retrieve a better data-structure for searching.
 * */
export const PRIORITIZED_GROUP_TYPES = ['ad-hoc', 'org', 'orgunit', 'prg', 'str', 'kull', 'klasse', 'emne'];

/**
 * Transforms PRIORITIZED_GROUP_TYPES to a searchable map.
 * */
function getGroupTypeOrdering(): Map<string, number> {
  // Assign an index for each number
  const ordered: Iterable<readonly [string, number]> = PRIORITIZED_GROUP_TYPES.map((type, idx) => [type, idx]);

  return new Map<string, number>(ordered);
}

function compareNumber(a: number, b: number): number {
  if (a === b) {
    return 0;
  }

  if (a > b) {
    return 1;
  }

  return -1;
}

function sortByType(data: Group[]): Group[] {
  const types = getGroupTypeOrdering();

  data.sort((a, b) => {
    /* eslint-disable no-param-reassign */
    let aPos: number | undefined;
    let bPos: number | undefined;

    const aLast = lastItem(a.type.split(':'));
    if (aLast) {
      aPos = types.get(aLast);
    }
    const bLast = lastItem(b.type.split(':'));
    if (bLast) {
      bPos = types.get(bLast);
    }

    return compareNumber(aPos ?? 8, bPos ?? 8);
  });

  return data;
}

/**
 * List view is responsible for presenting the groups as a list
 * */
function ListView(): React.JSX.Element {
  const { accessToken } = useAuthInformation();
  const { groups, error, isLoading } = useGroupInformation({ accessToken });

  const [selectedType, setFilterTypes] = useState<Selected | null>(null);
  const [selectedMembership, setFilterMemberships] = useState<Selected | null>(null);

  if (isLoading) {
    return (
      <div className="spinner--centered">
        <LoadingView />
      </div>
    );
  }

  if (error) {
    return <GenericErrorCard error={error.message} />;
  }

  const sortedByPriority = sortByType(groups);

  const groupData = sortedByPriority.filter((element) => {
    const type = getQualifiedGroupType(element);
    const membership = getQualifiedMembership(element);

    const isMatchingType = !selectedType || selectedType.key === type;
    const isMatchingMembership = !selectedMembership || selectedMembership.key === membership;

    return isMatchingType && isMatchingMembership;
  });

  return (
    <>
      <GroupListControl
        onMembershipChange={setFilterMemberships}
        onTypeChange={setFilterTypes}
        selectedType={selectedType}
        selectedMembership={selectedMembership}
        groups={sortedByPriority}
      />
      <GroupList groups={groupData} />
    </>
  );
}

export default ListView;
