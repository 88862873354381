import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmableButton from './ConfirmableButton';
import FeideButton from '../../ui/FeideButton';

import './index.scss';
import ApplicationLogo from './ApplicationLogo';
import { AuthorizedService } from '../../../hooks/service/useListMyAuthorizedServices';

type Props = {
  children: React.ReactNode;
  name?: string;
  logo: string | undefined;
  onConsentDelete?: (service: AuthorizedService | string | undefined) => void;
  isConsent?: boolean;
  appID?: string;
  header: React.JSX.Element;
  landingPageUrl?: string | null;
};
function GenericApplication({
  children, name, logo, onConsentDelete, isConsent, appID, header, landingPageUrl,
}: Props): React.JSX.Element {
  const { t } = useTranslation('services');

  return (
    <li className="list-group-item">
      <section className="name-scopes-container text-left-margin">
        <div className="app-logo-title">
          <ApplicationLogo url={logo} alt={`logo for ${name}`} className="logo" />
        </div>
        <div className="app-description">
          <h3 className="app-header">
            {landingPageUrl ? (
              <a href={landingPageUrl} rel="noreferrer noopener" target="_blank">
                {name}
              </a>
            ) : (
              name
            )}
          </h3>
          {header}
          {children}
        </div>
      </section>
      {onConsentDelete && (
        <div className="owner-auth-container">
          {!isConsent ? (
            <FeideButton onClick={() => null} disabled>
              {t('withdraw.disabled')}
            </FeideButton>
          ) : (
            <ConfirmableButton
              onConfirm={() => onConsentDelete(appID)}
              modalActionLabels={{
                confirm: t('withdraw.yes'),
                dismiss: t('withdraw.no'),
              }}
              modalHeader={t('withdraw.title')}
              modalBody={<p>{t('withdraw.body')}</p>}
            >
              {t('withdraw.title')}
            </ConfirmableButton>
          )}
        </div>
      )}
    </li>
  );
}
export default GenericApplication;
