import React from 'react';

import img from './feide.svg';

type Props = {
  className?: string;
};
function FeideLogo(props: Props): React.JSX.Element {
  return <img {...props} src={img} alt="Feide" />;
}
export default FeideLogo;
