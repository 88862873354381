import { useContext } from 'react';
import { UserPreferenceContext } from '../contexts/userPreferenceContext';

interface UserPreference {
  isDetailedInformationVisible: boolean;
  toggleShowDetailedInformation: (isVisible: boolean) => void;
}

/**
 * This hook manages different local user preferences for this view,
 * such as the visibility of detailed information
 * */
function useUserPreference(): UserPreference {
  const { state, dispatch } = useContext(UserPreferenceContext);

  const toggleShowDetailedInformation = (isVisible: boolean) => {
    dispatch({
      type: 'SAVE_SHOW_DETAILED_INFO',
      isDetailedInformationVisible: isVisible,
    });
  };

  return {
    isDetailedInformationVisible: state.isDetailedInformationVisible,
    toggleShowDetailedInformation,
  };
}

export default useUserPreference;
