import * as Sentry from '@sentry/browser';
import { useQuery } from 'react-query';
import { TranslatedProperty } from '../types/global';
import { feideApiGet } from '../util/api';

export interface OrganizationContactInformation {
  id: string;
  realm: string;
  supportEmail?: string;
  supportUrl?: TranslatedProperty;
  supportPhone?: string;
}

async function retrieveOrganizationContactInformation(realm: string): Promise<OrganizationContactInformation | undefined> {
  type OrganizationContactInformationResponse = {
    id: string;
    realm: string;
    support_email?: string;
    support_url?: TranslatedProperty;
    support_phone?: string;
  };

  const allOrgs = (await feideApiGet(
    'https://api.feide.no/2/org/all?fields=realm,id,support_email,support_phone,support_url',
  )) as OrganizationContactInformationResponse[];

  const myOrg = allOrgs.find((org) => org.realm === realm);
  if (!myOrg) {
    return undefined;
  }

  return {
    id: myOrg.id,
    realm: myOrg.realm,
    supportEmail: myOrg.support_email,
    supportUrl: myOrg.support_url,
    supportPhone: myOrg.support_phone,
  };
}

interface UseRetrieveContactInfoResults {
  contactInformation?: OrganizationContactInformation;
  isLoading: boolean;
  error?: Error;
}

function useRetrieveContactInformation({ realm }: { realm?: string | null }): UseRetrieveContactInfoResults {
  const {
    data: contactInformation,
    isLoading,
    error,
  } = useQuery<OrganizationContactInformation | undefined, Error>(
    // The groups may contain translated names
    ['contactinformation', realm],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => retrieveOrganizationContactInformation(realm!),
    {
      enabled: !!realm,
      onError(err) {
        Sentry.captureException(err);
      },
    },
  );

  return {
    contactInformation,
    isLoading,
    error: error ?? undefined,
  };
}

export default useRetrieveContactInformation;
