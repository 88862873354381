import * as Sentry from '@sentry/browser';
import { useQuery } from 'react-query';
import { TranslatedProperty } from '../../types/global';
import { feideApiGet } from '../../util/api';

interface FeideServiceProvider {
  readonly id: string;
  readonly name: TranslatedProperty;
  readonly realm?: string;
}

export interface FeideService {
  readonly name: TranslatedProperty;
  readonly description: TranslatedProperty;
  readonly provider: FeideServiceProvider;
  readonly aboutUrl?: string;
  readonly loginUrl?: string;
  readonly logoRectangular?: string;
  readonly logoSquare?: string;
}

// eslint-disable-next-line max-len
export const buildServiceApiUri = (realm: string) => `https://api.feide.no/2/sp/filter?subscriber=${realm}&fields=provider,name,description,about_url,login_url,logo_square,logo_rectangular`;

async function listAllSubscribedServices(realm: string): Promise<FeideService[]> {
  type FeideServiceResponse = {
    provider: FeideServiceProvider;
    description: TranslatedProperty;
    name: TranslatedProperty;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    about_url?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    login_url?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    logo_rectangular?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    logo_square?: string;
  };

  const payload = (await feideApiGet(buildServiceApiUri(realm))) as FeideServiceResponse[];

  return payload.map(
    (serviceRaw): FeideService => ({
      provider: serviceRaw.provider,
      description: serviceRaw.description,
      name: serviceRaw.name,
      aboutUrl: serviceRaw.about_url,
      loginUrl: serviceRaw.login_url,
      logoRectangular: serviceRaw.logo_rectangular,
      logoSquare: serviceRaw.logo_square,
    }),
  );
}

interface UseListSubscribedServicesResults {
  services: FeideService[];
  isLoading: boolean;
  error?: Error;
}

function useListSubscribedServices({ realm }: { realm?: string | null }): UseListSubscribedServicesResults {
  const { data, error, isLoading } = useQuery<FeideService[], Error>(
    ['services', 'all', realm],
    // This function is not called unless accessToken is present
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => listAllSubscribedServices(realm!),
    {
      enabled: !!realm,
      onError(err) {
        Sentry.captureException(err);
      },
    },
  );

  return {
    isLoading,
    services: data ?? [],
    error: error ?? undefined,
  };
}

export default useListSubscribedServices;
