// Hack to get package base64url to work.
// It requires Buffer which is a nodeJS module, and is expected to exist globally.
// Future solution should ideally not be dependent on base64url package or buffer.
//
// However, this is unfortunately not entirely trivial, since we have to base64URL encode an ArrayBuffer.
// Possible alternative is to move authentication logic to a server
import { Buffer } from 'buffer';
import base64url from 'base64url';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: base64url expects buffer to exist to we are adding it (see comment above)
window.Buffer = Buffer;

/**
 * Encodes the parameter into base64URL encoded string,
 * required for PKCE auth flow.
 * @param payload The buffer to encode, typically SHA256 hashed value (ArrayBuffer)
 * @return Base64URL encoded value in string form
 * */
// eslint-disable-next-line import/prefer-default-export
export const base64UrlEncode = (payload: Buffer): string => base64url.encode(payload);
