import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InlineConfirmableButton from '../../../ui/InlineConfirmableButton';

function DeleteGroupSection({ onDelete, isDeleting }: { onDelete: () => void; isDeleting: boolean }): React.JSX.Element {
  const { t } = useTranslation('groups');

  const [isPendingGroupDelete, setPendingGroupDelete] = useState(false);

  const onGroupDelete = () => {
    setPendingGroupDelete(false);
    onDelete();
  };

  return (
    <section className="deleteadhocgroupsection margin-top">
      <h4>{t('deleteGroup.deleteGroup')}</h4>
      <button className="btn feide-dangerous" onClick={() => setPendingGroupDelete(true)} disabled={isPendingGroupDelete || isDeleting}>
        {t('deleteGroup.deleteGroup')}
      </button>

      {isPendingGroupDelete && (
        <InlineConfirmableButton
          confirmationTitle={t('deleteGroup.deleteConfirmation')}
          confirmationMessage={t('deleteGroup.deleteConfirmationDescription')}
          onConfirm={onGroupDelete}
          onAbort={() => setPendingGroupDelete(false)}
        />
      )}
    </section>
  );
}
export default DeleteGroupSection;
