import { useQuery } from 'react-query';
import * as Sentry from '@sentry/browser';
import { buildApiDataportenUri, getData } from '../util/api';
import { UseBasicPersonalInformationResults } from './useBasicPersonalInformation';

/**
 * Collection of attributes we expected returned from api.dataporten.no/userinfo/v1/insight,
 * that should match attributes defined in https://docs.feide.no/reference/schema/info_go/go_attributter_ch01.html?highlight=attributes.
 *
 * Note the list is non-exhaustive. Add missing attributes as you need them.
 *
 * Note: Guest, twitter, facebook and eduGAIN users can not access v1/insight, since there are no info about them in LDAPAPI
 * so this should only be invoked if it is a feide user. Otherwise this should return finished loading and data generated by the
 * basicInformation object.
 *
 * Please order the attributes in alphabetical order,
 * for easier navigation.
 * */
export interface FeideAttributes {
  [key: string]: string[] | undefined;
}

async function getDetailedUserinfo(token: string): Promise<FeideAttributes> {
  return getData(token, buildApiDataportenUri('userinfo/v1/insight')) as Promise<FeideAttributes>;
}

// Guest user incl social logins and idporten logins
function getGuestUserDetailedUserInfo(
  basicPersonalInformation: UseBasicPersonalInformationResults,
  feideId: string | undefined,
): FeideAttributes {
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    displayName: [basicPersonalInformation!.personalInformation!.user.name],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mail: [basicPersonalInformation!.personalInformation!.user.email],
    norEduPersonNIN: feideId ? [feideId] : [],
  };
}

export interface UseDetailedPersonalInformationResults {
  isLoading: boolean;
  personalInformation?: FeideAttributes;
  error?: Error;
}

function useDetailedPersonalInformation({
  accessToken,
  isFeideUser,
  basicInformation,
}: {
  accessToken?: string;
  isFeideUser?: boolean;
  basicInformation: UseBasicPersonalInformationResults;
}): UseDetailedPersonalInformationResults {
  let {
    data: personalInformation,
    // eslint-disable-next-line prefer-const
    error,
    // eslint-disable-next-line prefer-const
    isLoading,
  } = useQuery<FeideAttributes, Error>(
    ['userinfo', 'detailed'],
    // This function is not called unless accessToken is present and isFeideUser is true
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getDetailedUserinfo(accessToken!),
    {
      enabled: !!accessToken && !!isFeideUser,
      onError(err) {
        Sentry.captureException(err);
      },
    },
  );

  // If not a feide user, there wont be any LDAP data on the user, so just return an empty dict
  if (isFeideUser === false) {
    // See if user is idPorten user and retrieve the NIN if is:
    const userIdSec = basicInformation.personalInformation?.user.userIdSec;
    let feideId: string | undefined;
    if (userIdSec) {
      feideId = userIdSec.find((id) => id.startsWith('nin:'))?.replace('nin:', '');
    }
    if (basicInformation.personalInformation) {
      personalInformation = getGuestUserDetailedUserInfo(basicInformation, feideId);
      return {
        personalInformation,
        error: undefined,
        isLoading: false,
      };
    }
    return {
      isLoading: true,
    };
  }

  return {
    isLoading,
    personalInformation,
    error: error ?? undefined,
  };
}

export default useDetailedPersonalInformation;
