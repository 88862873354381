import * as Sentry from '@sentry/browser';
import { useQuery } from 'react-query';
import { GroupMembership } from '../../types/group';
import { buildApiDataportenUri, getData } from '../../util/api';
import useBasicPersonalInformation from '../useBasicPersonalInformation';

export interface GroupOwner {
  name: string;
  id: string;
}

export type AdhocGroupMemberType = 'admin' | 'member';

export interface AdhocGroupMember {
  id: string;
  isOwner: boolean;
  name: string;
  status: 'normal'; // What are the other alternatives?
  type: AdhocGroupMemberType;
  /**
   * Flag that make it easier for us to spot which member the current user is.
   * */
  isCurrentUser: boolean;
}

export interface AdhocGroup {
  members: AdhocGroupMember[];
  createdOn: string;
  description: string;
  id: string;
  /**
   * Token that can be used to invite users to a group.
   * Is only available for admin and owners
   * */
  invitationToken?: string;
  name: string;
  owner: GroupOwner;
  isPublic: boolean;
  lastUpdated?: string;
}

export type Details = {
  id: string;
  name: string;
  created: string;
  descr: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  invitation_token?: string;
  public: boolean;
  updated?: string;
  owner: GroupOwner;
};

/**
 * Retrieve the user that is the current member.
 * */
export function extractCurrentMember(members: AdhocGroupMember[]): AdhocGroupMember | undefined {
  return members.find(({ isCurrentUser }) => isCurrentUser);
}

async function retrieveAdhocGroupDetails(token: string, groupId: string, membership: GroupMembership): Promise<AdhocGroup> {
  let uri = buildApiDataportenUri(`adhocgroups/${groupId}`);
  if (membership.basic === 'owner' || membership.basic === 'admin') {
    // Only the details endpoint has the invite link to the group
    uri = buildApiDataportenUri(`adhocgroups/${groupId}/details`);
  }
  console.info(`Retrieving using uri: ${uri}`);
  const details = (await getData(token, uri)) as Details;

  return {
    id: details.id,
    name: details.name,
    members: [],
    createdOn: details.created,
    description: details.descr,
    invitationToken: details.invitation_token,
    isPublic: details.public,
    lastUpdated: details.updated,
    owner: details.owner,
  };
}

async function retrieveAdhocGroupMembers(token: string, groupId: string): Promise<AdhocGroupMember[]> {
  type Members = {
    id: string;
    name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_owner: boolean;
    status: 'normal';
    type: AdhocGroupMemberType;
  };
  const members = (await getData(token, buildApiDataportenUri(`adhocgroups/${groupId}/members`))) as Members[];

  return members.map((member) => ({
    id: member.id,
    name: member.name,
    isOwner: member.is_owner,
    status: member.status,
    type: member.type,
    // This property is filled later by external information
    isCurrentUser: false,
  }));
}

async function getAdhocGroupDetails(token: string, groupId: string, membership: GroupMembership): Promise<AdhocGroup> {
  const details = await retrieveAdhocGroupDetails(token, groupId, membership);
  const members = await retrieveAdhocGroupMembers(token, groupId);

  return {
    ...details,
    members,
  };
}

interface UseRetrieveAdhocGroupDetailsResults {
  isLoading: boolean;
  group?: AdhocGroup;
  error?: Error;
}

function useRetrieveAdhocGroupDetails(params: {
  accessToken?: string;
  groupId?: string;
  currentMembership: GroupMembership;
}): UseRetrieveAdhocGroupDetailsResults {
  const { accessToken, groupId, currentMembership } = params;

  const { personalInformation: currentUser, isLoading: isLoadingUser } = useBasicPersonalInformation({ accessToken });

  const {
    data: group,
    isLoading,
    error,
  } = useQuery<AdhocGroup, Error>(
    // The groups may contain translated names
    ['groups', 'adhoc', groupId, 'details'],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getAdhocGroupDetails(accessToken!, groupId!, currentMembership),
    {
      enabled: !!accessToken && !!groupId,
      onError(err) {
        Sentry.withScope((scope) => {
          if (params.groupId) {
            scope.setTag('groupId', params.groupId);
            // A user's membership may quickly determine why something is failing
            scope.setExtras({ currentGroupMembership: params.currentMembership.basic });
          }
          Sentry.captureException(err);
        });
      },
    },
  );

  let enrichedGroup: AdhocGroup | undefined;

  if (group) {
    // Enrich the group information with data from other sources.
    // Such as which member the current user is
    enrichedGroup = {
      ...group,
      members: group.members.map((member) => ({
        ...member,
        // Apparently the member.id is set to the user's profilePhoto identifier, and not the userid...
        // Also! Make sure that Innsyn has the scope 'userinfo-photo' or else this value will not be present
        isCurrentUser: currentUser?.user?.profilePhotoID === member.id,
      })),
    };
  }

  return {
    // We should wait to return the group information until the currentUser has been retrieved,
    // since it is expected by other components that members.isCurrentUser is properly injected
    group: isLoadingUser ? undefined : enrichedGroup,
    isLoading: isLoading || isLoadingUser,
    error: error ?? undefined,
  };
}

export default useRetrieveAdhocGroupDetails;
