import * as Sentry from '@sentry/browser';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { buildApiDataportenUri, getData, postRequest } from '../../util/api';
import { AdhocGroup, Details } from './useRetrieveAdhocGroupDetails';

/**
 * Retrieves similar details that useRetrieveAdhocGroupDetails extracts,
 * but requires an invitationToken for access. This is intended for users
 * that haven't yet joined this group.
 * */
async function getAdhocGroupInvitationDetails(token: string, groupId: string, invitationToken: string): Promise<AdhocGroup> {
  const details = (await getData(token, buildApiDataportenUri(`adhocgroups/${groupId}?invitation_token=${invitationToken}`))) as Details;

  return {
    id: details.id,
    name: details.name,
    members: [],
    createdOn: details.created,
    description: details.descr,
    invitationToken: details.invitation_token,
    isPublic: details.public,
    lastUpdated: details.updated,
    owner: details.owner,
  };
}

interface UseRetrieveGroupInvitationDetailsResults {
  group?: AdhocGroup;
  isLoading: boolean;
  error?: Error;
}

export function useRetrieveGroupInvitationDetails(params: {
  accessToken: string;
  groupId: string;
  invitationToken: string;
}): UseRetrieveGroupInvitationDetailsResults {
  const {
    data: group,
    isLoading,
    error,
  } = useQuery<AdhocGroup, Error>(
    // The groups may contain translated names
    ['groups', 'adhoc', params.groupId, 'joindetails', params.invitationToken],
    () => getAdhocGroupInvitationDetails(params.accessToken, params.groupId, params.invitationToken),
  );

  return {
    group,
    isLoading,
    error: error ?? undefined,
  };
}

function joinAdhocGroup(accessToken: string, groupId: string, invitationToken: string): Promise<object> {
  return postRequest(accessToken, buildApiDataportenUri(`adhocgroups/${groupId}/invitation`), { invitation_token: invitationToken });
}

export function useJoinAdhocGroup(params: { accessToken: string; groupId: string; invitationToken: string }): {
  joinGroup: () => void;
  isJoining: boolean;
  hasJoined: boolean;
} {
  const queryClient = useQueryClient();

  const mutation = useMutation<object, Error>(() => joinAdhocGroup(params.accessToken, params.groupId, params.invitationToken), {
    onSuccess() {
      queryClient.refetchQueries(['groups'], { exact: false });
    },
    onError(error) {
      console.error(`Failed to join ad hoc group (GroupId: ${params.groupId}, InvitationToken: ${params.invitationToken})`, error);
      Sentry.withScope((scope) => {
        scope.setTags({ adhocGroupId: params.groupId });
        Sentry.captureException(error);
      });
    },
  });

  const joinGroup = () => {
    mutation.mutate();
  };

  return {
    joinGroup,
    isJoining: mutation.isLoading,
    hasJoined: mutation.isSuccess,
  };
}
