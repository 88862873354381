import * as Sentry from '@sentry/browser';
import { useMutation, useQueryClient } from 'react-query';
import { buildApiDataportenUri, deleteData, patchRequest } from '../../util/api';
import { AdhocGroupMember, AdhocGroupMemberType } from './useRetrieveAdhocGroupDetails';

function deleteUserFromGroup(accessToken: string, groupId: string, userId: string): Promise<Response> {
  const uri = buildApiDataportenUri(`adhocgroups/${groupId}/members`);

  return deleteData(accessToken, uri, [userId]);
}

interface UseRemoveAdhocGroupMemberResults {
  readonly removeUserFromGroup: (userId: string) => void;
  readonly isRemoving: boolean;
  readonly error?: Error;
}

export function useRemoveAdhocGroupMember(params: { accessToken: string; groupId: string }): UseRemoveAdhocGroupMemberResults {
  const queryClient = useQueryClient();

  const mutation = useMutation<Response, Error, { groupId: string; userId: string; accessToken: string }>(
    ({ groupId, userId, accessToken }) => deleteUserFromGroup(accessToken, groupId, userId),
    {
      onSuccess() {
        queryClient.refetchQueries(['groups', 'adhoc', params.groupId, 'details'], { exact: false });
      },
      onError(error, { groupId, userId }) {
        console.error(`Failed to remove person from ad hoc group (GroupId: ${groupId}, userId: ${userId})`, error);
      },
    },
  );

  const removeUserFromGroup = (userId: string) => {
    mutation.mutate({
      userId,
      groupId: params.groupId,
      accessToken: params.accessToken,
    });
  };

  return {
    removeUserFromGroup,
    isRemoving: mutation.isLoading,
    error: mutation.error ?? undefined,
  };
}

/**
 * Contains a reduced number of fields from AdhocGroupMember,
 * which is relevant when mutating the member.
 * */
type SimplifiedAdhocGroupMember = Pick<AdhocGroupMember, 'id' | 'type'>;

interface AdhocGroupMemberMutation {
  groupId: string;
  member: SimplifiedAdhocGroupMember;
  accessToken: string;
}

function updateMemberInGroup(accessToken: string, groupId: string, member: SimplifiedAdhocGroupMember): Promise<object> {
  const uri = buildApiDataportenUri(`adhocgroups/${groupId}/members`);
  return patchRequest(accessToken, uri, [member]);
}

interface UseMutateAdhocGroupMemberResults {
  readonly updateMember: (member: { id: string; type: AdhocGroupMemberType }) => void;
  readonly isUpdating: boolean;
  readonly error?: Error;
}

export function useMutateAdhocMember(params: { accessToken: string; groupId: string }): UseMutateAdhocGroupMemberResults {
  const queryClient = useQueryClient();

  const mutation = useMutation<object, Error, AdhocGroupMemberMutation>(
    ({ groupId, member, accessToken }) => updateMemberInGroup(accessToken, groupId, member),
    {
      onSuccess() {
        queryClient.refetchQueries(['groups', 'adhoc', params.groupId, 'details'], { exact: false });
      },
      onError(error: Error, { groupId, member }) {
        console.error(
          `Failed to remove person from ad hoc group (GroupId: ${groupId}, memberId: ${member.id}, memberType: ${member.type})`,
          error,
        );
      },
    },
  );

  const updateMember = (member: SimplifiedAdhocGroupMember) => {
    mutation.mutate({
      member,
      groupId: params.groupId,
      accessToken: params.accessToken,
    });
  };

  return {
    updateMember,
    isUpdating: mutation.isLoading,
    error: mutation.error ?? undefined,
  };
}

function leaveAdhocGroup(accessToken: string, groupId: string): Promise<Response> {
  const uri = buildApiDataportenUri('adhocgroups/memberships');
  return deleteData(accessToken, uri, [groupId]);
}

interface UseLeaveAdhocGroupResults {
  leaveGroup: () => void;
  isLeaving: boolean;
  hasLeft: boolean;
  error?: Error;
}

export function useLeaveAdhocGroup(params: { accessToken: string; groupId: string }): UseLeaveAdhocGroupResults {
  const queryClient = useQueryClient();

  const mutation = useMutation<Response, Error, { accessToken: string; groupId: string }>(
    ({ groupId, accessToken }) => leaveAdhocGroup(accessToken, groupId),
    {
      onSuccess() {
        queryClient.refetchQueries(['groups'], { exact: false });
      },
      onError(error, { groupId }) {
        console.error(`Failed to leave ad hoc group (GroupId: ${groupId})`, error);
        Sentry.withScope((scope) => {
          scope.setTags({ groupId: params.groupId });
          Sentry.captureException(error);
        });
      },
    },
  );

  const leaveGroup = () => {
    mutation.mutate({
      accessToken: params.accessToken,
      groupId: params.groupId,
    });
  };

  return {
    leaveGroup,
    isLeaving: mutation.isLoading,
    hasLeft: mutation.isSuccess,
    error: mutation.error ?? undefined,
  };
}

function deleteAdhocGroup(accessToken: string, groupId: string): Promise<Response> {
  const uri = buildApiDataportenUri(`adhocgroups/${groupId}`);
  return deleteData(accessToken, uri);
}

interface UseDeleteAdhocGroupResults {
  deleteGroup: () => void;
  isDeleting: boolean;
  hasDeleted: boolean;
  error?: Error;
}

export function useDeleteAdhocGroup(params: { accessToken: string; groupId: string }): UseDeleteAdhocGroupResults {
  const queryClient = useQueryClient();

  const mutation = useMutation<Response, Error, { accessToken: string; groupId: string }>(
    ({ groupId, accessToken }) => deleteAdhocGroup(accessToken, groupId),
    {
      onSuccess() {
        // Clear the group details for this exact group,
        // so react-query do not attempt to re-fetch a deleted group
        queryClient.removeQueries(['groups', 'adhoc', params.groupId], { exact: false });
        queryClient.refetchQueries(['groups'], { exact: false });
      },
      onError(error, { groupId }) {
        console.error(`Failed to leave ad hoc group (GroupId: ${groupId})`, error);
      },
    },
  );

  const deleteGroup = () => {
    mutation.mutate({
      accessToken: params.accessToken,
      groupId: params.groupId,
    });
  };

  return {
    deleteGroup,
    isDeleting: mutation.isLoading,
    hasDeleted: mutation.isSuccess,
    error: mutation.error ?? undefined,
  };
}
