import * as Sentry from '@sentry/browser';
import { useMutation, useQueryClient } from 'react-query';
import { buildApiDataportenUri, patchRequest } from '../../util/api';

interface BasicGroupDetails {
  name: string;
  description?: string;
  isPublic: boolean;
}

function updateAdhocGroup(accessToken: string, groupId: string, group: BasicGroupDetails): Promise<object> {
  const uri = buildApiDataportenUri(`adhocgroups/${groupId}`);

  return patchRequest(accessToken, uri, {
    name: group.name,
    descr: group.description,
    public: group.isPublic,
  }) as Promise<object>;
}

interface UseMutateAdhocGroupResults {
  updateGroup: (group: BasicGroupDetails) => void;
  isUpdating: boolean;
  hasBeenUpdated: boolean;
  error?: Error;
}

function useMutateAdhocGroup(params: { accessToken: string; groupId: string }): UseMutateAdhocGroupResults {
  const queryClient = useQueryClient();

  const mutation = useMutation<object, Error, { group: BasicGroupDetails }>(
    ({ group }) => updateAdhocGroup(params.accessToken, params.groupId, group),
    {
      onSuccess() {
        queryClient.refetchQueries(['groups', 'adhoc', params.groupId, 'details'], { exact: false });
      },
      onError(error) {
        console.error('Failed to remove upgrade ad hoc group', error);
        Sentry.withScope((scope) => {
          scope.setTags({ groupId: params.groupId });
          Sentry.captureException(error);
        });
      },
    },
  );

  const updateGroup = (group: BasicGroupDetails) => {
    mutation.mutate({ group });
  };

  return {
    updateGroup,
    isUpdating: mutation.isLoading,
    hasBeenUpdated: mutation.isSuccess,
    error: mutation.error ?? undefined,
  };
}

export default useMutateAdhocGroup;
