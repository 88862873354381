import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './index.scss';

export type Selected = {
  key: string;
  label: string;
  isDefault: boolean;
  isSelected: boolean | null;
};

type Props = {
  type: string;
  onChange: (option: Selected | null) => void;
  options: Selected[];
  className?: string;
};

function FilterDropdown({
  type, onChange, options, className,
}: Props): React.JSX.Element {
  const { t } = useTranslation('groups');

  const handleChange = (option: Selected) => () => onChange(option);

  const selectedOption = options.find((option) => option.isSelected);
  // Find the one labelled default or use the first item
  const defaultOption = options.find((option) => option.isDefault) || options[0];

  const uniqueId = `filterdropdown-${type}`;

  // i18next extract is not confident on paths inside this element
  // i18next-extract-disable
  return (
    <div className={classNames('filterdropdown', className)}>
      <label htmlFor={uniqueId} aria-label={uniqueId}>
        {t(type)}
      </label>
      <div className="filterdropdown__dropdown">
        <button
          className="btn dropdown-toggle filterdropdown__dropdown__button"
          type="button"
          id={uniqueId}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {selectedOption ? selectedOption.label : defaultOption.label}
        </button>
        <div className="dropdown-menu filterdropdown__dropdown__options" aria-labelledby={uniqueId}>
          {options.map((option) => (
            <button key={option.key} className="dropdown-item" type="button" onClick={handleChange(option)}>
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
  // i18next-extract-enable
}
export default FilterDropdown;
