import React, { useState } from 'react';
import classNames from 'classnames';
import './index.scss';

type Props = {
  children: React.ReactNode;
};

function SensitiveDataField({ children }: Props): React.JSX.Element {
  const [show, setShow] = useState(false);

  const toggleVisibility = () => {
    setShow(!show);
  };

  const eyeClassName = classNames('far', {
    'fa-eye': !show,
    'fa-eye-slash': show,
  });

  // TODO(fredrfli) Replace button with a checkbox/togglebutton
  return (
    <div className="hidden-container">
      <button onClick={toggleVisibility} aria-label="Toggle show/hide sensitive data">
        <i className={eyeClassName} aria-hidden="true" />
      </button>
      <p className="sensitive-container">{show ? children : '•••••••••••'}</p>
    </div>
  );
}

export default SensitiveDataField;
