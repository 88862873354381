import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUsernameFromPrincipalName } from '../../../util/feideUtil';
import { BasicPersonalInformation } from '../../../hooks/useBasicPersonalInformation';
import { FeideAttributes } from '../../../hooks/useDetailedPersonalInformation';
import { UseGroupInformationResults } from '../../../hooks/useGroupInformation';
import { Group } from '../../../types/group';

import image from './user.svg';
import './index.scss';

function findOrganization(groups: Group[]) {
  const org = groups.find((group) => group.eduOrgLegalName);

  if (!org) {
    return '';
  }

  return org.eduOrgLegalName;
}

interface Props {
  basicInfo?: BasicPersonalInformation;
  detailedInfo?: FeideAttributes;
  groupInfo: UseGroupInformationResults;
  eduPersonPrincipalName?: string;
}

function BasicInfo({
  basicInfo, detailedInfo, groupInfo, eduPersonPrincipalName,
}: Props): React.JSX.Element | null {
  const { t } = useTranslation('userinfo');

  if (!basicInfo) {
    return null;
  }
  const userData = basicInfo.user;

  // Userdata might not be present yet
  if (!userData || !groupInfo) {
    return null;
  }

  let img = image;
  if (detailedInfo && detailedInfo.jpegPhoto) {
    // The `jpegPhoto` is only available for Feide users, but only Feide users have profile photos in Dataporten. <
    // This check therefore ensures that the users get the innsyn "placeholder profile photo" instead of the Dataporten placeholder photo.
    img = `https://api.dataporten.no/userinfo/v1/user/media/${userData.profilePhotoID}`;
  }

  let username: string | null;
  if (eduPersonPrincipalName) {
    username = getUsernameFromPrincipalName(eduPersonPrincipalName);
  } else {
    username = '-';
  }

  return (
    <div className="level no-margin basic-container">
      <h1 id="main" className="visuallyhidden">
        {t('translation:navbar.aboutMe')}
      </h1>
      <img src={img} alt={`Profile of ${userData.name}`} className="profile-picture profile-alignment level-item" />
      <section className="profile-alignment details-container level-item">
        <h3 className="pagetitle">{userData.name}</h3>
        <table className="profile-table before:border-none">
          <tbody>
            <tr className="table-border">
              <td className="left-column-basic">{t('basicInfo.userName')}</td>
              <td>{username}</td>
            </tr>
            <tr className="table-border">
              <td className="left-column-basic">{t('basicInfo.org')}</td>
              <td>{findOrganization(groupInfo.groups)}</td>
            </tr>
            <tr className="table-border">
              <td className="left-column-basic">{t('basicInfo.email')}</td>
              <td>{userData.email}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default BasicInfo;
