import { PersistenceError } from './customErrors';

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}
/**
 * Persists some payload to localStorage,
 * or fails with PersistenceError
 *
 * @throws {PersistenceError}
 * */
export function persist(key: string, payload: string): void | never {
  try {
    localStorage.setItem(key, JSON.stringify(payload));
  } catch (err) {
    const message = getErrorMessage(err);
    throw new PersistenceError(`Failed to persist ${key} in localStorage. Error '${message}'`);
  }
}

/**
 * Retrieves some payload from localStorage
 * and parses it as JSON.
 *
 * @throws {PersistenceError} If it fails to read from localStorage
 * */
export function retrieve(key: string): string | null {
  let data: string | null;
  try {
    data = localStorage.getItem(key);
  } catch (err) {
    throw new PersistenceError(`Failed to retrieve ${key} from localStorage. Error '${getErrorMessage(err)}'`);
  }

  if (!data) {
    return data;
  }

  return JSON.parse(data);
}
