/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import classNames from 'classnames';

import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import useAuthInformation from '../../../../hooks/auth/useAuthInformation';
import { hasTokenExpired } from '../../../../hooks/auth/useRequiredAuthInformation';
import ChangeLanguage from '../ChangeLanguage';

import * as Routes from '../../../../routes/constants';

import Logo from './Logo';
import FeideMenuButton from './FeideMenuButton';
import useWindowWidth from '../../../../util/hooks/windowWidth';

import './Navbar.scss';

type Route = {
  to: string;
  label: string;
  isActive: boolean;
};

type ExpandableNavMenuProps = {
  uid: string;
  routes: Route[];
  isExpanded: boolean;
  onNavigate: (route: Route, index: number) => void;
  languageChanger: React.JSX.Element;
  signOutButton: React.JSX.Element;
};
function ExpandableNavMenu({
  uid,
  routes,
  isExpanded,
  onNavigate,
  languageChanger,
  signOutButton,
}: ExpandableNavMenuProps): React.JSX.Element {
  const handleNavigation = (route: Route, index: number) => () => onNavigate(route, index);

  return (
    <div id={uid} className={classNames('expandablenavmenu', { 'expandablenavmenu-show': isExpanded })}>
      <ul className="expandablenavmenu-links">
        <div className="dropdown-divider" />

        {routes.map((route, idx) => (
          <li key={`navmenu-${idx}`} onClick={handleNavigation(route, idx)}>
            <Link to={route.to} className={classNames('btn navbar-button', { 'active-navbar-link': route.isActive })}>
              {route.label}
            </Link>
          </li>
        ))}
        <div className="dropdown-divider" />
        <li className="nav-item">{languageChanger}</li>
        <li className="nav-item">{signOutButton}</li>
      </ul>
    </div>
  );
}

function Navbar({ location }: RouteComponentProps): React.JSX.Element {
  const { accessToken, expiresOn } = useAuthInformation();

  const { t } = useTranslation();
  // Remember if hamburger menu should be open or closed
  const [opened, setOpened] = useState(false);
  const width = useWindowWidth();

  const stickyNavbar = width < 992;
  const path = location.pathname;

  // Used to let accessibility utilities
  // connect the relationship between menu button and navmenu
  const navmenuControlsUid = 'navmenu-control-hook';

  const isAuthenticated = (token: string | undefined, expires: Moment) => {
    if (!token) {
      return false;
    }
    return !hasTokenExpired(expires);
  };

  const logoutLink = isAuthenticated(accessToken, expiresOn) ? (
    <Link to={Routes.LOGOUT} className="btn navbar-button">
      {t('navbar.logOut')}
    </Link>
  ) : (
    <Link to={Routes.LOGIN} className="btn navbar-button">
      {t('navbar.logIn')}
    </Link>
  );

  const routes = [
    {
      to: Routes.HOME,
      label: t('navbar.home'),
      isActive: path === Routes.HOME,
    },
    {
      to: Routes.PERSONALINFO,
      label: t('navbar.aboutMe'),
      isActive: path === Routes.PERSONALINFO,
    },
    {
      to: Routes.GROUPINFO,
      label: t('navbar.groups'),
      isActive: path === Routes.GROUPINFO,
    },
    {
      to: Routes.APPINFO,
      label: t('navbar.applications'),
      isActive: path === Routes.APPINFO,
    },
    {
      to: Routes.PRIVACYINFO,
      label: t('navbar.privacy'),
      isActive: path === Routes.PRIVACYINFO,
    },
  ];

  return (
    <header className={classNames('navbar navbar-light shadowed-normal', { 'sticky-top': stickyNavbar })}>
      <Logo onClick={() => setOpened(false)} />
      <div className="navbar-primarybuttons">
        <FeideMenuButton
          onClick={(isOpen) => setOpened(isOpen)}
          opened={opened}
          text={t('navbar.menu')}
          className="navbar-collapsetoggle"
          aria-controls={navmenuControlsUid}
        />

        {!stickyNavbar && (
          <>
            <ChangeLanguage />

            {logoutLink}
          </>
        )}
      </div>
      <ExpandableNavMenu
        uid={navmenuControlsUid}
        isExpanded={opened}
        onNavigate={() => setOpened(false)}
        routes={routes}
        languageChanger={<ChangeLanguage />}
        signOutButton={logoutLink}
      />
    </header>
  );
}

export default withRouter(Navbar);
