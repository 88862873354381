import React from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalMoreInfo from '../../../../features/ui/ConditionalMoreInfo';
import './index.scss';
import { ServiceType } from '../../../../hooks/service/useListMyAuthorizedServices';

interface ScopesProps {
  scopes: string[];
  type: ServiceType;
}

function Scopes({ scopes, type }: ScopesProps): React.JSX.Element {
  // Both attributes and scopes namespaces can be used
  const { t } = useTranslation(['attributes', 'scopes']);

  const localizeScope = (scope: string, isSaml: boolean) => {
    // i18next-extract-disable
    if (isSaml) {
      return t([`attributes:attribute.${scope.replace(':', '-')}`, 'attributes:unknown'], { attribute: scope });
    }

    return t([`scopes:scope.${scope}`, 'scopes:unknown'], { scope });
    // i18next-extract-enable
  };

  if (scopes.length === 0) {
    return <div />;
  }

  const scopeList = scopes.map((scope, idx) => (
    <li key={`scope-${idx}`}>
      {localizeScope(scope, type === 'saml')}
      <ConditionalMoreInfo>
        {' ('}
        <i>{scope}</i>
        {' )'}
      </ConditionalMoreInfo>
    </li>
  ));

  return <ul className="scope-list">{scopeList}</ul>;
}

export default Scopes;
