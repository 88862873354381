import * as Sentry from '@sentry/browser';
import { useMutation, useQueryClient } from 'react-query';
import {
  buildApiDataportenUri, buildFeideConsentApiUri, deleteData, getData,
} from '../../util/api';
import { ServiceType } from './useListMyAuthorizedServices';

function revokeServiceAuthorization(serviceId: string, type: ServiceType, accessToken: string): Promise<object> {
  if (type === 'oauth') {
    const uri = buildApiDataportenUri(`authorizations/${serviceId}`);
    return deleteData(accessToken, uri);
  }

  // SAML services go through a Gatekeeper endpoint and sends an HTTP GET request
  const uri = `${buildFeideConsentApiUri()}?action=revoke&key=${serviceId}`;
  return getData(accessToken, uri) as Promise<object>;
}

interface UseRevokeServiceAuthorizationResults {
  revokeAuthorization: (serviceId: string, type: ServiceType) => void;
  isDeleting: boolean;
  error?: Error;
}

/**
 * Provides the function revokeAuthorization that revokes authorization
 * (consent) to a service.
 *
 * Note that if accessToken is not present when the user invokes revokeAuthorization,
 * it will throw an exception.
 * */
function useRevokeServiceAuthorization(params: { accessToken?: string }): UseRevokeServiceAuthorizationResults {
  const queryClient = useQueryClient();

  const mutation = useMutation<object, Error, { serviceId: string; type: ServiceType; accessToken: string }>(
    ({ serviceId, type, accessToken }) => revokeServiceAuthorization(serviceId, type, accessToken),
    {
      /**
       * Force the list of authorized services to be reloaded, by re-fetching them
       * */
      onSuccess() {
        queryClient.refetchQueries(['services', 'authorized'], { exact: false });
      },
      onError(error, { serviceId, type }) {
        console.error(`Failed to revoke service authorization (ServiceId: ${serviceId}, type: ${type})`, error);
        Sentry.withScope((scope) => {
          scope.setTag('serviceId', serviceId);
          scope.setTag('serviceType', type);
          Sentry.captureException(error);
        });
      },
    },
  );

  const revokeAuthorization = (serviceId: string, type: ServiceType) => {
    if (!params.accessToken) {
      throw new Error('Required access token is missing');
    }
    mutation.mutate({ serviceId, type, accessToken: params.accessToken });
  };

  return {
    revokeAuthorization,
    isDeleting: mutation.isLoading,
    error: mutation.error ?? undefined,
  };
}

export default useRevokeServiceAuthorization;
