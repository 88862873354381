import React, { useEffect } from 'react';
import useAuthInformation from '../hooks/auth/useAuthInformation';
import LoadingView from '../features/ui/LoadingView';

const logoutDataporten = () => 'https://auth.dataporten.no/logout';

function Logout(): React.JSX.Element {
  const { clearAccessToken } = useAuthInformation();

  useEffect(() => {
    clearAccessToken();
    window.location.replace(logoutDataporten());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="loading-wrapper">
      <LoadingView />
    </div>
  );
}

export default Logout;
