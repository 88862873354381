import { getWindowCrypto } from '../cryptoUtil';

const NONCE_KEY = 'nonce';

/**
 * Uses Window.crypto [2] to generate a random string.
 * This is typically strong enough for basic nonce generation.
 * However, note the potential attack by polyfill issues in [2].
 *
 * [1] https://auth0.com/docs/api-auth/tutorials/nonce#generate-a-cryptographically-random-nonce
 * [2] https://developer.mozilla.org/en-US/docs/Web/API/Window/crypto
 * */
function randomString(preferredLength: number): string {
  // Identify the supported crypto instance
  const { crypto } = getWindowCrypto();

  let length = preferredLength;
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
  let result = '';

  while (length > 0) {
    const bytes = new Uint8Array(16);
    const random = crypto.getRandomValues(bytes);
    // eslint-disable-next-line no-loop-func
    random.forEach((char: number) => {
      if (length === 0) {
        return;
      }

      if (char < charset.length) {
        result += charset[char];
        length -= 1;
      }
    });
  }
  return result;
}

/**
 * Generates a Cryptographic Nonce (using window.crypto),
 * which can be used as nonce in OpenID Connect or as general
 * state in OAuth
 * */
export const generateNonce = (): string => randomString(20);

export function persistNonce(nonce: string): void {
  localStorage.setItem(NONCE_KEY, nonce);
}

const retrieveNonce = () => localStorage.getItem(NONCE_KEY);
const purgeNonce = () => localStorage.removeItem(NONCE_KEY);

export function isValidNonce(reflectedNonce: string): boolean {
  if (!reflectedNonce || reflectedNonce.length < 5) {
    console.warn(`Received nonce (${reflectedNonce}) is missing or too short (min length: 20)`);
    return false;
  }

  const nonce = retrieveNonce();
  purgeNonce();

  if (!nonce || nonce.length < 5) {
    console.error('Stored nonce is missing or too short. This could be a CSRF attack');
    return false;
  }

  if (reflectedNonce !== nonce) {
    console.warn(`Received (${reflectedNonce}) and stored (${nonce}) nonce did not match`);
    return false;
  }

  return true;
}
