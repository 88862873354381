import 'whatwg-fetch';
import 'core-js/stable';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import App from './App';
import { AuthenticationProvider } from './contexts/authenticationContext';
import { UserPreferenceProvider } from './contexts/userPreferenceContext';
import { queryClient } from './queryClient';
import * as serviceWorker from './serviceWorker';
import { initSentry } from './util/sentry';

// eslint-disable-next-line import/no-named-as-default
import OuterErrorBoundary from './features/error/OuterErrorBoundary';
import { registerReactAxe } from './util/accessibilityUtil';

import './style/globals.scss';
import { registerLocaleEngine } from './util/language/localeEngine';

// Init sentry before application is started
initSentry();
// Give detailed accessibility feedback
registerReactAxe();
// Register the engine to do translation on the page
registerLocaleEngine();

ReactDOM.render(
  <AuthenticationProvider>
    <UserPreferenceProvider>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div className="loading-wrapper"/>}>
          <OuterErrorBoundary>
            <App/>
          </OuterErrorBoundary>
        </Suspense>
      </QueryClientProvider>
    </UserPreferenceProvider>
  </AuthenticationProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
