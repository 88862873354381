import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import './index.scss';

type Props = {
  label: string;
  children: ReactNode;
};

function ExpandableTextBox({ children, label }: Props): React.JSX.Element {
  const [scopesHidden, setScopesHidden] = useState(true);

  const hideScopes = () => setScopesHidden(!scopesHidden);

  return (
    <div>
      <button className="button-flat margin-left" onClick={() => hideScopes()}>
        {label} <i className={classNames('fas', { 'fa-chevron-down': scopesHidden, 'fa-chevron-up': !scopesHidden })} />
      </button>
      <div className={classNames('expandabletextbox-content', { hidden: scopesHidden })}>{children}</div>
    </div>
  );
}
export default ExpandableTextBox;
