import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import InlineConfirmableButton from '../../features/ui/InlineConfirmableButton';
import useRequiredAuthInformation from '../../hooks/auth/useRequiredAuthInformation';
import { useJoinAdhocGroup, useRetrieveGroupInvitationDetails } from '../../hooks/groups/joinAdhocGroup';
import useGroupInformation from '../../hooks/useGroupInformation';

/**
 * This page prompts the user with some basic information about the invited group
 * and whether she/he wishes to join.
 * */
function JoinAdhocGroupPage(): React.JSX.Element | null {
  const { t } = useTranslation('joingroup');
  const { groupId, invitationToken } = useParams<{ groupId: string; invitationToken: string }>();
  const history = useHistory();

  const { accessToken } = useRequiredAuthInformation();
  const { groups } = useGroupInformation({ accessToken });
  const { group } = useRetrieveGroupInvitationDetails({ accessToken, groupId, invitationToken });
  const { joinGroup, isJoining, hasJoined } = useJoinAdhocGroup({ accessToken, groupId, invitationToken });

  const goToHomePage = () => {
    // Redirect with a full reload of the page, to clear any state when the user aborts
    window.location.href = '/';
  };

  useEffect(() => {
    if (hasJoined) {
      history.replace('/groups');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasJoined]);

  const isAlreadyMember = groups.some(({ id }) => id.endsWith(groupId));

  // The user is already a member of this group
  if (isAlreadyMember) {
    history.replace('/groups');
    return null;
  }

  if (!group) {
    return <div className="collapse-wrapper content-box" />;
  }

  return (
    <div className="collapse-wrapper content-box">
      <h1 className="pagetitle">{t('title', { group: group.name })}</h1>
      <p>
        <strong>{t('owner')}:</strong> <span>{group.owner.name}</span>
      </p>
      <p>{group.description}</p>

      {!isJoining && (
        <InlineConfirmableButton
          onConfirm={joinGroup}
          onAbort={goToHomePage}
          confirmationTitle={t('confirmTitle')}
          confirmationMessage={t('confirmMessage')}
          confirmButtonText={t('confirmButtonText')}
          abortButtonText={t('abortButtonText')}
        />
      )}
    </div>
  );
}

export default JoinAdhocGroupPage;
