/**
 * Ideally we wanted to use import.meta.env.DEV and PROD injected from ViteJS.
 * However, Jest did not like this _at all_.
 * */
export function getEnvironment(): string | undefined {
  if (!window || !window.location) {
    return undefined;
  }

  const { hostname } = window.location;
  if (hostname === 'innsyn.feide.no') {
    return 'production';
  }

  if (hostname === 'feide-innsyn-staging.paas2.uninett.no') {
    // This should be converted to staging, when rest of the application is prepared to do so
    return 'production';
  }

  return 'development';
}

// eslint-disable-next-line import/prefer-default-export
export const isDevelopment = (): boolean => getEnvironment() === 'development';
export const isProduction = (): boolean => getEnvironment() === 'production';
