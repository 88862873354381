import { UseBasicPersonalInformationResults } from './useBasicPersonalInformation';
import { getRealmFromPrincipalName } from '../util/feideUtil';

/*
 * This hooks helps determines what type of user we are dealing with, this is used several times
 * further throughout the about me section on the innsyn page. So far there are 4 groups of users
 * feide logins, guest logins, social logins and idporten logins. We seperate them into either
 * feide or not feide logins for simplicity
 */

function determineFeideUser(eduPersonPrincipalName?: string, accessToken?: string): boolean | undefined {
  if (
    // feide user
    eduPersonPrincipalName // Ensure value exist before sending to getRealmFunction
    && getRealmFromPrincipalName(eduPersonPrincipalName) !== 'rnd.feide.no'
  ) {
    return true;
  }
  if (
    eduPersonPrincipalName // guest user
    && getRealmFromPrincipalName(eduPersonPrincipalName) === 'rnd.feide.no'
  ) {
    return false;
  }
  if (accessToken && !eduPersonPrincipalName) {
    // social user
    return false;
  }
  return undefined;
}

function useGetIsFeideUser({
  basicInformation,
  accessToken,
}: {
  basicInformation: UseBasicPersonalInformationResults;
  accessToken?: string;
}): boolean | undefined {
  let isFeideUser: boolean | undefined;
  if (basicInformation) {
    isFeideUser = determineFeideUser(basicInformation.eduPersonPrincipalName, accessToken);
  }
  return isFeideUser;
}

export default useGetIsFeideUser;
