import React, { ReactNode, useState } from 'react';
import { v4 as uuid } from 'uuid';
import GeneralModal from '../../../ui/GeneralModal';
import FeideButton from '../../../ui/FeideButton';

import './index.scss';

type Props = {
  onConfirm: () => void;
  children: ReactNode;
  modalActionLabels: { dismiss: string; confirm: string };
  modalHeader: string;
  modalBody: ReactNode;
};

/**
 * Actionable button, which requires an extra confirmation step from the user.
 * Uses a modal to to the confirmation.
 * */
function ConfirmableButton({
  onConfirm, children, modalActionLabels, modalHeader, modalBody,
}: Props): React.JSX.Element {
  const [isOpen, setOpened] = useState(false);

  const handleDismiss = () => {
    setOpened(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setOpened(false);
  };

  const identifier = `confirmablebutton-${uuid()}`;

  return (
    <div>
      <FeideButton id={identifier} onClick={() => setOpened(true)} isFlat>
        {children}
      </FeideButton>

      <GeneralModal header={<h3>{modalHeader}</h3>} aria-labelledby={identifier} isOpen={isOpen} onDismiss={handleDismiss}>
        {modalBody}

        <div className="modal-footer">
          <FeideButton type="reset" onClick={handleDismiss}>
            {modalActionLabels.dismiss}
          </FeideButton>
          <FeideButton type="submit" is="danger" onClick={handleConfirm}>
            {modalActionLabels.confirm}
          </FeideButton>
        </div>
      </GeneralModal>
    </div>
  );
}
export default ConfirmableButton;
