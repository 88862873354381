import { useTranslation } from 'react-i18next';
import { UseDetailedPersonalInformationResults } from '../../../hooks/useDetailedPersonalInformation';
import { UseGroupInformationResults } from '../../../hooks/useGroupInformation';
import { Group } from '../../../types/group';
import { buildAttributeList, FEIDE_ATTRIBUTES, GUEST_ATTRIBUTES } from '../../../util/feideUtil';
import { isEmptyObject } from '../../../util/objectUtil';
import { TranslatableProperty } from '../../group/GroupCard';
import GenericErrorCard from '../../ui/GenericErrorCard';
import ExpandablePanel from './ExpandablePanel';
import FeideAttributeTable from './FeideAttributeTable';

import './index.scss';

/**
 * Finds name of the user's host org, by looking for eduOrgLegalName.
 * */
function findMyOrganizationName(groupInfo: Group[]): string | null | undefined {
  const org = groupInfo.find((group) => !!group.eduOrgLegalName);

  if (!org) {
    return null;
  }

  return org.eduOrgLegalName;
}

type FeideAttributes = {
  [key: string]: string[] | undefined;
};

function buildUserDataRows(userinfo: FeideAttributes, showMoreInfo: boolean, isFeideUser: boolean): TranslatableProperty[] {
  const attrList = isFeideUser ? FEIDE_ATTRIBUTES.aboutMe : GUEST_ATTRIBUTES;
  return buildAttributeList(userinfo, attrList, showMoreInfo);
}

function buildMyOrganizationRows(orgAttributes: FeideAttributes, showMoreInfo: boolean): TranslatableProperty[] {
  return buildAttributeList(orgAttributes, FEIDE_ATTRIBUTES.aboutOrg, showMoreInfo);
}

type DropdownListProps = {
  detailedInfo: UseDetailedPersonalInformationResults;
  userId?: string;
  groupInfo: UseGroupInformationResults;
  isDetailedInformationVisible: boolean;
  isFeideUser: boolean;
};

function DropdownList({
  detailedInfo,
  userId,
  groupInfo,
  isDetailedInformationVisible,
  isFeideUser,
}: DropdownListProps): React.JSX.Element {
  const { t } = useTranslation('userinfo');

  // Give the user some sensible feedback, if we fail to retrieve detailed userinformation
  // (have had issues here with NTNU users)
  if (!isEmptyObject(detailedInfo) && !!detailedInfo.error) {
    return (
      <GenericErrorCard
        title={t('detailedInfo.error.couldNotRetrieveUserdata')}
        error={
          <div>
            <p>{t('detailedInfo.error.somethingWentWrong')}</p>
            <a className="contact-link" href="https://www.feide.no/kontakt-oss" target="_blank" rel="noopener noreferrer">
              {t('detailedInfo.error.contactUs')}
            </a>
          </div>
        }
      />
    );
  }

  const dataObject = Object.assign(detailedInfo.personalInformation!, {});

  if (userId) {
    dataObject.userid = [userId];
  }

  if (groupInfo.groups) {
    const homeOrg = findMyOrganizationName(groupInfo.groups);

    if (homeOrg) {
      dataObject.eduOrgLegalName = [homeOrg];
    }
  }

  const personalInfo = buildUserDataRows(dataObject, isDetailedInformationVisible, isFeideUser);
  const myOrganization = buildMyOrganizationRows(dataObject, isDetailedInformationVisible);

  return (
    <div className="dropdown-info">
      <ExpandablePanel title={t('detailedInfo.titles.aboutMe')} isConnected={true}>
        <FeideAttributeTable attributes={personalInfo} />
      </ExpandablePanel>
      {isFeideUser ? (
        <ExpandablePanel title={t('detailedInfo.titles.aboutOrg')} isConnected={true}>
          {/* No info about this for guest users so remove the dropdown */}
          <FeideAttributeTable attributes={myOrganization} />
        </ExpandablePanel>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DropdownList;
