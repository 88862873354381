import qs from 'qs';

import { AuthState } from './state';

const clientId = 'e107929b-acb7-4197-af07-23c5ccb635e0';

const getRedirectUri = () => `${window.location.origin}/callback`;

export function getHybridAuthConfig(state: AuthState, codeChallenge: string): { [key: string]: string } {
  return {
    state: JSON.stringify(state),
    response_type: 'code',
    client_id: clientId,
    scope: 'authzinfo adhocgroupadmin email groups openid profile userid userid-feide userinfo-photo userinfo-all gk_feideconsent',
    redirect_uri: getRedirectUri(),
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  };
}

export function getTokenConfig(): { [key: string]: string } {
  return {
    grant_type: 'authorization_code',
    client_id: clientId,
    redirect_uri: getRedirectUri(),
  };
}

export function buildAuthUri(config: { [name: string]: string }): string {
  const query = qs.stringify(config);
  return `https://auth.dataporten.no/oauth/authorization?${query}`;
}
