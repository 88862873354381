import { useQuery } from 'react-query';
import * as Sentry from '@sentry/browser';
import { buildAuthDataportenUri, getData } from '../util/api';
import { getRealmFromPrincipalName } from '../util/feideUtil';
import { useSentryTags, useSentryUser } from './sentry';

interface User {
  userIdSec: string[];
  userId: string;
  name: string;
  email: string;
  /**
   * Make sure Innsyn has access to the scope userinfo-photo, or else this will not be present
   * */
  profilePhotoID: string;
}

/**
 * Represents the data we get from auth.dataporten.no/userinfo
 * */
export interface BasicPersonalInformation {
  user: User;
}

async function getBasicUserinfo(token: string): Promise<BasicPersonalInformation> {
  const data = (await getData(token, buildAuthDataportenUri('openid/userinfo'))) as Record<string, unknown>;
  let pictureID = '';
  if ('picture' in data) {
    pictureID = (data.picture as string).split('/').pop() as string;
  }

  return {
    // Convert the response over to a format that conforms to our style conventions
    user: {
      userId: data.sub as string,
      userIdSec: data['dataporten-userid_sec'] as string[],
      name: data.name as string,
      email: data.email as string,
      profilePhotoID: pictureID,
    },
  };
}

/**
 * Extracts eduPersonPrincipalName from userid_sec
 * */
function extractEduPersonPrincipalName(personalInformation: BasicPersonalInformation): string | undefined {
  const { userIdSec } = personalInformation.user;

  const feideId = userIdSec.find((id) => id.startsWith('feide:'));
  if (!feideId) {
    return undefined;
  }

  return feideId.replace('feide:', '');
}

export interface UseBasicPersonalInformationResults {
  personalInformation?: BasicPersonalInformation;
  eduPersonPrincipalName?: string;
  error?: Error;
  isLoading: boolean;
}

/**
 * Fetches a users personal information from Authengine.
 * */
function useBasicPersonalInformation({ accessToken }: { accessToken?: string }): UseBasicPersonalInformationResults {
  const { data, error, isLoading } = useQuery<BasicPersonalInformation, Error>(
    ['userinfo', 'basic'],
    // This function is not called unless accessToken is present
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getBasicUserinfo(accessToken!),
    {
      enabled: !!accessToken,
      onError(err) {
        Sentry.captureException(err);
      },
    },
  );

  const eduPersonPrincipalName = data ? extractEduPersonPrincipalName(data) : undefined;
  // Provide Sentry with some context when we first have it,
  // which simplifies debugging and the size of a problem
  useSentryTags('realm', getRealmFromPrincipalName(eduPersonPrincipalName));
  useSentryUser(data?.user?.userId);

  return {
    isLoading,
    personalInformation: data,
    eduPersonPrincipalName: data ? extractEduPersonPrincipalName(data) : undefined,
    error: error ?? undefined,
  };
}

export default useBasicPersonalInformation;
